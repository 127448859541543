import * as BackendApi from '../components/BackendApi'

const FETCH_CHAT_QUESTIONS = "FETCH_CHAT_QUESTIONS"
const REFRESH_CHAT_QUESTIONS = 'REFRESH_CHAT_QUESTIONS'
const FETCH_CHAT_QUESTION_TYPES = "FETCH_CHAT_QUESTION_TYPES"
const OPEN_CHAT_QUESTION_VIEW = "OPEN_CHAT_QUESTION_VIEW"
const CLOSE_CHAT_QUESTION_VIEW = 'CLOSE_CHAT_QUESTION_VIEW'
const CHANGE_EXPANDED_CHAT_QUESTION = 'CHANGE_EXPANDED_CHAT_QUESTION'
const CHANGE_CHAT_QUESTION_TYPE = 'CHANGE_CHAT_QUESTION_TYPE'
const CHANGE_CHAT_QUESTION_PAGE_SIZE = 'CHANGE_CHAT_QUESTION_PAGE_SIZE'

const initialState = {
    isTypeInited: false,
    isLoadingType: false,
    questionTypeList: [],

    questionType: 0,
    pageSize: 20,

    isQuestionInited: false,
    questionList: [],
    currentPage: 1,
    totalCount: 0,
    totalPage: 1,

    expandedQuestion: 0,

    questionTypeDisable: false,

    isOpen: false, 

    isLoading: false,
    errorMessage: '',
};

export class Actions {
    static fetchQuestionTypeList = () => ({ 
        type: FETCH_CHAT_QUESTION_TYPES, 
        payload: BackendApi.fetch_chat_question_types() 
    })

    static fetchQuestionList = (page, pageSize, questionType, refresh = false) => {
        if(refresh){
            return { 
                type: REFRESH_CHAT_QUESTIONS, 
                payload: BackendApi.fetch_chat_questions(page, pageSize, questionType) 
            }
        }
        return { 
            type: FETCH_CHAT_QUESTIONS, 
            payload: BackendApi.fetch_chat_questions(page, pageSize, questionType) 
        }
    }
    static changeChatQuestionType = (questionType) => ({  type: CHANGE_CHAT_QUESTION_TYPE, payload: questionType})
    static changeExpandedQuestion = (expandedQuestion) => ({  type: CHANGE_EXPANDED_CHAT_QUESTION, payload: expandedQuestion})
    static changePageSize = (pageSize) => ({  type: CHANGE_CHAT_QUESTION_PAGE_SIZE, payload: pageSize})
    static open = () => ({  type: OPEN_CHAT_QUESTION_VIEW })
    static close = () => ({  type: CLOSE_CHAT_QUESTION_VIEW })
}

export function reduce(state = initialState, action) {
    switch (action.type) {
        case `${FETCH_CHAT_QUESTION_TYPES}_PENDING`: {
            return { ...state, errorMessage: '', isLoadingType: true}
        }
        case `${FETCH_CHAT_QUESTION_TYPES}_FULFILLED`: {
            return { 
                ...state,
                errorMessage: '',
                questionTypeList: action.payload.data,
                isTypeInited: true,
                isLoadingType: false,
            }
        }
        case `${FETCH_CHAT_QUESTION_TYPES}_REJECTED`: {
            return { ...state, errorMessage: 'message.networkIsBusyPleaseRetry', isLoadingType: false}
        }

        case `${REFRESH_CHAT_QUESTIONS}_PENDING`: {
            return { ...state, isLoading: true, errorMessage: ''}
        }
        case `${REFRESH_CHAT_QUESTIONS}_FULFILLED`: {
            return { 
                ...state,
                isLoading: false,
                errorMessage: '',
                isQuestionInited: true,

                questionList: action.payload.data.pageList, 
                currentPage: action.payload.data.currentPage, 
                totalCount: action.payload.data.totalCount,
                totalPage: action.payload.data.totalPage,
            }
        }
        case `${REFRESH_CHAT_QUESTIONS}_REJECTED`: {
            return { ...state, isLoading: false, errorMessage: 'message.networkIsBusyPleaseRetry'}
        }

        case `${FETCH_CHAT_QUESTIONS}_PENDING`: {
            return { ...state, isLoading: true, errorMessage: ''}
        }
        case `${FETCH_CHAT_QUESTIONS}_FULFILLED`: {
            let pageSize = state.pageSize
            let totalPage = parseInt((action.payload.data.totalCount + pageSize - 1)/pageSize)
            return { 
                ...state,
                isLoading: false, 
                errorMessage: '',

                questionList: state.questionList.concat(action.payload.data.pageList), 
                currentPage: action.payload.data.currentPage, 
                totalCount: action.payload.data.totalCount,
                totalPage: totalPage,
            }
        }
        case `${FETCH_CHAT_QUESTIONS}_REJECTED`: {
            return { ...state, isLoading: false, errorMessage: 'message.networkIsBusyPleaseRetry'}
        }

        case CHANGE_CHAT_QUESTION_TYPE:{
            return {...state, questionType: action.payload}
        }
        
        case CHANGE_EXPANDED_CHAT_QUESTION:{
            return {...state, expandedQuestion: action.payload}
        }

        case CHANGE_CHAT_QUESTION_PAGE_SIZE: {
            return {...state, pageSize: action.payload}
        }

        case OPEN_CHAT_QUESTION_VIEW: {
            return {...state, isOpen: true}
        }

        case CLOSE_CHAT_QUESTION_VIEW: {
            return {...state, isOpen: false}
        }
        default:
            return state;
    }
}

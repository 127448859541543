import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CustomerArea from "./CustomerArea";
import { withTranslation } from "react-i18next";
import { Actions } from "../../reducers/CustomerState";

const mapStateToProps = (state) => ({
  selectedContact: state.customerArea.selectedContact,
  editStatus: state.customerArea.editStatus,
  tabLeadList: state.customerArea.tabLeadList,
  contactList: state.customerArea.contactList,
});

const mapDispatchToProps = (dispatch) => ({
  setTabLeadList: bindActionCreators(Actions.setTabLeadList, dispatch),
  selectContact: bindActionCreators(Actions.selectContact, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CustomerArea));

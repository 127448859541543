
const initialState = {
};

export class Actions {
  
}

export function reduce(state = initialState, action) {
  switch (action.type) {
  
    default:
      return state;
  }
}

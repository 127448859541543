const JOIN_VIDEO_ROOM = "JOIN_VIDEO_ROOM"
const LEAVE_VIDEO_ROOM = "LEAVE_VIDEO_ROOM"
const END_VIDEO_ROOM = "END_VIDEO_ROOM"

const SET_LOCAL_DATA_TRACK = 'SET_LOCAL_DATA_TRACK'
const SEND_MESSAGE_TO_VIDEO_ROOM = 'SEND_MESSAGE_TO_VIDEO_ROOM'
const SEND_COMMAND_TO_VIDEO_ROOM = 'SEND_COMMAND_TO_VIDEO_ROOM'

const initialState = {
    activeRoom: null,
    localDataTrack: null,
};

export class Actions {
    static joinRoom = (room) => ({ type: JOIN_VIDEO_ROOM, payload: room })
    static endVideo = () => ({ type: END_VIDEO_ROOM })

    static leaveRoom = () => ({ type: LEAVE_VIDEO_ROOM })
    static setLocalDataTrack = (dataTrack) => ({ type: SET_LOCAL_DATA_TRACK, payload: dataTrack })
    static sendMessage = (message) => ({ type: SEND_MESSAGE_TO_VIDEO_ROOM, payload: message })
    static sendCommand = (command) => ({ type: SEND_COMMAND_TO_VIDEO_ROOM, payload: command })

}

export function reduce(state = initialState, action) {
    switch (action.type) {
        case JOIN_VIDEO_ROOM:
            return { ...state, activeRoom: action.payload }
        case END_VIDEO_ROOM:
            state.localDataTrack?.send(JSON.stringify({ type: 'command', value: 'end-video' }))
            state.activeRoom && state.activeRoom?.state !== 'disconnected' && state.activeRoom.disconnect()
            return { ...state, activeRoom: null, localDataTrack: null }
        case LEAVE_VIDEO_ROOM:
            state.activeRoom && state.activeRoom?.state !== 'disconnected' && state.activeRoom.disconnect();
            return { ...state, activeRoom: null, localDataTrack: null }
        case SET_LOCAL_DATA_TRACK:
            return { ...state, localDataTrack: action.payload }
        case SEND_MESSAGE_TO_VIDEO_ROOM:
            state.localDataTrack?.send(JSON.stringify({ type: 'message', value: action.payload }))
            console.log('Send Message To Video Room.', action.payload)
            return { ...state }
        case SEND_COMMAND_TO_VIDEO_ROOM:
            state.localDataTrack?.send(JSON.stringify({ type: 'command', value: action.payload }))
            console.log('Send Command To Video Room.', action.payload)
            return { ...state }
        default:
            return state;
    }
}

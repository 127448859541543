import { getLanguageShort } from "../utils";
import i18n from "i18next";
import i18nLocize from "i18next-locize-backend";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from 'i18next-browser-languagedetector';

let lang = getLanguageShort();

console.log("i18n Will init with language: ", lang);

i18n
  .use(i18nLocize)
  .use(initReactI18next) // passes i18n down to react-i18next
  // .use(LanguageDetector)
  .init({
    lng: lang,
    fallbackLng: "en",
    // debug: true,
    saveMissing: true,
    // saveMissingTo: "current",
    // resources: locales,

    keySeparator: false, // we do not use keys in form messages.welcome
    nsSeparator: ":",
    ns: ["FlexCommon", "FlexCalendar", "FlexCrm", "FlexOptometry"],
    defaultNS: "FlexCommon",
    fallbackNS: "FlexCommon",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      projectId: "9ba0fb60-8524-4850-a766-1963c305fe4d",
      apiKey: "a9311de2-bd23-4100-bd98-95994af4182c",
      referenceLng: "en",
      private: true,
    },
    react: {
      bindI18n: "languageChanged",
      useSuspense: false,
    },
  });
export default i18n;

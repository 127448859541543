import { Actions } from "../../../reducers/CustomerState";
import LeadSearch from "./LeadSearch";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

const mapStateToProps = (state) => ({
  searchField: state.customerArea.searchField,
  searchLeadId: state.customerArea.searchLeadId,
  searchInbound: state.customerArea.searchInbound,

  inSearchingContact: state.customerArea.inSearchingContact,
  searchContactMessage: state.customerArea.searchContactMessage,
  contactList: state.customerArea.contactList,
  selectedContactIndex: state.customerArea.selectedContactIndex,

  selectedContact: state.customerArea.selectedContact,
  editStatus: state.customerArea.editStatus,
  offers: state.dataCenter.offers,
  authUser: state.dataCenter.authUser,
  tabLeadList: state.customerArea.tabLeadList,
});

const mapDispatchToProps = (dispatch) => ({
  searchContactList: bindActionCreators(Actions.searchContactList, dispatch),
  setSearchField: bindActionCreators(Actions.setSearchField, dispatch),
  setSearchLeadId: bindActionCreators(Actions.setSearchLeadId, dispatch),
  selectContact: bindActionCreators(Actions.selectContact, dispatch),
  setEditStatus: bindActionCreators(Actions.setEditStatus, dispatch),
  setLeadApp: bindActionCreators(Actions.setLeadApp, dispatch),
  setTabLeadList: bindActionCreators(Actions.setTabLeadList, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(LeadSearch));

import 'react-phone-input-2/lib/style.css';

import * as BackendApi from '../../BackendApi';

import React, { Component } from 'react';
import {
  errorTips,
  getCurrentCountry,
  successTips,
  warningTips
} from '../../../utils';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import PhoneInput from 'react-phone-input-2';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';
import moment from '../../../i18n/moment';
import { styles } from './LeadSearch.Styles';
import { withStyles } from "@mui/styles";

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);



class LeadSearch extends Component {
  static propTypes = {
    inSearchingContact: PropTypes.bool,
    searchContactMessage: PropTypes.string,
    searchField: PropTypes.string,
    searchLeadId: PropTypes.string,
    contactList: PropTypes.array,
    selectedContactIndex: PropTypes.number,
    selectedContact: PropTypes.object,

    searchContactList: PropTypes.func,
    selectContact: PropTypes.func,
    setSearchField: PropTypes.func,
    setEditStatus: PropTypes.func,
    editStatus: PropTypes.bool
  }
  constructor(props) {
    super(props);
    this.state = {
      phone_or_email: '',
      valid_error: '',

      firstName: '',
      lastName: '',
      customerNo: '',
      email: '',
      phone: '',
      campaignName: '',
      campaignCode: '',
      leadId: '',
      createDatetime: '',
      zip: '',
      preBirthDays: new Date(),
      noEmail: false,

      updateCustomer: false,
      createCustomer: false,
      isSubmit: false,

      updateFiled: {
        firstStatus: false,
        lastStatus: false,
        emailStatus: false,
        phoneStatus: false,
        codeStatus: false,
        zipStatus: false,
        birthdayStatus: false
      },
      errors: {
        firstErrorTips: '',
        lastErrorTips: '',
        emailErrorTips: '',
        phoneErrorTips: '',
        codeErrorTips: '',
        zipErrorTips: '',
        birthdayErrorTips: ''
      },

      mergeStatus: false,
      mergeName: '',
      mergePhone: '',
      mergeEmail: '',
      mergeContact: '',
      mergeArry: [],
      mergeInfoList: [],
    }
  }

  validInputText = () => {
    const { t, searchField } = this.props;
    if (!searchField) {
      this.setState({ valid_error: t('FlexCrm:tips.requiredEmailOrPhone') })
      return false
    }
    this.setState({ valid_error: '' })
    return true
  }

  search = () => {
    const { searchContactList, searchField, selectedContact } = this.props;
    if (this.validInputText()) {
      searchContactList(searchField, selectedContact && selectedContact.lead_id)
    }
  }

  onSelectLead = (selectIndex) => {
    const { contactList, selectContact, setTabLeadList, tabLeadList } = this.props
    if (selectIndex >= 0) {
      selectContact(contactList[selectIndex], selectIndex)
      let list = contactList[selectIndex]
      let arr = []
      arr = tabLeadList
      if (list) {
        let arrstr = true
        arr.forEach((lead) => {
          if (lead.lead_id === list.lead_id) {
            arrstr = false
          }
        })
        if (arrstr) {
          arr.push(list)
          setTabLeadList(arr)
        }
        console.info(tabLeadList)
        this.setState({
          firstName: list.first_name,
          lastName: list.last_name,
          customerNo: list.customer_no,
          email: list.email,
          phone: list.phone ? list.phone : list.mobile,
          campaignName: list.campaign_name,
          campaignCode: list.campaign_code,
          leadId: list.lead_id,
          createDatetim: moment(list.create_datetime).format('DD/MM/YYYY HH:mm:ss'),
          zip: list.zip,
          preBirthDays: list.birthday ? list.birthday : new Date(),
        })
      }
    } else {
      selectContact(null, selectIndex)
    }
  }

  onInputSearchField = (value) => {
    const { setSearchField } = this.props
    // console.log('searchField:',value)
    setSearchField(value)
  }

  onCreate = () => {
    console.log('Cerate New Lead')
    this.setState({
      firstName: '',
      lastName: '',
      customerNo: '',
      email: '',
      phone: '',
      prefix: '',
      campaignName: '',
      campaignCode: "",
      leadId: '',
      createDatetim: '',
      zip: '',
      preBirthDays: new Date(),
      noEmail: false,

      updateFiled: {
        firstStatus: false,
        lastStatus: false,
        emailStatus: false,
        phoneStatus: false,
        codeStatus: false,
        zipStatus: false,
        birthdayStatus: false
      },

      errors: {
        firstErrorTips: '',
        lastErrorTips: '',
        emailErrorTips: '',
        phoneErrorTips: '',
        codeErrorTips: '',
        zipErrorTips: '',
        birthdayErrorTips: ''
      },

      updateCustomer: false,
      createCustomer: !this.state.createCustomer,
    })
  }

  onEdit = () => {
    const { setEditStatus, editStatus, selectedContact } = this.props
    console.log('Edit Lead')

    this.setState({
      firstName: selectedContact.first_name,
      lastName: selectedContact.last_name,
      customerNo: selectedContact.customer_no,
      email: selectedContact.email,
      phone: selectedContact.phone ? selectedContact.phone : selectedContact.mobile,
      prefix: '',
      campaignName: selectedContact.campaign_name,
      campaignCode: selectedContact.campaign_code,
      leadId: selectedContact.lead_id,
      createDatetim: moment(selectedContact.create_datetime).format('DD/MM/YYYY HH:mm:ss'),
      zip: selectedContact.zip,
      preBirthDays: selectedContact.birthday ? new Date(selectedContact.birthday + ' 00:00:00') : new Date(),
      noEmail: selectedContact.email ? false : true,

      updateFiled: {
        firstStatus: false,
        lastStatus: false,
        emailStatus: false,
        phoneStatus: false,
        codeStatus: false,
        zipStatus: false,
        birthdayStatus: false
      },

      errors: {
        firstErrorTips: '',
        lastErrorTips: '',
        emailErrorTips: '',
        phoneErrorTips: '',
        codeErrorTips: '',
        zipErrorTips: '',
        birthdayErrorTips: ''
      },

      updateCustomer: false,
    })
    setEditStatus(!editStatus)
  }

  brithBayToDay = (e) => {
    console.info('Change birthday:', e)
    this.setState({
      preBirthDays: e || new Date(),
      updateFiled: {
        ...this.state.updateFiled,
        birthdayStatus: true
      }
    })
  }

  closeEditLeadDialog = () => {
    const { setEditStatus, editStatus } = this.props
    if (editStatus) {
      setEditStatus(!editStatus)
    } else {
      this.setState({
        createCustomer: !this.state.createCustomer
      })
    }
  }

  onSubmit = () => {
    const { t, authUser, editStatus, searchContactList, setSearchLeadId } = this.props;
    if (this.state.createCustomer) {
      this.setState({
        errors: {
          ...this.state.errors,
          firstErrorTips: !this.state.firstName ? t('FlexCrm:tips.firstNameError') : '',
          lastErrorTips: !this.state.lastName ? t('FlexCrm:tips.lastNameError') : '',
          emailErrorTips: (!this.state.email && !this.state.noEmail) ? t('FlexCrm:tips.emailError') : '',
          phoneErrorTips: (!this.state.phone || this.state.phone.length <= 4) ? t('FlexCrm:tips.phoneError') : '',
          codeErrorTips: !this.state.campaignCode ? t('FlexCrm:tips.campaignCodeError') : ''
        }
      }, () => {
        if (!this.state.errors.firstErrorTips && !this.state.errors.lastErrorTips && !this.state.errors.emailErrorTips
          && !this.state.errors.phoneErrorTips && !this.state.errors.codeErrorTips) {
          let inst_info = {}
          inst_info["first_name"] = this.state.firstName
          inst_info["last_name"] = this.state.lastName
          inst_info["email"] = this.state.email
          inst_info["phone_number"] = this.state.phone
          inst_info["phone_prefix"] = this.state.prefix
          inst_info["campaign_code"] = this.state.campaignCode
          inst_info["zipcode"] = this.state.zip
          inst_info["nav_lang"] = authUser.lang
          inst_info["birthday"] = this.state.preBirthDays ? moment(this.state.preBirthDays).format('YYYY-MM-DD') : ''
          inst_info["operating_id"] = authUser.id
          BackendApi.create_new_lead(inst_info).then(result => {
            if (result.success) {
              setSearchLeadId(result.data.encryption)
              searchContactList(result.data.phone, result.data.encryption)
              this.setState({ createCustomer: false })
              successTips(t('FlexCrm:tips.leadCreationSuccess'))
            } else {
              if (result?.data?.error_message?.code == 40202) {
                errorTips(t('FlexCrm:There is the same lead today'))
              } else {
                errorTips(t('FlexCrm:tips.leadCreationFailed'))
              }
            }
          })
        }
      })
    } else if (editStatus) {
      let editInfo = {}
      let status = false
      if (this.state.updateFiled.firstStatus) {
        editInfo["first_name"] = this.state.firstName
        status = true
      } else {
        editInfo["first_name"] = 'NoEdit'
      }
      if (this.state.updateFiled.lastStatus) {
        editInfo["last_name"] = this.state.lastName
        status = true
      } else {
        editInfo["last_name"] = 'NoEdit'
      }
      if (this.state.updateFiled.emailStatus) {
        editInfo["email"] = this.state.email
        status = true
      } else {
        editInfo["email"] = 'NoEdit'
      }
      if (this.state.updateFiled.phoneStatus) {
        editInfo["phone_number"] = this.state.phone
        editInfo["phone_prefix"] = this.state.prefix
        status = true
      } else {
        editInfo["phone_number"] = 'NoEdit'
        editInfo["phone_prefix"] = 'NoEdit'
      }
      if (this.state.updateFiled.codeStatus) {
        editInfo["campaign_code"] = this.state.campaignCode
        status = true
      } else {
        editInfo["campaign_code"] = 'NoEdit'
      }
      if (this.state.updateFiled.zipStatus) {
        editInfo["zipcode"] = this.state.zip
        status = true
      } else {
        editInfo["zipcode"] = 'NoEdit'
      }
      if (this.state.updateFiled.birthdayStatus) {
        editInfo["birthday"] = this.state.preBirthDays ? moment(this.state.preBirthDays).format("YYYY-MM-DD") : ''
        status = true
      } else {
        editInfo["birthday"] = 'NoEdit'
      }

      if (status) {
        editInfo["lead_id"] = this.state.leadId
        editInfo["operating_id"] = authUser.id
        if (this.state.updateCustomer) {
          editInfo["ref"] = this.state.customerNo
        }
        BackendApi.edit_customer_lead_info(editInfo).then(result => {
          console.info(result)
          if (result.success) {
            searchContactList(result.data.phone, this.state.leadId)
            this.setState({ editStatus: false })
            successTips(t('FlexCrm:tips.leadCustomerEditSuccess'))
          } else {
            errorTips(t('FlexCrm:tips.leadCustomerEditFailed'))
          }
        })
      } else {
        warningTips(t('FlexCrm:tipsleadNoChanges'))
      }
    }
  }

  onOpenMergeInfo = () => {
    let info = {
      number: this.state.mergePhone,
      email: this.state.mergeEmail,
      name: this.state.mergeName
    }
    this.setState({
      mergeStatus: !this.state.mergeStatus,
      mergeContact: '',
      mergeArry: [],
      mergeInfoList: []
    }, () => {
      if (this.state.mergeName || this.state.mergePhone || this.state.mergeEmail) {
        BackendApi.get_merge_customer_info(info).then(result => {
          console.info(result)
          if (result.success) {
            this.setState({
              mergeInfoList: result.data
            })
          }
        })
      }
    })
  }

  onMergeCustomerInfo = () => {
    const { authUser } = this.props;
    let info = {
      operating_id: authUser.id,
      destination_contact: this.state.mergeContact,
      customer_array: this.state.mergeArry
    }
    BackendApi.merge_customer_info(info).then(result => {
      console.info(result)
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((this.props.searchLeadId || this.props.searchInbound || this.props.selectedContact) && this.props.contactList && this.props.contactList !== prevProps.contactList) {
      this.props.contactList.forEach((lead, index) => {
        if (this.props.selectedContact && (lead.lead_id === this.props.selectedContact.lead_id)) {
          console.log('Refresh selected lead', lead)
          this.onSelectLead(index)
        } else if (lead.lead_id === this.props.searchLeadId) {
          console.log('Auto select lead', this.props.searchLeadId, lead)
          this.onSelectLead(index)
        } else if (lead.inbound_select) {
          console.log('Auto select inbound lead', lead)
          this.onSelectLead(index)
        }
      })
    }
  }

  render() {
    const { t, classes, setEditStatus, inSearchingContact, selectedContact, searchContactMessage, searchField, selectedContactIndex, contactList, editStatus, offers } = this.props;

    // if (!this.state.mergeStatus) {
    //   if (selectedContact && selectedContact.first_name && selectedContact.last_name) {
    //     this.state.mergeName = selectedContact.first_name + ' ' + selectedContact.last_name
    //   } else if (selectedContact && (selectedContact.first_name || selectedContact.last_name)) {
    //     this.state.mergeName = selectedContact.first_name ? selectedContact.first_name : selectedContact.last_name
    //   } else {
    //     this.state.mergeName = ''
    //   }
    //   this.state.mergePhone = selectedContact ? (selectedContact.phone ? selectedContact.phone : selectedContact.mobile) : ''
    //   this.state.mergeEmail = selectedContact ? (selectedContact.email ? selectedContact.email : '') : ''
    // }
    return (
      <React.Fragment>
        <Paper style={{ padding: "10px", marginTop: "10px" }} elevation={3}>
          <Grid container direction="row" spacing={1} justifyContent="space-between" alignItems="center" >
            <Grid item xs={12} sm={3}>
              <Grid container direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                <Grid item>
                  <TextField
                    id="lead_search_input"
                    label={t('label.email') + "/" + t('label.phone')}
                    value={searchField}
                    onChange={(e) => this.onInputSearchField(e.target.value)}
                    margin="dense"
                    type="search"
                    error={this.state.valid_error ? true : false}
                    helperText={this.state.valid_error}
                  />
                </Grid>
                <Grid item>
                  <IconButton onClick={this.search} disabled={inSearchingContact} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  {inSearchingContact && <CircularProgress size={20} />}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Grid container direction="row" spacing={1} justifyContent="space-between" alignItems="center" >
                {contactList.length > 0 ?
                  <Grid item xs={8}>
                    <TextField
                      label={t('FlexCrm:label.selectACustomer')}
                      id="outlined-select-currency"
                      select
                      margin="dense"
                      fullWidth
                      value={selectedContactIndex}
                      onChange={(e) => this.onSelectLead(e.target.value)}
                      variant="outlined"
                    >
                      <MenuItem key={-1} value={-1}>
                        {t('FlexCrm:Please select one customer here.')}
                      </MenuItem>
                      {
                        contactList.map((lead, index) => {
                          let phone = lead.mobile || lead.phone
                          return (
                            <MenuItem key={index} value={index}>
                              <div>
                                {lead.email ? lead.email + " - " : ''}
                                {lead.first_name ? lead.first_name + " " : ''}
                                {lead.last_name ? lead.last_name + " - " : ''}
                                {phone ? phone + " - " : ''}
                                {lead.zip ? lead.zip + ' - ' : ''}
                                {lead.customer_no ? 'Customer' : 'Lead'}
                                {` ( ${moment(lead.create_datetime).format('lll')} )`}
                              </div>
                              {
                                lead.tags?.length > 0 ? <>
                                  <Divider />
                                  <Stack direction="row" spacing={1}>
                                    {lead.tags.map((tag) => {
                                      return <Chip key={tag.id} label={tag.name} />
                                    })}
                                  </Stack>
                                </> : null
                              }
                            </MenuItem>
                          )
                        })
                      }
                    </TextField>
                  </Grid>
                  : (searchContactMessage ? t(searchContactMessage) : null)
                }
                {selectedContact ?
                  <Grid>
                    <Button onClick={this.onEdit} variant="contained" color="primary" >{t('FlexCrm:Edit Lead')}</Button>
                  </Grid>
                  : null
                }
                <Grid style={{ display: 'none' }}>
                  <Button onClick={this.onOpenMergeInfo} variant="contained" color="primary" >{t('FlexCrm:Merge')}</Button>
                </Grid>
                <Grid>
                  <Button onClick={this.onCreate} variant="contained" color="primary" >{t('FlexCrm:Create New Lead')}</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {editStatus || this.state.createCustomer ?
          <Dialog
            aria-labelledby="simple-dialog-title"
            open={editStatus ? editStatus : this.state.createCustomer}
            onClose={this.closeEditLeadDialog}
          >
            <DialogTitle id="simple-dialog-title">
              <Grid container justifyContent='space-between' alignItems='center'>
                <Typography variant='h6' component="h2" paragraph={true}>
                  {this.state.createCustomer ? t('FlexCrm:title.createTitle') : null}
                  {editStatus ? t('FlexCrm:title.editTitle') : null}
                </Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={this.closeEditLeadDialog}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </DialogTitle>
            <DialogContent>
              {this.state.customerNo ? <>
                <FormControlLabel
                  style={{ marginLeft: "1%", display: 'block' }}
                  control={<Checkbox
                    color="default" name="updateCustomer"
                    checked={this.state.updateCustomer}
                    onChange={(e) => {
                      this.setState({
                        updateCustomer: e.target.checked
                      })
                    }}
                  />}
                  label={t('FlexCrm:label.whetherToUpdateCustomer')}
                />
              </> : null
              }
              <TextField
                size="small"
                ref="first_name"
                id="first_name"
                label={t('FlexCrm:First Name')}
                variant="outlined"
                color="secondary"
                style={{ margin: "1.5ch", width: "42%" }}
                value={this.state.firstName}
                error={this.state.errors.firstErrorTips ? true : false}
                helperText={this.state.errors.firstErrorTips}
                onChange={(e) => {
                  this.setState({
                    firstName: e.target.value,
                    updateFiled: {
                      ...this.state.updateFiled,
                      firstStatus: true
                    }
                  })
                }}
              />
              <TextField
                size="small"
                ref="last_name"
                id="last_name"
                label={t('FlexCrm:Last Name')}
                variant="outlined"
                color="secondary"
                style={{ margin: "1.5ch", width: "42%" }}
                value={this.state.lastName}
                error={this.state.errors.lastErrorTips ? true : false}
                helperText={this.state.errors.lastErrorTips}
                onChange={(e) => {
                  this.setState({
                    lastName: e.target.value,
                    updateFiled: {
                      ...this.state.updateFiled,
                      lastStatus: true
                    }
                  })
                }}
              />
              <TextField
                size="small"
                ref="email"
                id="email"
                label={t('label.email')}
                variant="outlined"
                color="secondary"
                style={{ margin: "1.5ch", width: "42%" }}
                value={this.state.email}
                error={this.state.errors.emailErrorTips ? true : false}
                helperText={this.state.errors.emailErrorTips}
                onChange={(e) => {
                  this.setState({
                    email: e.target.value,
                    updateFiled: {
                      ...this.state.updateFiled,
                      emailStatus: true
                    }
                  })
                }}
              />
              <TextField
                size="small"
                ref="zipcode"
                id="zipcode"
                label={t('label.zipcode')}
                variant="outlined"
                color="secondary"
                style={{ margin: "1.5ch", width: "42%" }}
                value={this.state.zip}
                error={this.state.errors.zipErrorTips ? true : false}
                helperText={this.state.errors.zipErrorTips}
                onChange={(e) => {
                  this.setState({
                    zip: e.target.value,
                    updateFiled: {
                      ...this.state.updateFiled,
                      zipStatus: true
                    }
                  })
                }}
              />
              <Grid style={{ margin: "1.5ch", width: "42%", display: 'inline-block' }}>
                <PhoneInput
                  size="small"
                  country={getCurrentCountry()}
                  onlyCountries={['at', 'de', 'gb', 'ch', 'li', 'pl', 'us', 'it', 'es', 'ch']}
                  ref="phone"
                  id="phone"
                  label={t('FlexCrm:Phone/Mobile')}
                  variant="outlined"
                  color="secondary"
                  style={{ display: 'inline-flex' }}
                  value={this.state.phone}
                  onChange={(value, data, event, formattedValue) => {
                    let phone = formattedValue.replace(/\(|\)|-|\s/g, '')
                    this.setState({
                      phone: (!phone || phone.length <= 4) ? '' : phone,
                      prefix: (!phone || phone.length <= 4) ? '' : '+' + data.dialCode,
                      updateFiled: {
                        ...this.state.updateFiled,
                        phoneStatus: true
                      }
                    }, () => {
                    })
                  }}
                >
                </PhoneInput>
                {this.state.errors.phoneErrorTips ?
                  <label style={{ fontSize: '0.75rem', color: 'red', margin: '4px 14px 0px 14px' }}>{this.state.errors.phoneErrorTips}</label> : null
                }
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  margin="normal"
                  variant="outlined"
                  label={t('FlexCrm:Birthday')}
                  id="date-picker-dialog"
                  views={['year', 'month', 'day']}
                  maxDate={new Date()}
                  value={moment(this.state.preBirthDays).format('YYYY-MM-DD')}
                  onChange={this.brithBayToDay}
                  renderInput={
                    (params) =>
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        style={{ margin: "0.5ch 1.5ch", width: "42%" }}
                      />
                  } />
              </LocalizationProvider>
              <FormControl fullWidth required style={{ margin: "1.5ch", width: "89%" }} variant="outlined" size="small" margin="normal" error={this.state.errors.codeErrorTips ? true : false}>
                <InputLabel id="lead-select-offer-label" >{t('label.offer')}</InputLabel>
                <Select
                  labelId="lead-select-offer-label"
                  id="lead-select-offer"
                  value={this.state.campaignCode}
                  label={t('label.offer')}
                  onChange={(e) => {
                    this.setState({
                      campaignCode: e.target.value || false,
                      updateFiled: {
                        ...this.state.updateFiled,
                        codeStatus: true
                      },
                      errors: {
                        ...this.state.errors,
                        codeErrorTips: ''
                      }
                    })
                  }}
                >
                  {offers.map((option) => {
                    return (
                      <MenuItem key={option.campaign_code} value={option.campaign_code}>
                        {/* {option.campaign_name}({option.campaign_code}) */}
                        {option.campaign_name}
                      </MenuItem>
                    )
                  })}
                </Select>
                <FormHelperText>{this.state.errors.codeErrorTips}</FormHelperText>
              </FormControl>
              <FormControlLabel
                control={<GreenCheckbox name="No Email" onChange={
                  (e) => {
                    this.setState({
                      noEmail: !this.state.noEmail
                    })
                  }
                } />}
                label={t('FlexCalendar:label.noEmail')}
              />
            </DialogContent>
            <DialogActions>
              <Button disabled={this.state.isSubmit} onClick={this.onSubmit} className={classes.buttonGreen} >
                {t('button.save')}
              </Button>
              <Button
                className={classes.buttonGreen}
                onClick={() => { setEditStatus(!editStatus) }}>
                {t('button.cancel')}
              </Button>
            </DialogActions>
          </Dialog> : null
        }
        <Dialog
          onClose={(e) => {
            this.setState({
              mergeStatus: !this.state.mergeStatus
            })
          }}
          open={this.state.mergeStatus}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="customized-dialog-title" style={{ padding: '0px 24px', borderBottom: '1px solid #d7d7d7' }}>
            {t('FlexCrm:MergeCustomer')}
            <IconButton aria-label="close" style={{ top: '0px', padding: '2px' }} className={classes.closeButton} onClick={(e) => {
              this.setState({
                mergeStatus: !this.state.mergeStatus
              })
            }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid>
              <Typography style={{ fontWeight: 600 }}>Merge the following contacts</Typography>
              <Typography>Selected contacts will be merged together. All documents linked to one of these contacts will be redirected to the destination contact. You can remove contacts from this list to avoid merging them.</Typography>
            </Grid>
            <Grid xs={12} item container>
              <Grid xs={12} item>
                <Typography style={{ fontWeight: 600 }}>Search Content:</Typography>
                <TextField
                  style={{ margin: '5px 5px' }}
                  label={t('FlexCrm:mergeName')}
                  value={this.state.mergeName}
                  onChange={(e) => { }}
                  margin="dense"
                  type="search"
                  variant="outlined"
                />
                <TextField
                  style={{ margin: '5px 5px' }}
                  label={t('FlexCrm:mergeEmail')}
                  value={this.state.mergeEmail}
                  onChange={(e) => { }}
                  margin="dense"
                  type="search"
                  variant="outlined"
                />
                <PhoneInput
                  size="small"
                  country={getCurrentCountry()}
                  onlyCountries={['at', 'de', 'gb', 'ch', 'li', 'pl', 'us', 'it', 'es', 'ch']}
                  ref="phone"
                  id="phone"
                  label={t('FlexCrm:mergePhone')}
                  variant="outlined"
                  color="secondary"
                  style={{ lineHeight: '0px', display: 'inline-flex', margin: '5px 5px', width: '33%' }}
                  inputStyle={{ height: '40px' }}
                  value={this.state.mergePhone}
                  onChange={(value, data, event, formattedValue) => {
                    let phone = formattedValue.replace(/\(|\)|-|\s/g, '')
                    this.setState({
                      mergePhone: (!phone || phone.length <= 4) ? '' : phone,
                    })
                  }}
                >
                </PhoneInput>
                {this.state.errors.phoneErrorTips ?
                  <label style={{ fontSize: '0.75rem', color: 'red', margin: '4px 14px 0px 14px' }}>{this.state.errors.phoneErrorTips}</label> : null
                }
              </Grid>
              <Grid xs={12} item>
                <Typography style={{ fontWeight: 600 }}>Destination Contact:</Typography>
                <TextField
                  style={{ width: '50%' }}
                  label={t('FlexCrm:mergeDestinationContact')}
                  id="outlined-select-currency"
                  select
                  margin="dense"
                  fullWidth
                  value={this.state.mergeContact}
                  onChange={(e) => {
                    this.setState({
                      mergeContact: e.target.value
                    })
                  }}
                  variant="outlined"
                >
                  {this.state.mergeInfoList && this.state.mergeInfoList.map((list) => {
                    return (<MenuItem key={list.customer_no} value={list.customer_no}>
                      {list.customer_no + ';' + list.first_name + ' ' + list.last_name + ';' +
                        (list.mobile ? (list.mobile + (list.phone ? '/' : '')) : '') + (list.phone ? list.phone : '') + ';' +
                        list.email + ';' + list.street2 + ' ' + list.street + ' ' + list.city
                      }</MenuItem>)
                  })}
                </TextField>
                <Button style={{ margin: '10px 40px', width: '30%' }} onClick={this.onMergeCustomerInfo} variant="contained" color="primary" >Merge Contacts</Button>
                <Typography style={{ fontWeight: 600 }}>Passively merge objects:</Typography>
                <TableContainer style={{ maxHeight: 280 }}>
                  <Table size="small" style={{ margin: '10px 0px' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>{t('FlexCrm:column.customerNo')}</TableCell>
                        <TableCell>{t('FlexCrm:column.customerName')}</TableCell>
                        <TableCell>{t('FlexCrm:column.customerEmail')}</TableCell>
                        <TableCell>{t('FlexCrm:column.customerPhone')}</TableCell>
                        <TableCell>{t('FlexCrm:column.customerStreet')}</TableCell>
                        <TableCell>{t('FlexCrm:column.customerRegistration')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.mergeInfoList ? this.state.mergeInfoList.map(row => {
                        return (
                          <TableRow key={row.customer_no}>
                            <TableCell style={{ padding: '0px' }}><FormControlLabel
                              style={{ marginLeft: "1%", paddingTop: '1%' }}
                              control={<Checkbox
                                value={row.customer_no}
                                checked={this.state.mergeArry.indexOf(row.customer_no) !== -1 ? true : false}
                                onChange={(e) => {
                                  let InfoList = this.state.mergeArry
                                  if (InfoList.indexOf(e.target.value) !== -1) {
                                    InfoList.forEach((item, index) => {
                                      if (item === e.target.value) {
                                        InfoList.splice(index, 1)
                                      }
                                    })
                                  } else {
                                    InfoList.push(e.target.value)
                                  }
                                  this.setState({
                                    mergeArry: InfoList
                                  })
                                }} color="default" name="onlyCMerge" />}
                            /></TableCell>
                            <TableCell style={{ padding: '5px' }}>{row.customer_no}</TableCell>
                            <TableCell style={{ padding: '5px' }}>{row.first_name + ' ' + row.last_name}</TableCell>
                            <TableCell style={{ padding: '5px' }}>{row.email}</TableCell>
                            <TableCell style={{ padding: '5px' }}>{(row.mobile ? (row.mobile + (row.phone ? '/' : '')) : '') +
                              (row.phone ? row.phone : '')}</TableCell>
                            <TableCell style={{ padding: '5px' }}>{row.street2 + ' ' + row.street + ' ' + row.city}</TableCell>
                            <TableCell style={{ padding: '5px' }}>{moment(row.create_date).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                          </TableRow>
                        );
                      }) :
                        <TableRow>
                          <TableCell colSpan={7}>{t('label.noCustomerData')}</TableCell></TableRow>}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }

}

export default withStyles(styles)(LeadSearch);

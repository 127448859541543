import TelegramIcon from '@mui/icons-material/Telegram';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { withStyles } from "@mui/styles";
import React, { Component } from 'react';
import { styles } from './OutboundMessage.Styles';


class OutboundMessage extends Component {

  sendMessage = () => {
    this.props.sendOutBoundMessage(this.props.to, this.props.body, this.props.sender.identity)
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Dialog
          disableEscapeKeyDown
          fullWidth
          open={this.props.isOpen}
          onClose={
            (event, reason) => {
              if (!reason || ['backdropClick', 'escapeKeyDown'].indexOf(reason) === -1) {
                this.props.close()
              }
            }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Send Message To {this.props.to}
          </DialogTitle>
          <DialogContent>
            {this.props.sender ?
              <DialogContentText>
                Sender: {this.props.sender.friendlyName}({this.props.sender.identity})
              </DialogContentText> : null}
            <TextField
              id="filled-multiline-static"
              label="Type Message"
              multiline
              rows="8"
              margin="normal"
              fullWidth
              variant="filled"
              value={this.props.body}
              onChange={(e) => this.props.updateMessageBody(e.target.value)}
            />
            {this.props.error && this.props.error.message ?
              <DialogContentText color='error' id="alert-dialog-description">
                {this.props.error.translate ? t(this.props.error.message) : this.props.error.message}
                {/* Error by Sending the Template Message */}
              </DialogContentText>
              :
              null
            }
          </DialogContent>
          <DialogActions>
            <Button disabled={this.props.sending} onClick={this.props.close} color="primary">
              {t('button.cancel')}
            </Button>
            <Button
              disabled={this.props.sending || !this.props.body || !this.props.to}
              onClick={this.sendMessage}
              color="primary"
              startIcon={<TelegramIcon />}>
              {t('button.send')}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
};

export default withStyles(styles)(OutboundMessage); 

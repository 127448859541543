import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";

import OutboundMessage from "./OutboundMessage";
import { Actions } from "../../reducers/OutboundMessageState";

const mapStateToProps = (state) => {
  return {
    isOpen: state.outboundMessage.isOpen,
    sending: state.outboundMessage.sending,
    to: state.outboundMessage.to,
    body: state.outboundMessage.body,
    error: state.outboundMessage.error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  close: bindActionCreators(Actions.hideDialog, dispatch),
  updateMessageBody: bindActionCreators(Actions.updateMessageBody, dispatch),
  sendOutBoundMessage: bindActionCreators(
    Actions.sendMessageToCustomer,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(OutboundMessage));

import { Actions } from "../../reducers/CalendarState";
import CalendarArea from "./CalendarArea";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Actions as customerArea } from "../../reducers/CustomerState";
import { withTranslation } from "react-i18next";

const mapStateToProps = (state) => ({
  calendarEvents: state.calendarArea.calendarEvents,
  calendarDate: state.calendarArea.calendarDate,
  highlightedEvent: state.calendarArea.highlightedEvent,
  selectedEvent: state.calendarArea.selectedEvent,
  leadApp: state.customerArea.leadApp,
  authUser: state.dataCenter.authUser,
  offers: state.dataCenter.offers,

  selectedContact: state.customerArea.selectedContact,
  email_reg: new RegExp(
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ),
});

const mapDispatchToProps = (dispatch) => ({
  jumpToDate: bindActionCreators(Actions.jumpToDate, dispatch),
  selectEvent: bindActionCreators(Actions.selectEvent, dispatch),
  highlightEvent: bindActionCreators(Actions.highlightEvent, dispatch),
  setLeadApp: bindActionCreators(customerArea.setLeadApp, dispatch),

  selectStore: bindActionCreators(Actions.selectStore, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CalendarArea));

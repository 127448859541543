import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";

import { WhatsApp24CheckActions } from "../../reducers/WhatsApp24hCheckState";
import WhatsApp24hCheck from "./WhatsApp24hCheck";

const mapStateToProps = (state) => ({
  openDialog: state.whatsapp24hCheck.openDialog,
  to: state.whatsapp24hCheck.to,
  channelSid: state.whatsapp24hCheck.channelSid,
  sending: state.whatsapp24hCheck.sending,
  error: state.whatsapp24hCheck.error,
});

const mapDispatchToProps = (dispatch) => ({
  closeDialogforWhatsapp: bindActionCreators(
    WhatsApp24CheckActions.closeDialogforWhatsapp24hCheck,
    dispatch
  ),
  inviteToJoinWhatsapp: bindActionCreators(
    WhatsApp24CheckActions.inviteToJoinWhatsapp,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(WhatsApp24hCheck));

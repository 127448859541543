const $greenBtnBG = '#94c21e'
export const Styles = theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	caption: {
		margin: theme.spacing(1),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	formLabel: {
		fontWeight: 'bold',
		margin: theme.spacing(2, 1, 1, 2),
	},
	button: {
		margin: theme.spacing(1),
	},
	btnBorder: {
		border: '2px solid dimgray',
		backgroundColor: "#fff",
	},
	buttonGreen: {
		margin: theme.spacing(1),
		boxShadow: '0px 2px 5px -5px',
		background: $greenBtnBG,
		'&:hover': {
			background: '#a7da23',
		},
		'& span': {
			color: '#fff',
			fontWeight: 'bold',
		}
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	paper: {
		position: 'absolute',
		width: theme.spacing(50),
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(4),
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	dense: {
		marginTop: 16,
	},
	menu: {
		width: 200,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	formControlOPT: {
		marginTop: theme.spacing(1),
		width: '100%',
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	rightIcon: {
		marginLeft: theme.spacing(1),
	},
	chip: {
		margin: theme.spacing(0.5),
	},
	secondary: {
		backgroundColor: '#e51f1f',
		"&:hover": {
			backgroundColor: "#ff0808",
		},
		"& span": {
			fontWeight: 'bold',
			color: '#fff',
		}
	},
	readMoreLess: {
		cursor: 'pointer',
		textDecoration: 'underline',
	},
	onMouseHover: {
		'&:hover': {
			color: 'silver',
			cursor: 'pointer'
		}
	},
	greenBox: {
		color: "#95c11f",
		fontWeight: 'bold',
		fontSize: 'larger',
		border: '2px solid #95c11f !important'
	},
	orangeBox: {
		color: "#ffa500",
		fontWeight: 'bold',
		fontSize: 'larger',
		border: '2px solid #ffa500 !important'
	},
	redBox: {
		color: "#FF0000",
		fontSize: 'larger',
		border: '1px solid #FF0000 !important',
		background: '#fff !important',
		fontWeight: 'bold',
	},
	tooltip: {
		fontSize: '0.95rem'
	},
	box: {
		border: '1px solid silver',
		borderRadius: 50,
		width: 104,
		height: 41,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 'larger',
	},
	zendeskHeader: {
		backgroundColor: "#eaeaea",
		padding: theme.spacing(2, 0),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly'
	},
	zendFills: {
		textAlign: 'center',
		border: '1px solid #eaeaea',
		borderRadius: 20,
		fontWeight: 700,
	},
	zendNew: {
		background: '#95c11f',
		color: '#fff',
	},
	zendOpen: {
		background: '#eaeaea'
	},

	// appotopsearch.js

	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: '#eee',
		'&:hover': {
			backgroundColor: 'rgba(88, 88, 88, 0.15)',
		},
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: 'auto',
		},
	},
	searchCode: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: '#eee',
		'&:hover': {
			backgroundColor: 'rgba(88, 88, 88, 0.15)',
		},
		width: '100%',
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: theme.spacing(1.5, 1, 1, 1),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		width: '100%',
	},
	inputCode: {
		padding: theme.spacing(1.5, 1, 1, 1),
		width: '100%',
	},
	bgInfo: {
		width: 15,
		height: 15,
		borderRadius: 10,
		border: '1px solid dimgray',
		marginRight: 8
	},
	accessCodeDis: {
		width: '98%',
		margin: '10px',
	},
});

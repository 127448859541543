const SET_OFFER_LIST = "SET_OFFER_LIST"
const SET_AUTH_USER = 'SET_AUTH_USER'


const initialState = {
    refreshSign: true,
    authUser: null,
    offers: []
};

export class Actions {
    static setOffers = (offers) => {
        return { type: SET_OFFER_LIST, payload: offers }
    }

    static setAuthUser = (authUser) => ({ type: SET_AUTH_USER, payload: authUser })

}

export function reduce(state = initialState, action) {
    switch (action.type) {
        case SET_OFFER_LIST: {
            return {
                ...state, offers: action.payload
            };
        }
        case SET_AUTH_USER: {
            return {
              ...state,
              authUser: action.payload,
            };
          }
        default:
            return state;
    }
}

import React, { Component } from "react";
import {
  closeLoading,
  errorTips,
  getCurrentCountry,
  openConfirmDialog,
  openLoading,
  successTips,
} from "../../../utils";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Styles } from "./styles";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import moment from "../../../i18n/moment";
import { send_eye_test_survey_email } from "../../BackendApi";
import { withStyles } from "@mui/styles";
import { withTranslation } from "react-i18next";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { date } from "date-arithmetic";

const GreenSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "#fff",
    },
    "&$checked + $track": {
      backgroundColor: "#95c11f",
    },
  },
  checked: {},
  track: {},
})(Switch);

const InfoItem = ({ label, text }) => (
  <Typography>
    <strong>{label}</strong> : {text}
  </Typography>
);

class RetentionTimeDialog extends Component {
  state = {
    isSubmit: false,
    errors: {
      DateErrorTips: "",
      TimeErrorTips: "",
      WeekErrorTips: ""
    },
    close_event_title:"",
    close_event_start_date:"",
    close_event_end_date:"", 
    close_event_start_time:"07:00:00",
    close_event_end_time:"22:00:00",
    close_event_mo:true, 
    close_event_tu:true, 
    close_event_we:true, 
    close_event_th:true, 
    close_event_fr:true, 
    close_event_sa:false, 
    close_event_su:false
  };

  constructor(props) {
    super(props);
    const {t} = this.props;
    this.state.close_event_start_date = moment(new Date()).format('YYYY-MM-DD');
    this.state.close_event_end_date = moment(new Date()).format('YYYY-MM-DD');
  };

  openLogConfirm = () => {
    const { t, openHistory } = this.props;
    openHistory();
  };

  time_list = () => {
    let state_time =new Date('1999/01/01 07:00:00')
    let end_time = new Date('1999/01/01 22:00:00')
    let all_list = []
    while (state_time <= end_time) {
      let interval_time = state_time
      all_list.push(moment(interval_time).format("HH:mm:ss"))
      var min = state_time.getMinutes()
      state_time.setMinutes(min + 15)
    }
    return all_list
  };

  onSubmit = () => {
    const {t} = this.props;
    if (this.validEventAttribute()) {
      openConfirmDialog(
        t("FlexCalendar:title.confirmAddRetentionTime"),
        this.onCreateRetentionTime,
        t("FlexCalendar:message.confirmAddNewRetentionTime"),
        t("FlexCalendar:button.yesAddRetentionTime"),
        t("FlexCalendar:button.noAddRetentionTime")
      );
    }
  };
  
  onCreateRetentionTime = () => {
    const { CreateRetentionTime} = this.props;
    let time_zone = moment.tz.guess()
    CreateRetentionTime({
      'title':this.state.close_event_title,
      'start_date':moment(this.state.close_event_start_date).format('YYYY-MM-DD'),
      'end_date':moment(this.state.close_event_end_date).format('YYYY-MM-DD'), 
      'start_time':this.state.close_event_start_time,
      'end_time':this.state.close_event_end_time,
      'mo':this.state.close_event_mo, 
      'tu':this.state.close_event_tu, 
      'we':this.state.close_event_we, 
      'th':this.state.close_event_th, 
      'fr':this.state.close_event_fr, 
      'sa':this.state.close_event_sa, 
      'su':this.state.close_event_su,
      'event_tz':time_zone
    })
  }

  validEventAttribute = () => {
    const {t} = this.props;
    let errors = {};
    if(!this.state.close_event_start_date || !this.state.close_event_end_date || this.state.close_event_start_date>this.state.close_event_end_date){
      errors = {
        ...errors,
        DateErrorTips: t("FlexCalendar:tips.DateErrorTips"),
      };
    }
    else if(this.state.close_event_start_date===this.state.close_event_end_date && 
      this.state.close_event_start_time>=this.state.close_event_end_time){
      errors = {
        ...errors,
        TimeErrorTips: t("FlexCalendar:tips.TimeErrorTips"),
      };
    }
    else if(!this.state.close_event_mo && !this.state.close_event_tu && !this.state.close_event_we && !this.state.close_event_th && 
      !this.state.close_event_fr && !this.state.close_event_sa && !this.state.close_event_su){
      errors = {
        ...errors,
        WeekErrorTips: t("FlexCalendar:tips.WeekErrorTips"),
      };
    }
    this.setState({ errors: errors });
    return Object.keys(errors).length === 0 ? true : false;
  };

  render() {
    const {t, classes, open, onClose} = this.props;
    //NORMAL,NOT BUYING,NOT YET,DONE
    let country_code = getCurrentCountry();
    return (
      <>
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={open}
          onClose={onClose}
        >
          <DialogTitle id="simple-dialog-title">
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography variant="h6" paragraph={true}>
              {t('FlexCalendar:AddNewRetentionTime')}
              </Typography>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Divider />
          </DialogTitle>
          <DialogContent>
            <Grid container justifyContent="space-between">
              <TextField
                size="small"
                ref="title"
                id="title"
                label={t('FlexCalendar:RetentionTitle')}
                variant="outlined"
                color="secondary"
                style={{ margin: "1.5ch", width: "100%"}}
                value={this.state.close_event_title}
                onChange={(e) => {
                  this.setState({
                    close_event_title: e.target.value || ''
                  })
                }}
              />
            </Grid>
            <Grid container justifyContent="space-between">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  margin="normal"
                  variant="outlined"
                  label={t('FlexCalendar:RetentionStartDate')}
                  id="date-picker-dialog"
                  views={['year', 'month', 'day']}
                  value={moment(this.state.close_event_start_date).format('YYYY-MM-DD')}
                  error={this.state.errors.DateErrorTips ? true : false}
                  helperText={this.state.errors.DateErrorTips}
                  onChange={(e)=>{
                    this.setState({
                      close_event_start_date:e
                    })
                  }}
                  renderInput={
                    (params) =>
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        style={{ margin: "0.5ch 1.5ch", width: "42%" }}
                      />
                  } />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  margin="normal"
                  variant="outlined"
                  label={t('FlexCalendar:RetentionEndDate')}
                  id="date-picker-dialog"
                  views={['year', 'month', 'day']}
                  value={moment(this.state.close_event_end_date).format('YYYY-MM-DD')}
                  error={this.state.errors.DateErrorTips ? true : false}
                  helperText={this.state.errors.DateErrorTips}
                  onChange={(e)=>{
                    this.setState({
                      close_event_end_date:e
                    })
                  }}
                  renderInput={
                    (params) =>
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        style={{ margin: "0.5ch 1.5ch", width: "42%" }}
                      />
                  } />
              </LocalizationProvider>
            </Grid>
            <Grid container justifyContent="space-between">
              <FormControl fullWidth required style={{ margin: "1.5ch", width: "42%", display:"inline-block" }} variant="outlined" size="small" margin="normal">
                <InputLabel id="lead-select-offer-label" >{t('FlexCalendar:RetentionStartTime')}</InputLabel>
                <Select
                  labelId="lead-select-offer-label"
                  id="lead-select-offer"
                  value={this.state.close_event_start_time}
                  label={t('FlexCalendar:RetentionStartTime')}
                  style={{width: "100%"}}
                  error={this.state.errors.TimeErrorTips ? true : false}
                  onChange={(e) => {
                    this.setState({
                      close_event_start_time: e.target.value || '',
                    })
                  }}
                >
                  {this.time_list().map((option) => {
                    return (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    )
                  })}
                </Select>
                <FormHelperText>{this.state.errors.TimeErrorTips}</FormHelperText>
              </FormControl>
              <FormControl fullWidth required style={{ margin: "1.5ch", width: "42%", display:"inline-block" }} variant="outlined" size="small" margin="normal">
                <InputLabel id="lead-select-offer-label" >{t('FlexCalendar:RetentionEndTime')}</InputLabel>
                <Select
                  labelId="lead-select-offer-label"
                  id="lead-select-offer"
                  value={this.state.close_event_end_time}
                  label={t('FlexCalendar:RetentionEndTime')}
                  style={{width: "100%"}}
                  error={this.state.errors.TimeErrorTips ? true : false}
                  onChange={(e) => {
                    this.setState({
                      close_event_end_time: e.target.value || '',
                    })
                  }}
                >
                  {this.time_list().map((option) => {
                    return (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    )
                  })}
                </Select>
                <FormHelperText>{this.state.errors.TimeErrorTips}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid container justifyContent="space-between">
              <FormGroup style={{margin: "1.5ch", width: "100%", display: "-webkit-box"}}>
                <Grid style={{display:"flex"}}>
                  <FormControlLabel control={<Checkbox checked={this.state.close_event_mo} onChange={(e)=>{
                    this.setState({
                      close_event_mo:!this.state.close_event_mo
                    })
                  }} />} 
                  label={t('FlexCalendar:RetentionMon')} />
                </Grid>
                <Grid style={{display:"flex"}}>
                  <FormControlLabel control={<Checkbox checked={this.state.close_event_tu} onChange={(e)=>{
                    this.setState({
                      close_event_tu:!this.state.close_event_tu
                    })
                  }} />} label={t('FlexCalendar:RetentionTue')} />
                </Grid>
                <Grid style={{display:"flex"}}>
                  <FormControlLabel control={<Checkbox checked={this.state.close_event_we} onChange={(e)=>{
                    this.setState({
                      close_event_we:!this.state.close_event_we
                    })
                  }} />} label={t('FlexCalendar:RetentionWed')} />
                </Grid>
                <Grid style={{display:"flex"}}>
                  <FormControlLabel control={<Checkbox checked={this.state.close_event_th} onChange={(e)=>{
                    this.setState({
                      close_event_th:!this.state.close_event_th
                    })
                  }} />} label={t('FlexCalendar:RetentionThu')} />
                </Grid>
                <Grid style={{display:"flex"}}>
                  <FormControlLabel control={<Checkbox checked={this.state.close_event_fr} onChange={(e)=>{
                    this.setState({
                      close_event_fr:!this.state.close_event_fr
                    })
                  }} />} label={t('FlexCalendar:RetentionFri')} />
                </Grid>
                <Grid style={{display:"flex"}}>
                  <FormControlLabel control={<Checkbox checked={this.state.close_event_sa} onChange={(e)=>{
                    this.setState({
                      close_event_sa:!this.state.close_event_sa
                    })
                  }} />} label={t('FlexCalendar:RetentionSai')} />
                </Grid>
                <Grid style={{display:"flex"}}>
                  <FormControlLabel control={<Checkbox checked={this.state.close_event_su} onChange={(e)=>{
                    this.setState({
                      close_event_su:!this.state.close_event_su
                    })
                  }} />} label={t('FlexCalendar:RetentionSun')} />
                </Grid>
              </FormGroup>
              {this.state.errors.WeekErrorTips?<Grid>
                <Typography style={{ fontWeight: 600 }}>{this.state.errors.WeekErrorTips}</Typography>
              </Grid>
              :null
              }
            </Grid>
            <Grid container justifyContent="space-between">
              <Button
                variant="outlined"
                className={classes.button}
                style={{ backgroundColor:"#94c11e" }}
                onClick={this.onSubmit}
              >
              <span style={{color:"#fff"}}>{t("button.AddRetentionTimeConfirm")}</span>
              </Button>
              <Button
                  className={classes.button + " " + classes.secondary}
                  onClick={onClose}
                >
                <span style={{color:"#fff"}}>{t("button.Cancel")}</span>
              </Button>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default withStyles(Styles)(withTranslation()(RetentionTimeDialog));


const OPEN_LOGIN_VIEW = 'OPEN_LOGIN_VIEW'

const CLOSE_LOGIN_VIEW = 'CLOSE_LOGIN_VIEW'

const SET_LOGIN_NAME = 'SET_LOGIN_NAME'

const initialState = {
  username: '',
  isOpen: false,
};

export class Actions {

  static open = () => ({ type: OPEN_LOGIN_VIEW })

  static close = () => ({ type: CLOSE_LOGIN_VIEW })

  static setLoginUserName = (username) => ({ type: SET_LOGIN_NAME, payload: username })

}


export function reduce(state = initialState, action) {
  switch (action.type) {
    case SET_LOGIN_NAME: {
      return {
        ...state,
        username: action.payload,
      }
    }
    case OPEN_LOGIN_VIEW: {
      return {
        ...state,
        isOpen: true,
      };
    }
    case CLOSE_LOGIN_VIEW: {
      return {
        ...state,
        isOpen: false,
      };
    }
    
    default:
      return state;
  }
}

import React from "react";
import { withTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import { Styles } from "./styles";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions } from "../../reducers/CommonMessage";

const MessageDialog = ({
  t,
  classes,
  open,
  onClose,
  messageTitle,
  messageDescription,
  okButtonText,
}) => (
  <Dialog
    fullWidth
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    open={open}
    onClose={onClose}
  >
    <DialogTitle>
      {messageTitle ? messageTitle : t("title.message")}
    </DialogTitle>
    <DialogContentText>
      <Box m={4}>
        <p>{messageDescription}</p>
      </Box>
    </DialogContentText>
    <DialogActions>
      <Button variant="outlined" className={classes.button} onClick={onClose}>
        {okButtonText ? okButtonText : t("button.ok")}
      </Button>
    </DialogActions>
  </Dialog>
);

MessageDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  messageTitle: PropTypes.string,
  messageDescription: PropTypes.string,
  okButtonText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  open: state.commonMessage.isOpen,
  messageTitle: state.commonMessage.messageTitle,
  messageDescription: state.commonMessage.messageDescription,
  okButtonText: state.commonMessage.okButtonText,
});

const mapDispatchToProps = (dispatch) => ({
  onClose: bindActionCreators(Actions.close, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles)(withTranslation()(MessageDialog)));

import * as Utils from "../utils";

import axios from "axios";
import moment from "moment";

let apiSetting = {
  service_country: Utils.getServiceCode(),
  baseURL: Utils.getServiceEndpoint(),
};
console.log("Init BackendApi:", apiSetting);

let backend = axios.create({
  baseURL: apiSetting.baseURL,
});
backend.defaults.timeout = 100000;

backend.interceptors.request.use((config) => {
  config.headers["Authorization"] = Utils.check_crm_access_token();
  return config;
});

backend.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    return Promise.resolve({
      success: true,
      code: 200,
      data: response.data.data,
    });
  },
  (error) => {
    // 对响应错误做点什么
    if (error.response) {
      if (401 === error.response.status) {
        Utils.openLogin();
        Utils.errorTips("FlexCrm:message.loginInfoIsExpirePleaseRelogin", true);
      }
      return Promise.reject({
        success: false,
        code: error.response.status,
        data: error.response.data,
      });
    }
    return Promise.reject({
      success: false,
      code: 500,
      data: null,
    });
  }
);

export const changeBackendConfig = (baseURL, serviceCode) => {
  apiSetting.baseURL = baseURL;
  apiSetting.service_country = serviceCode;
  backend.defaults.baseURL = apiSetting.baseURL;
};

export const fetch_access_token = (username, password) => {
  let param = new URLSearchParams({
    username: username,
    password: password,
    grant_type: "password",
    country_code: apiSetting.service_country,
  });

  return axios
    .post(apiSetting.baseURL + "/auth/access_token", param)
    .then((response) => ({
      success: true,
      code: 200,
      data: response.data,
    }))
    .catch((error) => {
      if (error.response) {
        return {
          success: false,
          code: error.response.status,
          data: error.response.data,
        };
      }
      return {
        success: false,
        code: 500,
        data: null,
      };
    });
};

export const check_service_code = (service_code) => {
  return axios
    .post(apiSetting.baseURL + "/api_v1/service/check_code/" + service_code)
    .then((response) => ({
      success: true,
      code: 200,
      data: response.data.data,
    }))
    .catch((error) => ({
      success: false,
      code: 500,
      data: null,
    }));
};

export const logout = () => {
  return backend.post("/auth/logout");
};

export const isStore = () => {
  return backend.get("/api_v2/user/store");
};

export const fetch_currenr_user = () => {
  return backend.get("/api_v2/user/me");
};

export const search_lead_list = (search_request) => {
  return backend.post("/api_v2/lead/search", search_request);
};

export const fetch_offer_list = () => {
  return backend.get("/api_v2/campaign/list");
};

export const fetch_remarketing_tag_list = () => {
  return backend.get("/api_v2/lead/remarketing_tags");
};

export const fetch_chat_questions = (page, pageSize, questionType) => {
  return backend.post("/api_v2/chat/questions", {
    page: page,
    pageSize: pageSize,
    questionType: questionType,
  });
};

export const fetch_chat_question_types = () => {
  return backend.get("/api_v2/chat/question_types");
};

export const assign_optometrist_to_eyetest_video = (
  session_id,
  service_code
) => {
  return backend
    .post("/api_v2/check_in/optometry/video/assign", {
      session_id: session_id,
      service_code: service_code,
    })
    .then((result) => result.data);
};

export const finish_eyetest_video = (session_id, service_code) => {
  return backend.post("/api_v2/check_in/optometry/video/finish", {
    session_id: session_id,
    service_code: service_code,
  });
};

export const invite_to_join_whatsapp = (to, channelSid, author) => {
  return backend.post(
    "/api_v2/chat/invite_to_join_whatsapp",
    { to: to, chat_channel: channelSid, author: author ? author : null },
    { timeout: 10000 }
  );
};

export const send_message = (to, body, author) => {
  return backend.post(
    "/api_v2/chat/send_message",
    { to: to, body: body, author: author ? author : null },
    { timeout: 10000 }
  );
};

export const search_store_list = (optician_search, optician_zip_search) => {
  let url = "/api_v2/store/agent/all";
  if (optician_search && optician_zip_search) {
    url =
      "/api_v2/store/agent/all?fuzzy_query=" +
      optician_search +
      "&zipcode_city=" +
      optician_zip_search;
  }
  if (optician_search) {
    url = "/api_v2/store/agent/all?fuzzy_query=" + optician_search;
  } else if (optician_zip_search) {
    url = "/api_v2/store/agent/all?zipcode_city=" + optician_zip_search;
  }
  return backend.get(url);
};

export const app_openclose_list = (info) => {
  let url = "/api_v2/event/openclose";
  return backend.post(url, info);
};

export const add_retention = (info) => {
  let url = "/api_v2/event/add/retention";
  return backend.post(url, info);
};

export const edit_retention = (info) => {
  let url = "/api_v2/event/edit/retention";
  return backend.post(url, info);
};

export const cancel_retention = (info) => {
  let url = "/api_v2/event/cancel/retention";
  return backend.post(url, info);
};

export const get_app_date_interval = (store_id, c_date, calendar_model) => {
  return backend.get(
    `/api_v2/store/status/${store_id}/${c_date}?calendar_model=${calendar_model}`
  );
};

export const get_app_date_interval_2 = (store_id, c_date, calendar_model) => {
  return backend
    .get(
      `/api_v2/store/status-2/${store_id}/${c_date}?calendar_model=${calendar_model}`
    )
    .then((response) => response.data);
};

export const move_appointment = (move_info) => {
  let url = "/api_v2/event/move";
  return backend.post(url, move_info);
};

export const send_eye_test_survey_email = (body) => {
  let url = "/api_v2/event/send_eye_test_survey_email";
  return backend.post(url, body).then((result) => {
    let backendData = result.data;
    if (backendData.success) {
      return {
        success: true,
        code: 200,
        data: null,
      };
    } else {
      return Promise.reject({
        success: false,
        code: backendData.code,
        data: backendData.failed_reason,
      });
    }
  });
};

export const create_appointment = (create_info) => {
  let url = "/api_v2/booking/booking";
  return backend
    .post(url, create_info)
    .then((result) => result.data)
    .catch((error) => Promise.reject(error.data));
};

export const create_ticket = (ticket) => {
  let url = "/api_v2/ticket/add";
  return backend.post(url, ticket).then((result) => result.data);
};

export const fetch_my_tickets = (page, pageSize) => {
  return backend
    .post("/api_v2/ticket/me", {
      page: page,
      pageSize: pageSize,
      ticket_type: "",
    })
    .then((result) => result.data);
};

export const get_appointment_history = (search_info) => {
  let url = "/api_v2/event/history";
  return backend.post(url, search_info);
};

export const appointment_other_operation = (event_info) => {
  let url = "/api_v2/event/other/operations";
  return backend.post(url, event_info);
};

export const search_keyup_appointment = (search_info) => {
  let url = "/api_v2/event/keyupsearch";
  return backend.post(url, search_info);
};

export const available_booking_date = (store_id) => {
  let start_date = moment().format("YYYY-MM-DD");
  let url = `/api_v2/store/available_booking_date/${store_id}?start_date=${start_date}`;
  return backend.get(url);
};

export const available_booking_date_2 = (
  store_id,
  start_date,
  days_required = 15,
  max_search_days = 30
) => {
  start_date = start_date || moment().format("YYYY-MM-DD");
  let url = `/api_v2/store/available_booking_date-2/${store_id}?start_date=${start_date}&days_required=${days_required}&max_search_days=${max_search_days}`;
  return backend.get(url).then((result) => result.data);
};

export const available_lead_customer_gdpr = (gdpr_info) => {
  let url = "/api_v2/lead/gdpr";
  return backend.post(url, gdpr_info);
};

export const available_create_refraction = (info) => {
  let url = "/api_v2/lead/create_refraction";
  return backend.post(url, info).then((result) => result.data);
};
export const available_edit_refraction = (info) => {
  let url = "/api_v2/lead/edit_refraction";
  return backend.post(url, info).then((result) => result.data);
};
export const available_search_refraction = (info) => {
  let url = "/api_v2/lead/search_refraction";
  return backend.post(url, info).then((result) => result.data);
};

export const eyetest_finish_notify = (body) => {
  return backend
    .post("/api_v2/check_in/optometry/notify/finish", body)
    .then((result) => result.data);
};

export const eyetest_emergency_notify = (body) => {
  return backend
    .post("/api_v2/check_in/optometry/notify/emergency", body)
    .then((result) => result.data);
};

export const eyetest_request_help_notify = (body) => {
  return backend
    .post("/api_v2/check_in/optometry/notify/help", body)
    .then((result) => result.data);
};

export const clear_eyetest_notify = (body) => {
  return backend
    .post("/api_v2/check_in/optometry/notify/clear", body)
    .then((result) => result.data);
};

export const create_new_lead = (info) => {
  let url = "/api_v2/lead/add";
  return backend.post(url, info).catch((error) => error);
};

export const edit_customer_lead_info = (info) => {
  let url = "/api_v2/lead/edit_info";
  return backend.post(url, info).catch((error) => error);
};

export const get_call_records = (info) => {
  let url = "/api_v2/dial_task/get_call_records";
  return backend.post(url, info).then((result) => result.data);
};

export const get_dial_channels = () => {
  let url = "/api_v2/dial_task/get_dial_channels";
  return backend.get(url).then((result) => result.data);
};

export const get_reason_tags = () => {
  let url = "/api_v2/dial_task/get_reason_tags";
  return backend.get(url).then((result) => result.data);
};

export const create_new_call_later = (info) => {
  let url = "/api_v2/dial_task/call_later";
  return backend.post(url, info).then((result) => result.data);
};

export const cancel_call_task = (task_no, cancel_reason) => {
  return backend
    .post("/api_v2/dial_task/cancel", {
      task_no: task_no,
      cancel_reason: cancel_reason || 0,
    })
    .then((result) => result.data);
};

export const deassign_agent = (task_no) => {
  return backend
    .post("/api_v2/dial_task/remove_assign", { task_no: task_no })
    .then((result) => result.data);
};

export const update_schedule_date = (task_no, schedule_date) => {
  return backend
    .post("/api_v2/dial_task/update_schedule_date", {
      task_no: task_no,
      schedule_date: schedule_date,
    })
    .then((result) => result.data);
};

export const get_merge_customer_info = (info) => {
  let url = "/api_v2/lead/get_merge_info";
  return backend.post(url, info).catch((error) => error);
};

export const merge_customer_info = (info) => {
  let url = "/api_v2/lead/merge_customer";
  return backend.post(url, info);
};

export const create_call_notice = (info) => {
  let url = "/api_v2/lead/create_call_notice";
  return backend.post(url, info);
};

export const search_call_notice = (info) => {
  let url = "/api_v2/lead/search_call_notice";
  return backend.post(url, info);
};

export const search_customer_history = (info) => {
  let url = "/api_v2/chat/customer_history";
  return backend.post(url, info);
};

export const search_store_open_time = (info) => {
  let url = "/api_v2/store/query_store/" + info + "?limit=5";
  return backend.get(url).then((result) => result.data);
};

export const search_app_event_info = (event_id) => {
  let url = "/api_v2/event/info/" + event_id;
  return backend.get(url).then((result) => result);
};

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions } from "../../reducers/CommonConfirm";
import { Styles } from "./styles";

const ConfirmDialog = ({
  t,
  classes,
  open,
  onClose,
  confirmTitle,
  confirmDescription,
  onConfirm,
  confirmButtonText,
  cancelButtonText,
}) => {
  return (
    <Dialog
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{confirmTitle}</DialogTitle>
      <DialogContent>
        <Box m={4}>
          <p>{confirmDescription}</p>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.buttonGreen}
          onClick={() => {
            onClose();
            if (onConfirm) {
              onConfirm();
            }
          }}
        >
          {confirmButtonText ? confirmButtonText : t("button.confirm")}
        </Button>
        <Button variant="outlined" className={classes.button} onClick={onClose}>
          {cancelButtonText ? cancelButtonText : t("button.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  confirmTitle: PropTypes.string.isRequired,
  confirmDescription: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
};

const mapStateToProps = (state) => ({
  open: state.commonConfirm.isOpen,
  confirmTitle: state.commonConfirm.confirmTitle,
  confirmDescription: state.commonConfirm.confirmDescription,
  confirmButtonText: state.commonConfirm.confirmButtonText,
  cancelButtonText: state.commonConfirm.cancelButtonText,
  onConfirm: state.commonConfirm.onConfirm,
});

const mapDispatchToProps = (dispatch) => ({
  onClose: bindActionCreators(Actions.close, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles)(withTranslation()(ConfirmDialog)));

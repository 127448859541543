export const styles = theme => ({
  textField: {
    backgroundColor: "white",
    borderRadius: "4px",
    outline: "none",
    fontSize: "14px",
    width: '60%'
  },
  headline: {
    marginTop: '20px',
    fontSize: '30px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    lineHeight: '1.1',
    letterSpacing: '2px',
    textOverflow: 'ellipsis',
    marginBottom: '10px'
  },
  paperContainer: {
    padding: '25px'
  },
  button: {
    color: '#fff',
    backgroundColor: '#94c11e',
    position: 'relative',
    width: '60%',
    height: '55px',
    '&:hover': {
      background: "#575756",
      color: 'white'
    },
    marginBottom: '20px'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

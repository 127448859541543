import React, { Component } from 'react';
import { InfoContainer, InfoText, InfoItemsContainer } from './InfoArea.Styles';
import { loadLogo } from '../../utils';

class InfoArea extends Component {

  render() {
    return (
      <React.Fragment>
        <InfoContainer>

          <InfoItemsContainer>
            <InfoText>NO TASKS, MAKE SOME COFFEE</InfoText>
            <div><img src={loadLogo()} alt="logo" /></div>
          </InfoItemsContainer>


        </InfoContainer>

      </React.Fragment>
    );
  }
};
export default InfoArea;

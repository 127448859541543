import { styled } from "@mui/material/styles";

export const CustomerAreaContainer = styled("div")`
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
`;

export const styles = (theme) => ({});

import { styled } from "@mui/material/styles";

export const InfoContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30%;
`;

export const InfoText = styled("div")`
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  margin: 34px 20px 0px;
  margin-bottom: 12px;
`;

export const InfoItemsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

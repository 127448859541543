const OPEN_COMMON_TIPS = 'OPEN_COMMON_TIPS'
const CLOSE_COMMON_TIPS = 'CLOSE_COMMON_TIPS'

const initialState = {
    isOpen: false,
    message: '',
    useTranslate: false,
    type: '',
};

export class Actions {
    // error,warning,info,success
    static tips = (message, type, useTranslate) => {
        return {
            type: OPEN_COMMON_TIPS,
            payload: {
                message: message,
                useTranslate: useTranslate,
                type: type ? type: 'info',
            }
        }
    }

    static info = (message, useTranslate) => {
        return {
            type: OPEN_COMMON_TIPS,
            payload: {
                message: message,
                useTranslate: useTranslate,
                type: 'info',
            }
        }
    }

    static warn = (message, useTranslate) => {
        return {
            type: OPEN_COMMON_TIPS,
            payload: {
                message: message,
                useTranslate: useTranslate,
                type: 'warning',
            }
        }
    }

    static error = (message, useTranslate) => {
        return {
            type: OPEN_COMMON_TIPS,
            payload: {
                message: message,
                useTranslate: useTranslate,
                type: 'error',
            }
        }
    }
    static success = (message, useTranslate) => {
        return {
            type: OPEN_COMMON_TIPS,
            payload: {
                message: message,
                useTranslate: useTranslate,
                type: 'success',
            }
        }
    }

    static close = () => {
        return { type: CLOSE_COMMON_TIPS, payload: null }
    }
}

export function reduce(state = initialState, action) {
    switch (action.type) {
        case OPEN_COMMON_TIPS: {
            return {
                isOpen: true, 
                message: action.payload.message,
                useTranslate: action.payload.useTranslate,
                type: action.payload.type,
            };
        }
        case CLOSE_COMMON_TIPS: {
            return {...initialState};
        }
        default:
            return state;
    }
}

import { styled } from "@mui/material/styles";

export const CalendarAreaContainer = styled("div")`
  cursor: pointer;
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;

  .rbc-calendar {
    background: #fff;
    padding: 5px;
    border-radius: 2px;
  }

  .rbc-day-slot .rbc-event {
    font-size: 14px !important;
  }

  .rbc-allday-cell {
    display: none;
  }
  .rbc-day-slot.rbc-time-column {
    background-color: #b3b2b2;
    cursor: pointer;
  }
  .rbc-time-header-cell {
    height: 25px;
  }
  .rbc-time-slot:first-of-type {
    border: none;
  }

  .rbc-day-slot .rbc-time-slot {
    border: none
  }

  .rbc-current-time-indicator {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #74ad31;
    pointer-events: none;
    top: unset !important;
  }

  .rbc-day-slot .rbc-slot-time-lable {
    color: #ffffff6e;
    font-size: smaller;
    vertical-align: top;
  }

  .rbc-background-event:hover {
    border: solid 1.5px #fffe00;
    z-index: 100;
  }

  .rbc-event:hover {
    border: solid 1.5px #fffe00;
    z-index: 100;
  }

  .rbc-day-slot .rbc-timeslot-group {
    border: none;
  }
`;

import * as BackendApi from '../BackendApi';
import * as Utils from '../../utils';

import { FormHelperText, InputLabel } from '@mui/material';
import React, { Component } from 'react';

import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { styles } from './LoginView.Styles';
import { withStyles } from "@mui/styles";

class LoginView extends Component {

  static propTypes = {
    username: PropTypes.string,

    isOpen: PropTypes.bool.isRequired,

    openLoginView: PropTypes.func.isRequired,
    closeLoginView: PropTypes.func.isRequired,

    setAuthUser: PropTypes.func.isRequired,
    setOffers: PropTypes.func.isRequired,

    researchContactList: PropTypes.bool,
    contactSearchField: PropTypes.string,
    searchContactList: PropTypes.func,
  }

  state = {
    username: '',

    password: '',

    showPassword: false,

    errors: {
      missLogin: '',
      missPassword: '',
    }

  }

  componentDidMount() {
    console.log('Login View componentDidMount')
    const { openLoginView } = this.props;
    if (!Utils.check_crm_access_token()) {
      Utils.errorTips('FlexCrm:message.pleaseLoginToCrm', true)
      openLoginView()
    } else {
      this.initCommonData()
    }
  }

  initCommonData = async () => {
    const { setAuthUser, setOffers, openLoginView } = this.props;
    Utils.openLoading()
    try {
      let result = await BackendApi.fetch_currenr_user()

      if (!this.props.isOpen && this.props.username && this.props.username !== result.data.username) {
        Utils.clear_crm_access_token()
        openLoginView()
        return Promise.resolve(false)
      }
      console.log('AuthUser: ', result.data)
      setAuthUser(result.data)

      let offers_result = await BackendApi.fetch_offer_list()
      let campaign_set = new Set()
      var offers = []
      if (offers_result.data) {
        offers = offers_result.data.filter(offer => {
          if (offer.campaign_code && !campaign_set.has(offer.campaign_code)) {
            campaign_set.add(offer.campaign_code)
            return true;
          }
          return false;
        })
      }
      console.log('Available offers:', offers)
      setOffers(offers)

      this.researchContactList()

      return Promise.resolve(true)
    } catch (error) {
      if (error.code !== 401) {
        Utils.errorTips('message.networkIsBusyPleaseRetry', true)
        Utils.clear_crm_access_token()
        openLoginView()
      }
      return Promise.resolve(false)
    } finally {
      Utils.closeLoading()
    }
  }

  researchContactList = () => {
    const { researchContactList, contactSearchField, searchContactList, contactSearchLeadId, contactSearchInbound } = this.props
    if (researchContactList) {
      searchContactList(contactSearchField, contactSearchLeadId, contactSearchInbound)
    }
  }

  verifyAttributes = () => {
    const { t } = this.props;
    let errors = {}

    if (!this.state.password) {
      errors = { ...errors, missPassword: t('FlexCrm:tips.requiredPassword') }
    }

    if (!this.props.username) {
      if (!this.state.username) {
        errors = { ...errors, missLogin: t('FlexCrm:Please type your login account') }
      }
    }
    this.setState({ errors: errors })
    if (Object.keys(errors).length > 0) {
      return false
    }
    return true
  }

  handelLogin = () => {
    let that = this;
    const { t } = this.props;
    if (this.verifyAttributes()) {
      Utils.openLoading(t('FlexCrm:message.whenLoginLoading'))
      BackendApi
        .fetch_access_token(this.props.username || this.state.username, this.state.password)
        .then(result => {
          if (result.success) {
            Utils.save_crm_access_token(result.data)
            BackendApi.isStore().then(result => {
              Utils.clear_crm_access_token()
              Utils.errorTips(t("FlexCrm:you don't have permission to visit the agent page"))
              Utils.closeLoading()
            }).catch(error => {
              Utils.closeLoading()
              if (error?.data?.error_message?.name === "USER_INSUFFICIENT_PERMISSIONS") {
                that.loginSuccess(result.data)
              } else {
                Utils.clear_crm_access_token()
                if (error?.data?.error_message?.name === "USER_IS_NOT_FOUND") {
                  that.errorPassword(t('FlexCrm:tips.errorPassword'))
                } else {
                  Utils.errorTips(t('message.networkIsBusyPleaseRetry'))
                }
              }
            })
          } else {
            if (result.code === 401) {
              that.errorPassword(t('FlexCrm:tips.errorPassword'))
            } else {
              if(result.data?.error_message?.name === 'SERVICE_TIMEOUT'){
                Utils.errorTips(t('Service timeout, please try again later'))
              }else{
                Utils.errorTips(t('message.networkIsBusyPleaseRetry'))
              }
            }
            Utils.closeLoading()
          }
        })
    }
  }

  errorPassword = (message) => {
    this.setState({ errors: { missPassword: message } })
  }

  loginSuccess = (token) => {
    const { t, closeLoginView } = this.props;
    closeLoginView()
    this.initCommonData().then(result => {
      if (result) {
        Utils.successTips(t('FlexCrm:message.loginSuccess'))
        this.setState({ password: '', username: '' })
      }
    })
  }

  logout = () => {
    // Utils.logout_flex()
  }

  render() {
    const { t, classes, isOpen } = this.props;
    return (
      <React.Fragment>
        <Dialog
          open={isOpen}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth="sm"
        >
          <DialogContent>
            {/* <Paper className={classes.paperContainer} > */}
            <Grid container direction="row" justifyContent="center">
              <Typography className={classes.headline}>{t('FlexCrm:title.login')}</Typography>
            </Grid>
            <IconButton aria-label="close" className={classes.closeButton} onClick={this.logout}> <CloseIcon /> </IconButton>
            <form id='login-form'>
              {
                this.props.username ? <>
                  <Grid container direction="row" justifyContent="center">
                    <Typography>{t('label.username')}:  {this.props.username}</Typography>
                    <input
                      id="account"
                      type='text'
                      style={{
                        overflow: 'hidden',
                        border: 'none',
                        width: '0px'
                      }}
                    />
                  </Grid>
                  <Divider variant="middle" />
                </> : <Grid container direction="row" justifyContent="center">
                  <TextField
                    id="account"
                    label={t('Login')}
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    value={this.state.username}
                    onChange={(e) => this.setState({ username: e.target.value })}
                    type='text'
                    helperText={this.state.errors.missLogin}
                    error={this.state.errors.missLogin ? true : false}
                  />
                </Grid>
              }

              <Grid container direction="row" justifyContent="center">
                <FormControl className={classes.textField} variant="outlined" margin="normal" error={this.state.errors.missPassword ? true : false}>
                  <InputLabel htmlFor="password">{t('label.password')}</InputLabel>
                  <OutlinedInput
                    id="password"
                    type={this.state.showPassword ? 'text' : 'password'}
                    value={this.state.password}
                    onChange={(e) => this.setState({ password: e.target.value })}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                          onMouseDown={() => this.setState({ showPassword: !this.state.showPassword })}
                        >
                          {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={t('label.password')}
                  />
                  <FormHelperText>{this.state.errors.missPassword}</FormHelperText>
                </FormControl>
              </Grid>
            </form>
            <Grid container direction="row" justifyContent="center" >
              <Button onClick={this.handelLogin} className={classes.button} variant="contained" color="primary" >{t('button.login')}</Button>
            </Grid>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
};

export default withStyles(styles)(LoginView);

import { styled } from "@mui/material/styles";

export const styles = () => ({
  textField: {
    minWidth: 500,
  },
  agentText: {
    fontSize: "medium",
  },
  agentheadline: {
    fontSize: "large",
    fontStyle: "italic",
    fontVariant: "all-petite-caps",
    fontWeight: "bold",
  },
  card: {
    boxShadow:
      "0px 1px 3px -1px rgba(0,0,0,0.2), 0px 1px 1px 1px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    marginBottom: "20px",
    width: "420px",
    marginLeft: "10px",
  },
  createNotice: {
    marginLeft: "5%",
  },
});

export const Headline = styled("div")`
  color: rgb(34, 34, 34);
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.1;
  letter-spacing: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 2.8em;
  background: none;
  transition: color 0.2s ease 0s;
  padding: 12px;
  overflow: hidden;
`;
export const Divider = styled("div")`
  display: flex;
  margin-top: 20px;
  border-style: solid;
  border-width: 0px 0px 1px;
  border-color: rgb(204, 204, 204);
`;

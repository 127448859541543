import 'react-phone-input-2/lib/style.css';

import * as BackendApi from '../../BackendApi';

import {
    ContainerDividingLine,
    CustomerElementInfoContainer,
    CustomerElemetHeadLine,
    CustomerElemetText,
    CustomerMainInfoContainer,
    CustomerNameContainer,
    CustomerNameStyle,
    styles
} from './LeadDetail.Styles';
import React, { Component } from 'react';
import {
    closeLoading,
    errorTips,
    getCurrentCountry,
    openConfirmDialog,
    openLoading,
    successTips
} from '../../../utils';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import EmailIcon from '@mui/icons-material/Email';
import EventBusy from '@mui/icons-material/EventBusy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import PhoneForwardedRoundedIcon from '@mui/icons-material/PhoneForwardedRounded';
import PropTypes from 'prop-types';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import SmsIcon from '@mui/icons-material/Sms';
import TextField from '@mui/material/TextField';
import Timelapse from '@mui/icons-material/Timelapse';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import clsx from 'clsx';
import moment from '../../../i18n/moment';
import { withStyles } from "@mui/styles";

class LeadDetail extends Component {
    static propTypes = {
        selectedContact: PropTypes.object,
        editStatus: PropTypes.bool,
        offers: PropTypes.arrayOf(PropTypes.object).isRequired,
        setLeadApp: PropTypes.func
    }

    state = {
        showCampaignCodeErrorTips: '',
        firstName: null,
        lastName: null,
        customerNo: null,
        email: null,
        mobile: null,
        phone: null,
        campaignName: null,
        campaignCode: null,
        leadId: null,
        createDatetime: null,
        zip: null,
        birthday: null,
        updateCustomer: false,
        isOpenLeadDrawer: false,
        isOpenLeadProductDrawer: false,

        otherOperationMobile: false,
        nextCallIsMine: false,
        historyList: [],
        stateColor: {
            pending: '#32e9ec',
            cancelled: '#FF9901',
            dialing: '#b666e5',
            assigned: '#4b30b5',
            wrapping: '#b666e5',
            completed: '#3db530',
            failed: '#d10016',
        },

        dialChannels: [],
        selectedDialChannel: false,
        dialChannelErrorTips: '',

        callReasons: [],
        selectedCallReason: false,
        callReasonErrorTips: '',

        showCancelReasonView: false,
        cancelReasons: [],
        selectedCancelReason: false,
        cancelReasonErrorTips: '',

        expanded: false,
        expandKey: 0,
        callLaterDate: new Date(),
        callLaterDateTimeErrorTips: '',

        taskInCanceling: false,
        taskInDeassigning: false,

        showChangeScheduleView: false,
        newScheduleDate: new Date(),
        newScheduleDateError: '',

        selectProductOffer: '',
        ProductOfferDescription: "",
        clicked_appointment_id: false,
    }

    constructor(props) {
        super(props);
    }

    // confirm_dial_out = (number) => {
    //     const { t } = this.props;
    //     openConfirmDialog(
    //         t('FlexCrm:message.WhetherToDialOutImmediately') + "?",
    //         () => this.dial_out(number),
    //         t('FlexCrm:label.DialNumber') + ": " + number
    //     )
    // }

    // dial_out = (number) => {
    //     start_outbound_call(number)
    // }

    send_message = (to) => {
        this.props.openSendMessageDialog(to)
    }

    // add_message_body = (body) => {
    //     if (!body) { return }
    //     if (!this.props.task || !this.props.chatChannel) { return }
    //     if (this.props.task.status !== 'accepted') { return }
    //     let chatChannelType = this.props.task.attributes && this.props.task.attributes.channelType || null
    //     if (['sms', 'chat'].indexOf(chatChannelType) < 0) { return }
    //     let chatChannelSid = this.props.task.attributes && this.props.task.attributes.channelSid || null
    //     if (!chatChannelSid) { return }
    //     let currentText = this.props.chatChannel.inputText || ''
    //     let selectionStart = this.props.chatChannel.selectionStart || 0
    //     let selectionEnd = this.props.chatChannel.selectionEnd || 0
    //     let newBody = currentText.slice(0, selectionStart) + body
    //     selectionStart = newBody.length
    //     newBody = newBody + currentText.slice(selectionEnd)
    //     add_message_body(chatChannelSid, newBody, selectionStart, selectionStart)
    // }

    EditInfoSubmission = () => {

    }

    onLeadAppClick = (e) => {
        const { t, selectedContact, setLeadApp, changeTab } = this.props;
        selectedContact.app_details.map(item => {
            if (item.event_id == e.currentTarget.id) {
                setLeadApp({ 'app_time': item.app_time, 'event_id': item.event_id, 'store_ref': item.store_ref })
                changeTab('calendar')
            }
        })
    }

    openSendEyeTestSurveyEmailConfirm = (e) => {
        const { t } = this.props
        this.setState({
            clicked_appointment_id: e.currentTarget.id
        }, () => {
            openConfirmDialog(
                t('FlexCalendar:Confirm to send the eye test survey email?'),
                this.sendEyeTestSurveyEmail,
                t('FlexCalendar:This is a email for eye test survey.'),
                t('FlexCalendar:Yes, send it now'),
                t('FlexCalendar:no, cancel this operation')
            )
        })

    }

    sendEyeTestSurveyEmail = () => {
        const { t } = this.props
        openLoading()
        BackendApi.send_eye_test_survey_email({
            appointment_id: this.state.clicked_appointment_id
        }).then(result => {
            closeLoading()
            console.log('Send eye test survey email success:', result)
            successTips(t('FlexCalendar:Send email success'))
        }).catch(error => {
            closeLoading()
            console.log('Send eye test survey email failed:', error)
            errorTips(t('FlexCalendar:send email failed.'))
            if (error.code == 500) {
                errorTips(t("message.networkIsBusyPleaseRetry"))
            } else if (error.code == 4101) {
                errorTips(t('FlexCalendar:send email failed. No matched appointment'))
            }
        })
    }

    onOtherOperations = (is_mobile) => {
        this.fetchDialChannels()
        this.setState({
            isOpenLeadDrawer: !this.state.isOpenLeadDrawer,
            otherOperationMobile: is_mobile,
            callLaterDate: moment(new Date()).add(30, 'm'),
        }, () => {
            this.fetchCallTask()
        })
        this.fetchReasonTags()
    }

    onHandleProductDrawer = () => {
        const { selectedContact, offers } = this.props;
        if (selectedContact.campaign_code) {
            this.setState({
                selectProductOffer: selectedContact.campaign_code
            }, () => {
                offers.map((data) => {
                    if (selectedContact.campaign_code == data.campaign_code) {
                        this.setState({
                            ProductOfferDescription: data.product_description_template ? data.product_description_template : "No product description",
                            isOpenLeadProductDrawer: !this.state.isOpenLeadProductDrawer,
                        })
                    }
                })
            })
        } else {
            this.setState({
                isOpenLeadProductDrawer: !this.state.isOpenLeadProductDrawer,
            })
        }
    }

    onChangeProductSelect = (val) => {
        const { offers } = this.props;
        this.setState({
            selectProductOffer: val
        }, () => {
            offers.map((data) => {
                if (val == data.campaign_code) {
                    this.setState({
                        ProductOfferDescription: data.product_description_template ? data.product_description_template : "No product description",
                    })
                }
            })
        })
    }

    onHandleExpandClick = (e) => {
        console.log(e)
        this.setState({
            expanded: e.currentTarget.id == this.state.expandKey ? !this.state.expanded : true,
            expandKey: e.currentTarget.id
        })
    }

    fetchCallTask = () => {
        const { selectedContact } = this.props;
        let phone = this.state.otherOperationMobile && selectedContact.mobile || selectedContact.phone
        BackendApi.get_call_records({ phone: phone, lead_id: selectedContact.lead_id })
            .then(result => {
                this.setState({
                    historyList: result,
                })
            })
            .catch(error => {
                console.error('Fetch Call task history failed', error)
            })
    }

    fetchDialChannels = () => {
        BackendApi.get_dial_channels()
            .then(result => {
                this.setState({
                    dialChannels: result,
                })
            })
            .catch(error => {
                console.error('Fetch Call Reasons failed', error)
            })
    }

    selectDialChannel = (dialChannel) => {
        this.setState({ selectedDialChannel: dialChannel, dialChannelErrorTips: '' })
    }

    fetchReasonTags = () => {
        BackendApi.get_reason_tags()
            .then(result => {
                let call_reasons = []
                let cancel_reasons = []
                for (let tag of result) {
                    if (tag.call_reason) {
                        call_reasons.push(tag)
                    }
                    if (tag.cancel_reason) {
                        cancel_reasons.push(tag)
                    }
                }
                this.setState({
                    callReasons: call_reasons,
                    cancelReasons: cancel_reasons
                })
            })
            .catch(error => {
                console.error('Fetch Call Reasons failed', error)
            })
    }

    selectCallReason = (callReason) => {
        this.setState({ selectedCallReason: callReason, callReasonErrorTips: '' })
    }

    selectCancelReason = (cancelReason) => {
        this.setState({ selectedCancelReason: cancelReason, cancelReasonErrorTips: '' })
    }

    cancelCallTask = (task_no) => {
        const { t } = this.props;
        if (!this.state.selectedCancelReason) {
            this.setState({
                cancelReasonErrorTips: t('FlexCrm:Please select the cancel reason')
            })
            return
        }
        this.setState({ taskInCanceling: true })
        BackendApi.cancel_call_task(task_no, this.state.selectedCancelReason)
            .then(result => {
                this.setState({ taskInCanceling: false })
                if (result) {
                    successTips(t('FlexCrm:Cancel call task success.'))
                } else {
                    errorTips(t('FlexCrm:Can not cancel this call task.'))
                }
                this.fetchCallTask()
            })
            .catch(error => {
                this.setState({ taskInCanceling: false })
                console.error('Cancel Call Task failed', error)
                errorTips(t("message.networkIsBusyPleaseRetry"))
            })
    }

    deassignedFromTask = (task_no) => {
        const { t } = this.props;
        this.setState({ taskInDeassigning: true })
        BackendApi.deassign_agent(task_no)
            .then(result => {
                this.setState({ taskInDeassigning: false })
                if (result) {
                    successTips(t('FlexCrm:Deassign from this task success.'))
                } else {
                    errorTips(t('FlexCrm:Can not deassign from this call task.'))
                }
                this.fetchCallTask()
            })
            .catch(error => {
                this.setState({ taskInDeassigning: false })
                console.error('Deassign from call task failed', error)
                errorTips(t("message.networkIsBusyPleaseRetry"))
            })
    }

    changeTaskScheduleDate = (task_no) => {
        const { t } = this.props;
        if (this.state.newScheduleDate > new Date()) {
            this.setState({ taskInChangingSchedule: true, newScheduleDateError: '' })
            BackendApi.update_schedule_date(task_no, this.state.newScheduleDate.toISOString())
                .then(result => {
                    this.setState({ taskInChangingSchedule: false, showChangeScheduleView: false })
                    if (result) {
                        successTips(t('FlexCrm:Change task dial date success.'))
                    } else {
                        errorTips(t('FlexCrm:Can not change this call task.'))
                    }
                    this.fetchCallTask()
                })
                .catch(error => {
                    this.setState({ taskInChangingSchedule: false })
                    console.error('Change task schedule date failed', error)
                    errorTips(t("message.networkIsBusyPleaseRetry"))
                })
        } else {
            this.setState({ newScheduleDateError: t('FlexCrm:Task time must be in the future.') })
        }
    }

    onCallLaterSubmit = (e) => {
        const { t, authUser, selectedContact } = this.props;
        if (!this.state.selectedDialChannel) {
            this.setState({
                dialChannelErrorTips: t('FlexCrm:Please select a channel!')
            })
            return
        }
        if (!this.state.selectedCallReason) {
            this.setState({
                callReasonErrorTips: t('FlexCrm:Please select a reason for the next call!')
            })
            return
        }
        let dateTime = moment(this.state.callLaterDate)
        if (dateTime <= new Date()) {
            this.setState({
                callLaterDateTimeErrorTips: t('FlexCrm:Task time must be in the future.')
            })
            return
        }
        this.setState({
            dialChannelErrorTips: '',
            callReasonErrorTips: '',
            callLaterDateTimeErrorTips: ''
        })
        let phone = this.state.otherOperationMobile && selectedContact.mobile || selectedContact.phone
        let info = {
            isMine: this.state.nextCallIsMine,
            next_time: dateTime.utc().format('YYYY-MM-DD HH:mm:ss'),
            operating_id: authUser.id,
            customer_no: selectedContact.customer_no,
            lead_id: selectedContact.lead_id,
            phone: phone,
            is_mobile: this.state.otherOperationMobile,
            dial_channel: this.state.selectedDialChannel,
            call_reason: this.state.selectedCallReason,
        }
        openLoading(t('FlexCrm:message.create'))
        BackendApi.create_new_call_later(info).then(result => {
            closeLoading()
            successTips(t('FlexCrm:Add call task success.'))
            this.fetchCallTask()
        }).catch(error => {
            console.error('Create call task failed', error)
            closeLoading()
            if (error?.data?.error_message?.name === 'CALL_TASK_ALREADY_IN_PENDING') {
                errorTips(t('FlexCrm:Can not create a duplicate task with the same phone number.'))
            } else {
                errorTips(t("message.networkIsBusyPleaseRetry"))
            }
        })

    }

    resetCallTask = () => {
        this.setState({
            nextCallIsMine: false,

            selectedCancelReason: false,
            expanded: false,
            expandKey: 0,

            selectedDialChannel: false,
            dialChannelErrorTips: '',

            selectedCallReason: false,
            callReasonErrorTips: '',

            showCancelReasonView: false,
            selectedCancelReason: false,
            cancelReasonErrorTips: '',

            expanded: false,
            expandKey: 0,

            callLaterDateTimeErrorTips: '',

            showChangeScheduleView: false,
            newScheduleDateError: '',
        })
    }

    render = () => {
        const { t, selectedContact, authUser, offers, editStatus, classes } = this.props;
        let firstName = selectedContact.first_name
        let lastName = selectedContact.last_name
        let name_list = []
        if (firstName) { name_list.push(firstName) }
        if (lastName) { name_list.push(lastName) }
        let displayName = name_list.join(' ')
        let customerNo = selectedContact.customer_no
        let email = selectedContact.email
        let mobile = selectedContact.mobile
        let phone = selectedContact.phone
        let campaignName = selectedContact.campaign_name
        let leadId = selectedContact.lead_id
        let createDatetime = moment(selectedContact.create_datetime).format('DD/MM/YYYY HH:mm:ss')
        let zip = selectedContact.zip
        let birthday = selectedContact.birthday

        let country_code = getCurrentCountry()
        return (
            <React.Fragment>
                <Paper style={{ padding: "5px", marginTop: "10px" }} elevation={3}>
                    <CustomerNameContainer>
                        <CustomerNameStyle>{displayName}</CustomerNameStyle>
                    </CustomerNameContainer>
                    <CustomerMainInfoContainer>
                        <CustomerElementInfoContainer>
                            <CustomerElemetHeadLine>{t('label.CustomerNo')}</CustomerElemetHeadLine>
                            <CustomerElemetText>{customerNo}</CustomerElemetText>
                        </CustomerElementInfoContainer>
                        <CustomerElementInfoContainer>
                            <CustomerElemetHeadLine>{t('label.email')}</CustomerElemetHeadLine>
                            <CustomerElemetText>{email}</CustomerElemetText>
                        </CustomerElementInfoContainer>
                        <CustomerElementInfoContainer>
                            <CustomerElemetHeadLine>{t('label.Birthday')}</CustomerElemetHeadLine>
                            <CustomerElemetText>{birthday}</CustomerElemetText>
                        </CustomerElementInfoContainer>
                    </CustomerMainInfoContainer>
                    <CustomerMainInfoContainer>
                        <CustomerElementInfoContainer>
                            <CustomerElemetHeadLine>{t('label.zipcode')}</CustomerElemetHeadLine>
                            <CustomerElemetText>{zip}</CustomerElemetText>
                        </CustomerElementInfoContainer>
                        <CustomerElementInfoContainer>
                            <CustomerElemetHeadLine>{t('label.mobile')}</CustomerElemetHeadLine>
                            {mobile ?
                                <CustomerElemetText>
                                    <span>{mobile}</span>
                                    {/* <Tooltip title="Click To Call">
                                        <IconButton
                                            onClick={() => this.confirm_dial_out(mobile)}
                                            aria-label="call">
                                            <PhoneForwardedRoundedIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip> */}
                                    {/* <Tooltip title="Send sms">
                                        <IconButton
                                            onClick={() => this.send_message(mobile)}
                                            aria-label="sms">
                                            <SmsIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Send whatsapp">
                                        <IconButton
                                            onClick={() => this.send_message('whatsapp:' + mobile)}
                                            aria-label="whatsapp">
                                            <WhatsAppIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip> */}
                                    {/* <Tooltip title="Other Operations">
                                        <IconButton
                                            onClick={() => this.onOtherOperations(true)}
                                            aria-label="Other">
                                            <Timelapse fontSize="small" />
                                        </IconButton>
                                    </Tooltip> */}
                                </CustomerElemetText> : null
                            }
                            {phone ? <CustomerElemetText>
                                <span>{phone}</span>
                                {/* <Tooltip title="Click To Call">
                                    <IconButton 
                                        onClick={() => this.dial_out(phone)}
                                        aria-label="call">
                                        <PhoneForwardedRoundedIcon fontSize="small" />
                                    </IconButton>
                                    </Tooltip> */}
                                {/* <Tooltip title="Other Operations">
                                    <IconButton
                                        onClick={() => this.onOtherOperations(false)}
                                        aria-label="Other">
                                        <Timelapse fontSize="small" />
                                    </IconButton>
                                </Tooltip> */}
                            </CustomerElemetText>
                                : null
                            }
                        </CustomerElementInfoContainer>
                        <CustomerElementInfoContainer>
                            <CustomerElemetHeadLine>{t('label.lastOptician')}</CustomerElemetHeadLine>
                            <CustomerElemetText> </CustomerElemetText>
                        </CustomerElementInfoContainer>
                    </CustomerMainInfoContainer>
                    <ContainerDividingLine></ContainerDividingLine>
                    <CustomerNameContainer>
                        <CustomerNameStyle>{t('label.infos')}</CustomerNameStyle>
                    </CustomerNameContainer>
                    <CustomerMainInfoContainer>
                        <CustomerElementInfoContainer>
                            <CustomerElemetHeadLine>{t('label.product')}
                                <Tooltip title="Product Description">
                                    <IconButton
                                        onClick={() => this.onHandleProductDrawer(false)}
                                        aria-label="other">
                                        <HelpIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </CustomerElemetHeadLine>
                            <CustomerElemetText>{campaignName}</CustomerElemetText>
                        </CustomerElementInfoContainer>
                        <CustomerElementInfoContainer>
                            <CustomerElemetHeadLine>{t('label.marketingChannel')}</CustomerElemetHeadLine>
                            <CustomerElemetText ></CustomerElemetText>
                        </CustomerElementInfoContainer>
                        <CustomerElementInfoContainer>
                            <CustomerElemetHeadLine>{t('label.offerPage')}</CustomerElemetHeadLine>
                            <CustomerElemetText><a href=""></a></CustomerElemetText>
                        </CustomerElementInfoContainer>
                    </CustomerMainInfoContainer>
                    <CustomerMainInfoContainer>
                        <CustomerElementInfoContainer>
                            <CustomerElemetHeadLine>{t('label.Brand')}</CustomerElemetHeadLine>
                            <CustomerElemetText></CustomerElemetText>
                        </CustomerElementInfoContainer>
                        <CustomerElementInfoContainer>
                            <CustomerElemetHeadLine>{t('label.leadId')}</CustomerElemetHeadLine>
                            <CustomerElemetText>{leadId}</CustomerElemetText>
                        </CustomerElementInfoContainer>
                        <CustomerElementInfoContainer>
                            <CustomerElemetHeadLine>{t('label.lastRegisterDate')}</CustomerElemetHeadLine>
                            <CustomerElemetText>{createDatetime}</CustomerElemetText>
                        </CustomerElementInfoContainer>
                    </CustomerMainInfoContainer>
                    <ContainerDividingLine></ContainerDividingLine>
                    <CustomerMainInfoContainer>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                            <Grid item xs className={classes.footItemBorder} container direction="row">
                                <Grid item xs={12}><EventBusy /></Grid>
                                {selectedContact.app_details.length > 0 ?
                                    <Grid item xs container direction="row" spacing={1}>
                                        {selectedContact.app_details.map(item => {
                                            let appTime = moment(item.app_time)
                                            let appTimeFormat = appTime.format('DD/MM/YYYY HH:mm')
                                            let storeNo = item.store_ref
                                            let appLocation = item.location == 'lead' ? 'online' : item.location
                                            let futureApp = appTime.isAfter(moment())
                                            let appStyle
                                            if (futureApp) {
                                                if (item.operation_status === 'CANCEL') {
                                                    appStyle = { color: "#676464" }
                                                } else {
                                                    appStyle = { backgroundColor: "#94c11e" }
                                                }
                                            } else {
                                                appStyle = { backgroundColor: '#f74848' }
                                            }
                                            return <Grid item xs={12} key={item.event_id} container direction="row" justifyContent="flex-start" alignItems="center" style={appStyle}>
                                                <Grid item xs={2}><Typography>{storeNo}</Typography></Grid>
                                                <Grid item xs={4}><Typography>{appTimeFormat}</Typography></Grid>
                                                <Grid item xs><Typography>{appLocation}</Typography></Grid>
                                                <Grid item xs={3}>
                                                    {item.operation_status != 'CANCEL' ?
                                                        <>
                                                            {country_code == 'us' ?
                                                                <Tooltip title={t('FlexCrm:Send eye test survey email')}><IconButton
                                                                    onClick={this.openSendEyeTestSurveyEmailConfirm}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    id={item.event_id} >
                                                                    <EmailIcon style={{ fontSize: 18 }} />
                                                                </IconButton></Tooltip>
                                                                :
                                                                null
                                                            }
                                                            <Tooltip title={t('FlexCrm:Show on calendar')}><IconButton
                                                                onClick={this.onLeadAppClick}
                                                                variant="contained"
                                                                color="primary"
                                                                id={item.event_id} >
                                                                <ArrowForward style={{ fontSize: 18 }} />
                                                            </IconButton></Tooltip>
                                                        </>
                                                        :
                                                        null
                                                    }
                                                </Grid>
                                            </Grid>
                                        })}
                                    </Grid>
                                    :
                                    <Grid item xs><CustomerElemetHeadLine>{t('label.noAppointntmentsFound')}</CustomerElemetHeadLine></Grid>
                                }
                            </Grid>
                            <Grid item xs container direction="row">
                                <Grid item xs={12}><ShoppingCartOutlinedIcon /></Grid>
                                <Grid item xs>
                                    {selectedContact.sale_details.length > 0 ?
                                        <List>
                                            {selectedContact.sale_details.map(item => (
                                                <ListItem key={item.sale_name} style={{ height: "40px" }}>
                                                    <pre>{item.sale_name + '      ' + moment(item.create_date).format('DD/MM/YYYY HH:mm')
                                                        + '      ' + item.state}</pre>
                                                </ListItem>
                                            ))}
                                        </List> :
                                        <CustomerElemetHeadLine>{t('label.noRecentPurchasesFound')}</CustomerElemetHeadLine>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </CustomerMainInfoContainer>
                </Paper>

                {<Drawer open={this.state.isOpenLeadDrawer} anchor="right" transitionDuration={500}>
                    <Box p={3} style={{ maxWidth: "400px" }}>
                        <Box>
                            <Grid container justifyContent='space-between' alignItems='center'>
                                <Typography variant="h6">
                                    {t('FlexCrm:Call Task')}
                                </Typography>
                                <CloseIcon fontSize='large' color='secondary' onClick={() => {
                                    this.setState({ isOpenLeadDrawer: !this.state.isOpenLeadDrawer })
                                    this.resetCallTask()
                                }} />
                            </Grid>
                            <Divider style={{ marginTop: 8 }} />
                        </Box>
                        <Box>
                            <Grid container justifyContent='space-between' alignItems='center'>
                                <Typography variant="subtitle1">
                                    {t('FlexCrm:Next call this number at')}
                                </Typography>
                            </Grid>
                            <Grid container justifyContent='space-between' alignItems='center'>
                                <LocalizationProvider dateAdapter={AdapterDateFns} >
                                    <DateTimePicker
                                        renderInput={(props) => <TextField {...props} />}
                                        error={this.state.callLaterDateTimeErrorTips ? true : false}
                                        helperText={this.state.callLaterDateTimeErrorTips}
                                        margin="normal"
                                        id="next_call_date"
                                        label={t('FlexCrm:Hope to dial at')}
                                        value={this.state.callLaterDate}
                                        fullWidth
                                        minDate={new Date()}
                                        onChange={(e) => {
                                            this.setState({
                                                callLaterDate: e
                                            })
                                        }}
                                    />
                                </LocalizationProvider>
                                <FormControlLabel
                                    style={{ marginLeft: "1%", paddingTop: '1%', width: '100%' }}
                                    control={<Checkbox
                                        checked={this.state.nextCallIsMine}
                                        onChange={(e) => {
                                            this.setState({
                                                nextCallIsMine: e.target.checked
                                            })
                                        }} color="default" name="nextCallIsMine" />}
                                    label={t('FlexCrm:Assign to me')}
                                />
                                <TextField
                                    label={t('FlexCrm:The dial channel for the next call')}
                                    id="outlined-select-dial-channel"
                                    select
                                    margin="normal"
                                    fullWidth
                                    value={this.state.selectedDialChannel}
                                    onChange={(e) => this.selectDialChannel(e.target.value)}
                                    variant="outlined"
                                    error={this.state.dialChannelErrorTips ? true : false}
                                    helperText={this.state.dialChannelErrorTips}>
                                    <MenuItem key={false} value={false}> {t('FlexCrm:Please select the channel')}</MenuItem>
                                    {
                                        this.state.dialChannels.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item[0]}>
                                                    {item[1]}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                                <TextField
                                    label={t('FlexCrm:The most similar reason for the next call')}
                                    id="outlined-select-call-reason"
                                    select
                                    margin="normal"
                                    fullWidth
                                    value={this.state.selectedCallReason}
                                    onChange={(e) => this.selectCallReason(e.target.value)}
                                    variant="outlined"
                                    error={this.state.callReasonErrorTips ? true : false}
                                    helperText={this.state.callReasonErrorTips}>
                                    <MenuItem key={false} value={false}> {t('FlexCrm:Please select the call reason')}</MenuItem>
                                    {
                                        this.state.callReasons.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                            </Grid>
                            <Button onClick={this.onCallLaterSubmit} style={{ margin: "2ch 1ch" }} variant="contained" color="primary">
                                {t('FlexCrm:Add call Task')}
                            </Button>
                            <Divider style={{ marginTop: 8 }} />
                        </Box>
                        <Box>
                            <Grid container justifyContent='space-between' alignItems='center'>
                                <Typography variant="subtitle1">
                                    {t('FlexCrm:Call task history')}
                                </Typography>
                            </Grid>
                            <Grid container justifyContent='space-between' alignItems='center'>
                                {this.state.historyList.filter(item => item.state !== 'cancelled').map((item) => (
                                    <Card key={item.task_no} variant="outlined" style={{ width: "100%" }}>
                                        <CardContent style={{ margin: "0px", padding: "0 0 0 5px" }}>
                                            <Typography color="textSecondary" style={{ display: "inline-block", color: '#000', fontWeight: '500' }} gutterBottom>
                                                {item.state === 'pending' ?
                                                    ('Will call at ' + moment(item.schedule_date).format('lll'))
                                                    : 'Dialed at ' + moment(item.dial_date).format('lll')}
                                            </Typography>
                                            <Typography
                                                style={{
                                                    background: this.state.stateColor[item.state], display: 'inline-block', fontSize: 'small',
                                                    border: '1px solid #a29a9a', borderRadius: '5px', marginLeft: '10px'
                                                }}
                                                variant="h5"
                                                component="h5">
                                                {item.state}
                                            </Typography>
                                            <Typography color="textSecondary" style={{ display: "inline-block", color: '#000', fontWeight: '500', marginLeft: '5px' }} gutterBottom>
                                                {item.dial_attempt_progress + '/' + item.dial_attempt_limit}
                                            </Typography>
                                            <IconButton
                                                key={item.task_no}
                                                id={item.task_no}
                                                className={clsx(classes.expand, {
                                                    [classes.expandOpen]: this.state.expanded && this.state.expandKey == item.task_no,
                                                })}
                                                onClick={this.onHandleExpandClick}
                                                aria-expanded={this.state.expanded}
                                                aria-label="show more">
                                                <ExpandMoreIcon />
                                            </IconButton>
                                        </CardContent>
                                        <Collapse key={item.task_no} in={this.state.expanded && this.state.expandKey == item.task_no} style={{ paddingLeft: '5px' }} timeout="auto" unmountOnExit>
                                            <div style={{ fontWeight: '600' }}>
                                                {t('FlexCrm:Dial Channel')} :
                                                <span style={{ fontWeight: '400', marginLeft: '5px' }}>{item.dial_channel || ''}</span>
                                            </div>
                                            <div style={{ fontWeight: '600' }}>
                                                {t('FlexCrm:Call Reason')} :
                                                <span style={{ fontWeight: '400', marginLeft: '5px' }}>{item.call_reason || ''}</span>
                                            </div>
                                            <div style={{ fontWeight: '600' }}>
                                                {t('FlexCrm:Schedule Date')} :
                                                <span style={{ fontWeight: '400', marginLeft: '5px' }}>{item.schedule_date ? moment(item.schedule_date).format('lll') : ''}</span>
                                            </div>
                                            {item.state != 'pending' && item.state != 'cancelled' ? <>
                                                <div style={{ fontWeight: '600' }}>
                                                    {t('FlexCrm:Dial Date')} :
                                                    <span style={{ fontWeight: '400', marginLeft: '5px' }}>{item.dial_date ? moment(item.dial_date).format('lll') : ''}</span>
                                                </div>
                                            </> : null}
                                            {item.state === 'cancelled' ? <>
                                                <div style={{ fontWeight: '600' }}>
                                                    {t('FlexCrm:Cancel By')} :
                                                    <span style={{ fontWeight: '400', marginLeft: '5px' }}>{item.cancel_by || ''}</span>
                                                </div>
                                                <div style={{ fontWeight: '600' }}>
                                                    {t('FlexCrm:Cancel reason')} :
                                                    <span style={{ fontWeight: '400', marginLeft: '5px' }}>{item.cancel_reason || ''}</span>
                                                </div>
                                                <div style={{ fontWeight: '600' }}>
                                                    {t('FlexCrm:Cancel Date')} :
                                                    <span style={{ fontWeight: '400', marginLeft: '5px' }}>{item.cancel_date ? moment(item.cancel_date).format('lll') : ''}</span>
                                                </div>
                                            </> : null}
                                            {item.state === 'failed' ?
                                                <div style={{ fontWeight: '600' }}>
                                                    {t('FlexCrm:Failed Reason')} :
                                                    <span style={{ fontWeight: '400', marginLeft: '5px' }}>
                                                        {item.other_failed_reason ? item.other_failed_reason : (item.failed_reason ? item.failed_reason : '')}
                                                    </span>
                                                </div>
                                                : null}
                                            {item.state === 'assigned' || item.state === 'warpping' || item.state === 'completed' ? <>
                                                <div style={{ fontWeight: '600' }}>
                                                    {t('FlexCrm:Assigned Agent')} :
                                                    <span style={{ fontWeight: '400', marginLeft: '5px' }}>{item.assigned_agent || ''}</span>
                                                </div>
                                            </> : null}
                                            {item.state === 'completed' ? <>
                                                <div style={{ fontWeight: '600' }}>
                                                    {t('FlexCrm:Bussiness Result')} :
                                                    <span style={{ fontWeight: '400', marginLeft: '5px' }}>{item.bussiness_result || ''}</span>
                                                </div>
                                            </> : null}
                                            {item.state === 'pending' ? <>
                                                <ButtonGroup
                                                    disabled={this.state.taskInCanceling || this.state.taskInDeassigning || this.state.taskInChangingSchedule}
                                                    size="small" color="primary" aria-label="small outlined primary button group">
                                                    <Button variant="contained" color="primary"
                                                        disabled={this.state.taskInCanceling}
                                                        onClick={() => {
                                                            console.log(this.state)
                                                            this.setState({
                                                                showCancelReasonView: !this.state.showCancelReasonView,
                                                                selectedCallReason: false,
                                                                showChangeScheduleView: false,
                                                            })
                                                        }}>
                                                        {t('FlexCrm:Cancel task')}
                                                    </Button>
                                                    {item.assigned_agent_uid === authUser.id ?
                                                        <Button variant="contained" color="primary"
                                                            disabled={this.state.taskInDeassigning}
                                                            onClick={() => {
                                                                this.deassignedFromTask(item.task_no)
                                                            }}>
                                                            {t('FlexCrm:Deassign')}
                                                        </Button>
                                                        : null
                                                    }
                                                    <Button variant="contained" color="primary"
                                                        disabled={this.state.taskInChangingSchedule}
                                                        onClick={() => {
                                                            this.setState({
                                                                showChangeScheduleView: !this.state.showChangeScheduleView,
                                                                newScheduleDate: moment(new Date()).add(30, 'm'),
                                                                showCancelReasonView: false
                                                            })
                                                        }}>{t('FlexCrm:Change schedule')}</Button>
                                                </ButtonGroup>
                                                {this.state.showChangeScheduleView ? <>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="flex-end"
                                                    >
                                                        <Grid item xs>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns} >
                                                                <DateTimePicker
                                                                    renderInput={(props) => <TextField {...props} />}
                                                                    margin="normal"
                                                                    id="new_schedule_time"
                                                                    label={t('FlexCrm:Hope to dial at')}
                                                                    value={this.state.newScheduleDate}
                                                                    minDate={new Date()}
                                                                    error={this.state.newScheduleDateError && true || false}
                                                                    helperText={this.state.newScheduleDateError}
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            newScheduleDate: e
                                                                        })
                                                                    }}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Button variant="contained" color="primary"
                                                                disabled={this.state.taskInChangingSchedule}
                                                                onClick={() => {
                                                                    this.changeTaskScheduleDate(item.task_no)
                                                                }}>{t('button.save')}</Button>
                                                        </Grid>
                                                    </Grid>
                                                </> : null}
                                                {this.state.showCancelReasonView ? <>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="flex-end"
                                                    >
                                                        <Grid item xs={12}><TextField
                                                            label={t('FlexCrm:The most similar cancel reason')}
                                                            id="outlined-select-cancel-reason"
                                                            select
                                                            margin="normal"
                                                            fullWidth
                                                            value={this.state.selectedCancelReason}
                                                            onChange={(e) => this.selectCancelReason(e.target.value)}
                                                            variant="outlined"
                                                            error={this.state.cancelReasonErrorTips ? true : false}
                                                            helperText={this.state.cancelReasonErrorTips}>
                                                            <MenuItem key={false} value={false}> {t('FlexCrm:Please select the cancel reason')}</MenuItem>
                                                            {
                                                                this.state.cancelReasons.map((item, index) => {
                                                                    return (
                                                                        <MenuItem key={index} value={item.id}>
                                                                            {item.name}
                                                                        </MenuItem>
                                                                    )
                                                                })
                                                            }
                                                        </TextField></Grid>
                                                        <Grid item xs={4}>
                                                            <Button variant="contained" color="primary"
                                                                disabled={this.state.taskInCanceling}
                                                                onClick={() => {
                                                                    this.cancelCallTask(item.task_no)
                                                                }}>{t('button.save')}</Button>
                                                        </Grid>
                                                    </Grid>


                                                </> : null}
                                            </> : null}
                                        </Collapse>
                                    </Card>
                                ))
                                }
                            </Grid>
                        </Box>
                    </Box>
                </Drawer>}

                {<Drawer open={this.state.isOpenLeadProductDrawer} anchor="right" transitionDuration={500}>
                    <Box p={3} style={{ maxWidth: "450px", minWidth: "450px" }}>
                        <Box>
                            <Grid container justifyContent='space-between' alignItems='center'>
                                <Typography variant="h6">
                                    {t('FlexCrm:Product Description')}
                                </Typography>
                                <CloseIcon fontSize='large' color='secondary' onClick={() => {
                                    this.setState({
                                        isOpenLeadProductDrawer: !this.state.isOpenLeadProductDrawer,
                                        selectProductOffer: ''
                                    })
                                }} />
                            </Grid>
                            <Divider style={{ marginTop: 8 }} />
                        </Box>
                        <Box>
                            <Grid container justifyContent='space-between' alignItems='center'>
                                <Typography variant="subtitle1">
                                    {t('FlexCrm:Product List')}
                                </Typography>
                            </Grid>
                            <Grid container justifyContent='space-between' alignItems='center'>
                                <TextField
                                    label={t('FlexCrm:Select a product to read more information')}
                                    select
                                    margin="dense"
                                    fullWidth
                                    value={this.state.selectProductOffer}
                                    onChange={(e) => this.onChangeProductSelect(e.target.value)}
                                    variant="outlined">
                                    <MenuItem key={false} value={false}> {t('FlexCrm:Please select product')}</MenuItem>
                                    {
                                        offers.map(option => {
                                            return (
                                                <MenuItem key={option.campaign_code} value={option.campaign_code}>
                                                    {/* {option.campaign_name}({option.campaign_code}) */}
                                                    {option.campaign_name}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                            </Grid>
                            <Grid container justifyContent='space-between' alignItems='center'>
                                <Box style={{ maxWidth: "450px", minWidth: "450px", paddingTop: "10px", paddingBottom: "10px" }}>
                                    <Typography variant="subtitle1" >
                                        {t('FlexCrm:Product Details')}
                                    </Typography>
                                </Box>
                                <Grid style={{ fontSize: "13px" }}>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.ProductOfferDescription }}></div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Drawer>}
            </React.Fragment>
        );
    }

}

export default withStyles(styles)(LeadDetail);

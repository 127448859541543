import * as BackendApi from "../components/BackendApi";

const JUMP_TO_DATE = "JUMP_TO_DATE";
const SELECT_EVENT = "SELECT_EVENT";
const HIGHLIGHT_EVENT = "HIGHLIGHT_EVENT";
const SEARCH_STORE = "SEARCH_STORE";
const SELECT_STORE = "SELECT_STORE";

const initialState = {
  calendarEvents: [
    {
      title: "test",
      start: new Date(2021, 0, 7, 9, 0, 0),
      end: new Date(2021, 0, 7, 10, 0, 0),
      allDay: false,
      id: 1,
    },
    {
      title: "test",
      start: new Date(2021, 0, 7, 12, 30, 0),
      end: new Date(2021, 0, 7, 13, 30, 0),
      allDay: false,
      id: 2,
    },
  ],
  calendarDate: new Date(),
  highlightedEvent: {},
  selectedEvent: {},

  selectedStore: null,
};

export class Actions {
  static selectStore = (store) => {
    return {
      type: SELECT_STORE,
      payload: store,
    };
  };

  static jumpToDate = (date) => {
    return {
      type: JUMP_TO_DATE,
      payload: date,
    };
  };

  static highlightEvent = (event) => {
    return {
      type: HIGHLIGHT_EVENT,
      payload: event,
    };
  };

  static selectEvent = (event) => {
    return {
      type: SELECT_EVENT,
      payload: event,
    };
  };

  static searchStore = (optician_search, optician_zip_search) => {
    return {
      type: SEARCH_STORE,
      payload: BackendApi.search_store_list(
        optician_search,
        optician_zip_search
      ),
    };
  };
}

export function reduce(state = initialState, action) {
  switch (action.type) {
    case JUMP_TO_DATE: {
      return { ...state, calendarDate: action.payload };
    }
    case SELECT_EVENT: {
      return { ...state, selectedEvent: action.payload };
    }
    case HIGHLIGHT_EVENT: {
      return { ...state, highlightedEvent: action.payload };
    }
    case SEARCH_STORE: {
      return { ...state, searchStore: action.payload };
    }
    case SELECT_STORE: {
      return { ...state, selectedStore: action.payload };
    }
    default:
      return state;
  }
}

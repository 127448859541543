import * as BackendApi from "../components/BackendApi";

const SET_SEARCH_FIELD = "SET_SEARCH_FIELD";
const SET_CONTACT_LIST = "SET_CONTACT_LIST";
const SELECT_CONTACT = "SELECT_CONTACT";
const SEARCH_CONTACT_LIST = "SEARCH_CONTACT_LIST";
const SET_EDIT_STATUS = "SET_EDIT_STATUS";
const SET_LEAD_APP = "SET_LEAD_APP";
const SET_TAB_LEAD_LIST = "SET_TAB_LEAD_LIST";
const SET_SEARCH_LEAD_ID = "SET_SEARCH_LEAD_ID";
const SET_SEARCH_INBOUND = "SET_SEARCH_INBOUND";

const initialState = {
  researchContactListAfterLogin: false,

  searchField: "",
  searchLeadId: "",
  searchInbound: false,
  inSearchingContact: false,
  searchContactMessage: "",
  contactList: [],
  selectedContactIndex: -1,

  selectedContact: null,
  editStatus: false,
  leadApp: {},
  tabLeadList: [],
};

export class Actions {
  static selectContact = (contact, index) => {
    return {
      type: SELECT_CONTACT,
      payload: { contact: contact, index: index },
    };
  };
  static setContactList = (contactList) => {
    return {
      type: SET_CONTACT_LIST,
      payload: contactList,
    };
  };
  static setSearchField = (searchField) => {
    return {
      type: SET_SEARCH_FIELD,
      payload: searchField,
    };
  };
  static setSearchLeadId = (searchLeadId) => {
    return {
      type: SET_SEARCH_LEAD_ID,
      payload: searchLeadId || '',
    };
  };
  static setSearchInbound = (searchInbound) => {
    return {
      type: SET_SEARCH_INBOUND,
      payload: searchInbound || false,
    };
  };
  static setEditStatus = (editStatus) => {
    return {
      type: SET_EDIT_STATUS,
      payload: editStatus,
    };
  };
  static setLeadApp = (leadApp) => {
    return {
      type: SET_LEAD_APP,
      payload: leadApp,
    };
  };

  static searchContactList = (searchField, leadId = "", inbound = false) => {
    return {
      type: SEARCH_CONTACT_LIST,
      payload: BackendApi.search_lead_list({
        phone_or_email: searchField,
        lead_id: leadId || '',
        inbound: inbound || false,
      }),
    };
  };

  static setTabLeadList = (leadList) => {
    return {
      type: SET_TAB_LEAD_LIST,
      payload: leadList,
    };
  };
}

export function reduce(state = initialState, action) {
  switch (action.type) {
    case `${SEARCH_CONTACT_LIST}_PENDING`: {
      return { ...state, inSearchingContact: true };
    }
    case `${SEARCH_CONTACT_LIST}_FULFILLED`: {
      let message = "";
      if (action.payload.data.length <= 0) {
        message = "FlexCrm:message.noSearchResultsFound";
      }
      return {
        ...state,
        inSearchingContact: false,
        contactList: action.payload.data,
        selectedContactIndex: -1,
        searchContactMessage: message,
        researchContactListAfterLogin: false,
        editStatus: false,
      };
    }
    case `${SEARCH_CONTACT_LIST}_REJECTED`: {
      let message = "";
      let retryAfterLogin = false;
      if (action.payload.code === 401) {
        // message = 'message.pleaseTryAgainBecauseOfRelogin'
        retryAfterLogin = true;
      } else {
        message = "message.networkIsBusyPleaseRetry";
      }
      return {
        ...state,
        inSearchingContact: false,
        contactList: [],
        selectedContactIndex: -1,
        searchContactMessage: message,
        researchContactListAfterLogin: retryAfterLogin,
        editStatus: false,
      };
    }
    // case RESET_CONTACT_LIST: {
    //   return {
    //     ...state,
    //     selectedContactIndex: -1
    //   };
    // }
    case SELECT_CONTACT: {
      return {
        ...state,
        selectedContact: action.payload.contact,
        selectedContactIndex: action.payload.index,
        searchLeadId: "",
        searchInbound: false
      };
    }
    case SET_SEARCH_FIELD: {
      return { ...state, searchField: action.payload };
    }
    case SET_SEARCH_LEAD_ID: {
      return { ...state, searchLeadId: action.payload };
    }
    case SET_SEARCH_INBOUND: {
      return { ...state, searchInbound: action.payload };
    }
    case SET_CONTACT_LIST: {
      return {
        ...state,
        contactList: action.payload,
        selectedContactIndex: -1,
      };
    }
    case SET_EDIT_STATUS: {
      return { ...state, editStatus: action.payload };
    }
    case SET_LEAD_APP: {
      return { ...state, leadApp: action.payload };
    }
    case SET_TAB_LEAD_LIST: {
      return { ...state, tabLeadList: action.payload };
    }
    default:
      return state;
  }
}

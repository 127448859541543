import * as BackendApi from '../BackendApi'

import { Divider, Headline, styles } from './CallNoticeArea.Styles';
import React, { Component } from 'react';
import {
  closeLoading,
  errorTips,
  openLoading,
  warningTips,
} from '../../utils'

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import moment from '../../i18n/moment'
import { withStyles } from "@mui/styles";

class CallNoticeArea extends Component {
  constructor(props) {
    super(props)
    this.state = {
      CallNoticeErrorTips: '',
      messageTextValue: '',
      NoticeList: []
    }
  }

  componentDidMount() {
    if (this.state.NoticeList.length == 0 && this.props.selectedContact) {
      BackendApi.search_call_notice({
        lead_id: this.props.selectedContact.lead_id,
        partner_id: this.props.selectedContact.customer_no
      }).then(result => {
        if (result.success) {
          this.setState({ NoticeList: result.data })
        }
      })
    }
  }

  createNotice = () => {
    const { t, selectedContact, authUser } = this.props;
    if (selectedContact) {
      if (this.state.messageTextValue) {
        let info = {
          partner_id: selectedContact.customer_no,
          lead_id: selectedContact.lead_id,
          notice: this.state.messageTextValue,
          founder: authUser.id
        }
        openLoading(t('FlexCrm:tips.loading'))
        BackendApi.create_call_notice(info).then(result => {
          if (result.success) {
            BackendApi.search_call_notice({
              lead_id: selectedContact.lead_id,
              partner_id: selectedContact.customer_no
            }).then(list => {
              if (list.success) {
                this.setState({
                  NoticeList: list.data,
                  messageTextValue: ''
                })
              }
            })
            closeLoading()
          } else {
            errorTips(t('FlexCrm:tips.CreateNoticeError'))
            closeLoading()
          }
        }).catch(error => {
          console.error('create_call_notice', error)
          errorTips(t('FlexCrm:tips.CreateNoticeError'))
          closeLoading()
        })
      } else {
        this.setState({
          CallNoticeErrorTips: t('FlexCrm:tips.NoticeCannotBeEmpty')
        })
      }
    } else {
      warningTips(t('FlexCrm:tips.MustChooseCustomer'))
    }
  }

  render() {
    const { t, classes } = this.props;

    return (
      <React.Fragment>
        <Grid container direction="row" justifyContent="flex-start">
          <Headline variant="h6">{t("FlexCrm:Call Notice")}</Headline>
        </Grid>
        <Grid className={classes.createNotice} container direction="row" justifyContent="flex-start">
          <TextField
            id="standard-multiline-static"
            label={t("FlexCrm:Call Notice")}
            multiline
            rows="4"
            margin="normal"
            error={this.state.CallNoticeErrorTips ? true : false}
            helperText={t("FlexCrm:Pleas write a Call Notice")}
            className={classes.textField}
            value={this.state.messageTextValue}
            onChange={(e) => {
              this.setState({
                messageTextValue: e.target.value
              })
            }}
          />
        </Grid>
        <Grid className={classes.createNotice} container direction="row" justifyContent="flex-start">
          <Button onClick={this.createNotice} variant="outlined" color="primary" className="">
          {t("FlexCrm:Create Notice")}
          </Button>
        </Grid>
        <Divider></Divider>
        <Grid container direction="row" justifyContent="flex-start">
          <Headline variant="h6">{t("FlexCrm:All Call Notice(s)")}</Headline>
        </Grid>
        <Grid container direction="row">
          {this.state.NoticeList.length > 0 &&
            this.state.NoticeList.map((item, index) => {
              return <Card className={classes.card} key={index}>
                <CardContent>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    {moment(item.create_date).format('lll')}
                  </Typography>
                  <Typography className={classes.agentheadline} color="textSecondary">
                    <b>Agent {item.founder} schrieb...</b>
                  </Typography>
                  <Typography className={classes.agentText} component="p">
                    {item.notice}
                  </Typography>
                </CardContent>
              </Card>

            })
          }
        </Grid>
      </React.Fragment>
    );
  }
};

export default withStyles(styles)(CallNoticeArea);

export const styles = theme => ({
    button: {
      color: '#fff !important',
      backgroundColor: '#94c11e !important',
      height: '55px !important',
      width: '150px !important',
      '&:hover': {
        background: "#575756 !important",
        color:'white !important'
        },
    },
    searchContainer:{
      marginTop:'10px !important',
      borderBottom:'1px solid rgb(204, 204, 204) !important',
    },
    searchFlex:{
      marginLeft:'2%',
      marginBottom: '1%'
    },
    selectFlex:{
      flex:'0.5',
      alignSelf: 'center',
      marginTop: '5px',
      marginRight: 'auto',
      marginBottom: '1%',
      marginLeft: '1%',
    },
    buttonFlexSearch:{
      alignSelf: 'center',
      marginTop: '5px',
      marginLeft: '1%',
      marginBottom: '1%'
    },
    buttonFlexCreate:{
      alignSelf: 'center',
      marginTop: '5px',
      marginRight: '2%',
      marginBottom: '1%'
    },
    error:{
      color:'red',
      marginTop: '4%',
      marginLeft: '10%'
    },
    closeButton:{
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
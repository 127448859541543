const OPEN_COMMON_LOADING = 'OPEN_COMMON_LOADING'
const CLOSE_COMMON_LOADING = 'CLOSE_COMMON_LOADING'

const initialState = {
    isOpen: false,
    message: '',
    useTranslate: false,
};

export class Actions {
    static open = (message, useTranslate) => {
        return {
            type: OPEN_COMMON_LOADING,
            payload: { message: message, useTranslate: useTranslate }
        }
    }

    static close = () => {
        return { type: CLOSE_COMMON_LOADING }
    }
}

export function reduce(state = initialState, action) {
    switch (action.type) {
        case OPEN_COMMON_LOADING: {
            return {
                ...state,
                isOpen: true,
                message: action.payload.message,
                useTranslate: action.payload.useTranslate
            };
        }
        case CLOSE_COMMON_LOADING: {
            return { ...initialState };
        }
        default:
            return state;
    }
}

import { styled } from "@mui/material/styles";

export const Headline = styled("div")`
  color: rgb(34, 34, 34);
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.1;
  letter-spacing: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 2.3em;
  background: none;
  transition: color 0.2s ease 0s;
  overflow: hidden;
`;

export const styles = () => ({
  textField: {
    minWidth: 500,
  },
  agentText: {
    fontSize: "x-large",
  },
  agentheadline: {
    fontSize: "large",
    fontStyle: "italic",
    fontVariant: "all-petite-caps",
    fontWeight: "bold",
  },
  timelineContainer: {},
  timelineStyleLeft: {
    padding: "1px 20px",
  },
  timelineStyleContent: {
    flex: 0,
    display: "flex",
    flexDirection: "column",
    padding: "0px 8px",
  },
  timelineStyleContentCal: {
    flex: 0,
    padding: "1px 8px",
  },
  paper: {
    padding: "6px 16px",
    display: "flex",
    flexDirection: "column",
    marginTop: "4%",
    minWidth: "180px",
  },
  timelineStyleContentVoice: {
    flex: 0,
    display: "flex",
    flexDirection: "column",
    padding: "0px 8px",
  },
  paperChat: {
    position: "relative",
    left: "130%",
    padding: "6px 16px",
    display: "flex",
    flexDirection: "column",
    marginTop: "10%",
    background: "#757575",
    color: "white",
    minWidth: "180px",
  },
  paperChatBot: {
    position: "relative",
    left: "130%",
    padding: "6px 16px",
    display: "flex",
    flexDirection: "column",
    marginTop: "10%",
    background: "#94c11e",
    color: "white",
    minWidth: "180px",
  },
  calendarTimelineContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
    fontSize: "1.5em",
    alignItems: "center",
    marginTop: "6px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "darkGrey",
    borderRadius: "25px",
  },
  calendarTimelineDatePaper: {
    padding: "6px",
    background: "black",
    display: "flex",
    flexDirection: "row",
    color: "white",
    borderRadius: "25px",
  },
  calendarTimelineDateStyle: {
    fontSize: "0.8em",
    color: "white",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    marginBottom: "0px",
    textOverflow: "ellipsis",
    marginLeft: "2%",
    marginTop: "2%",
  },
  calenderToday: {
    width: "0.8em",
  },
  dotStyle: {
    color: "white",
  },
  timeStyle: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: "black",
    marginTop: "2%",
    marginLeft: "2%",
  },
  messagebubel: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  messageName: {
    flex: "0 1 auto",
    overflow: "hidden",
    fontSize: "10px",
    marginTop: "2%",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    marginRight: "8px",
    marginBottom: "0px",
    textOverflow: "ellipsis",
  },
  messageTime: {
    flex: "0 1 auto",
    overflow: "hidden",
    fontSize: "10px",
    marginTop: "2%",
    whiteSpace: "nowrap",
    marginRight: "8px",
    marginBottom: "0px",
    textOverflow: "ellipsis",
  },
  messsageText: {
    flex: "0 1 auto",
    fontSize: "12px",
    marginTop: "3px",
    lineHeight: "1.54",
    marginBottom: "0px",
    overflowWrap: "break-word",
  },
  whatsapp: {
    color: "white",

    backgroundColor: "rgb(43,184,38)",
  },
  sms: {
    color: "white",

    backgroundColor: "rgb(38 184 171)",
  },
  phone: {
    color: "white",

    backgroundColor: "orange",
  },
  timeContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "23%",
  },
  callNoticeButtonText: {
    flex: "0 1 auto",
    fontSize: "12px",
    marginTop: "3px",
    lineHeight: "1.54",
    marginBottom: "0px",
    overflowWrap: "break-word",
  },
  callNoticeButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  callNoticeButtonIcon: {
    width: "0.9em",
    height: "0.9em",
    color: "#213646",
  },
  callnoticeDialogContainer: {
    display: "flex",
    flexDirection: "column",
  },
  modalContentText: {
    flex: "0 1 auto",
    fontSize: "11px",
    marginTop: "3px",
    lineHeight: "1.54",
    marginBottom: "0px",
    overflowWrap: "break-word",
  },
  trimLine: {
    height: "2px",
    flexGrow: "1",
    backgroundColor: "#bdbdbd",
    marginBottom: "1%",
    marginTop: "1%",
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "10%",
  },
  progress: {
    color: "#575756",
  },
});

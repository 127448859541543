import { CustomerAreaContainer, styles } from './CustomerArea.Styles';
import React, { Component } from 'react';

import AppBar from '@mui/material/AppBar';
import LeadDetail from './LeadDetail/LeadDetail.Container';
import LeadSearch from './LeadSearch/LeadSearch.Container';
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { withStyles } from "@mui/styles";

class CustomerArea extends Component {

    static propTypes = {
        selectedContact: PropTypes.object,
        setTabLeadList: PropTypes.func,
    }

    constructor(props) {
        super(props);
    }

    onTabsDoubleClick = (currentLeadId) => {
        const { contactList, selectContact, tabLeadList, setTabLeadList } = this.props;
        let tabLeads = tabLeadList
        let currentIndex = -1
        let nextShowIndex = -1
        tabLeads.map((lead, index) => {
            if (lead.lead_id === currentLeadId) {
                tabLeads.splice(index, 1)
                currentIndex = index
            }
        })
        if (currentIndex >= 0) {
            setTabLeadList(tabLeads)
            if (currentIndex <= tabLeads.length - 1) {
                nextShowIndex = currentIndex
            } else {
                nextShowIndex = tabLeads.length - 1
            }
        }
        if (nextShowIndex >= 0) {
            let nextLead = tabLeads[nextShowIndex]
            let inContactList = false
            contactList.map((lead, index) => {
                if (lead.lead_id === nextLead.lead_id) {
                    inContactList = true
                    selectContact(lead, index)
                }
            })
            if (!inContactList) {
                selectContact(nextLead, -1)
            }
        } else {
            selectContact(null, -1)
        }

    }


    onTabsChange = (event, toLeadId) => {
        const { contactList, selectContact, tabLeadList } = this.props;
        let inContactList = false
        contactList.map((lead, index) => {
            if (lead.lead_id === toLeadId) {
                inContactList = true
                selectContact(lead, index)
            }
        })
        if (!inContactList) {
            tabLeadList.map((lead, index) => {
                if (lead.lead_id == toLeadId) {
                    selectContact(lead, -1)
                }
            })
        }
    }

    render() {
        const { selectedContact, tabLeadList } = this.props
        let selectedLeadId = selectedContact?.lead_id || false
        return (
            <React.Fragment>
                <CustomerAreaContainer>
                    <LeadSearch></LeadSearch>
                    {tabLeadList.length > 0 ?
                        <AppBar position="static" color="default">
                            <Tabs value={selectedLeadId} indicatorColor="primary"
                                textcolor="primary" variant="scrollable"
                                scrollButtons="auto"
                                onChange={this.onTabsChange}
                            >
                                {tabLeadList.map((list) => (
                                    <Tab wrapped onDoubleClick={() => this.onTabsDoubleClick(list.lead_id)}
                                        key={list.lead_id}
                                        value={list.lead_id}
                                        label={(list.phone || list.mobile) ? (list.phone ? list.phone : list.mobile) : list.email}
                                        style={{ maxWidth: '150px' }}>
                                    </Tab>
                                ))}
                            </Tabs>
                        </AppBar> : null
                    }
                    {selectedContact ? <LeadDetail></LeadDetail> : null}

                </CustomerAreaContainer>
            </React.Fragment>
        )

    }
}

export default withStyles(styles)(CustomerArea);

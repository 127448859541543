const SET_OPTOMETRY_CUSTOMER = "SET_OPTOMETRY_CUSTOMER"
const CLEAR_OPTOMETRY_CUSTOMER = "CLEAR_OPTOMETRY_CUSTOMER"

const initialState = {
    customer: null,
};

export class Actions {
    static setCustomer = (customer) => ({ type: SET_OPTOMETRY_CUSTOMER, payload: customer })
    static clearCustomer = () => ({ type: CLEAR_OPTOMETRY_CUSTOMER })
}

export function reduce(state = initialState, action) {
    switch (action.type) {
        case SET_OPTOMETRY_CUSTOMER:
            return { ...state, customer: action.payload }
        case CLEAR_OPTOMETRY_CUSTOMER:
            return { ...state, customer: null }
        default:
            return state;
    }
}

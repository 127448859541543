import "react-phone-input-2/lib/style.css";

import React, { Component } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import PhoneInput from "react-phone-input-2";
import { Styles } from "./styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { getCurrentCountry } from "../../../utils";
import { green } from "@mui/material/colors";
import moment from "../../../i18n/moment";
import { withStyles } from "@mui/styles";
import { withTranslation } from "react-i18next";

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

class CreateAppDialog extends Component {
  state = {
    isSubmit: false,
    event: {
      id: 0,
      first_name: "",
      last_name: "",
      email: "",
      zipcode: "",
      phone_prefix: "",
      phone_number: "",
      campaign_code: "",
      description: "",
      eye_test: true,
      remark: ""
    },
    noEmail: false,
    errors: {
      firstNameErrorTips: "",
      lastNameErrorTips: "",
      emailErrorTips: "",
      zipcodeErrorTips: "",
      phonePrefixErrorTips: "",
      phoneNumberErrorTips: "",
      offerErrorTips: "",
      remarkErrorTips: ""
    },
    tags: [{'key':1, 'remark':''},
    {'key':2, 'remark':'20 Monate nach Kauf (purchase more than 20 Month ago)'},
    {'key':3, 'remark':'24 Monate nach Kauf (purchase more than 24 Month ago)'},
    {'key':4, 'remark':'Last-Minute (Store Kalender ist zu leer)'},
    {'key':5, 'remark':'Inbound Hotline'},
    {'key':6, 'remark':'Anruf Print'}]
  };

  constructor(props) {
    super(props);
    const {
      event_offer,
      event_email,
      event_description,
      zipcode,
      event_firstname,
      event_lastname,
      event_phone,
      event_mobile,
    } = this.props;
    let code = getCurrentCountry();
    console.info("code---", code);
    this.state.event = {
      ...this.state.event,
      ...{
        first_name: event_firstname,
        last_name: event_lastname,
        email: event_email,
        zipcode: zipcode,
        phone_number: event_mobile ? event_mobile : event_phone,
        campaign_code: event_offer,
        description: event_description,
        eye_test: code === "gb" || code === "us" || code === "de" ? false : true,
      },
    };
  }

  keyEnterCheck = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  validEventAttribute = () => {
    const { t } = this.props;
    let errors = {};
    if (!this.state.event.first_name) {
      errors = {
        ...errors,
        firstNameErrorTips: t("FlexCalendar:tips.requiredFirstName"),
      };
    }
    if (!this.state.event.last_name) {
      errors = {
        ...errors,
        lastNameErrorTips: t("FlexCalendar:tips.requiredLastName"),
      };
    }
    if (!this.state.event.email && !this.state.noEmail) {
      errors = {
        ...errors,
        emailErrorTips: t("FlexCalendar:tips.requiredEmail"),
      };
    }
    if (!this.state.event.zipcode) {
      errors = {
        ...errors,
        zipcodeErrorTips: t("FlexCalendar:tips.requiredZipcode"),
      };
    }
    if (!this.state.event.phone_number) {
      errors = {
        ...errors,
        phoneNumberErrorTips: t("FlexCalendar:tips.requiredPhoneNumber"),
      };
    }
    if (!this.state.event.campaign_code) {
      errors = {
        ...errors,
        offerErrorTips: t("FlexCalendar:tips.requiredOffer"),
      };
    }
    if (!this.state.event.remark) {
      errors = {
        ...errors,
        remarkErrorTips: t("FlexCalendar:tips.requiredTags"),
      };
    }
    this.setState({ errors: errors });

    return Object.keys(errors).length === 0 ? true : false;
  };

  onSubmit = () => {
    const { onClose, offers, CreateApp, app_time } = this.props;
    if (this.validEventAttribute()) {
      CreateApp(this.state.event);
    }
  };
  render() {
    const {
      t,
      classes,
      open,
      onClose,
      offers,
      operation_app_id,
      app_time,
      BusinessHoursList,
    } = this.props;
    let code = getCurrentCountry();
    let app_date = moment(app_time).format("YYYY-MM-DD");
    let lm = "";
    if (BusinessHoursList) {
      BusinessHoursList.map((option, index) => {
        if (option.date === app_date) {
          if (index === 0 || index === 1) {
            lm = "lm1";
          } else if (index === 2) {
            lm = "lm2";
          } else if (index === 3) {
            lm = "lm3";
          }
        }
      });
    }
    return (
      <Dialog
        aria-labelledby="simple-dialog-title"
        open={open}
        onClose={onClose}
      >
        <DialogTitle id="simple-dialog-title" title="">
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h6" paragraph={true}>
              {operation_app_id
                ? t("FlexCalendar:title.editApp")
                : t("FlexCalendar:title.createApp")}
            </Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <Box ml={1}>
            <Typography variant="h6" paragraph={true}>
              {t("label.date")} : {moment(app_time).format("DD/MM/YYYY HH:mm")}
            </Typography>
          </Box>

          <Grid container justifyContent="space-between">
            <TextField
              id="firstname-input"
              required
              error={this.state.errors.firstNameErrorTips ? true : false}
              helperText={this.state.errors.firstNameErrorTips}
              label={t("label.firstName")}
              type="text"
              name="firstName"
              autoComplete="family-name"
              margin="normal"
              variant="outlined"
              value={this.state.event.first_name}
              onChange={(e) =>
                this.setState({
                  event: { ...this.state.event, first_name: e.target.value },
                })
              }
              style={{
                flexBasis: "49%",
                marginTop: "0px",
                marginBottom: "5px",
              }}
            />
            <TextField
              id="lastname-input"
              required
              error={this.state.errors.lastNameErrorTips ? true : false}
              helperText={this.state.errors.lastNameErrorTips}
              label={t("label.lastName")}
              type="text"
              name="lastName"
              autoComplete="additional-name"
              margin="normal"
              variant="outlined"
              value={this.state.event.last_name}
              onChange={(e) =>
                this.setState({
                  event: { ...this.state.event, last_name: e.target.value },
                })
              }
              style={{
                flexBasis: "49%",
                marginTop: "0px",
                marginBottom: "5px",
              }}
            />
          </Grid>
          <Grid container justifyContent="space-between">
            <TextField
              id="email-input"
              error={this.state.errors.emailErrorTips ? true : false}
              helperText={this.state.errors.emailErrorTips}
              label={t("label.email")}
              type="text"
              name="email"
              margin="normal"
              variant="outlined"
              autoComplete="email"
              value={this.state.event.email}
              onChange={(e) =>
                this.setState({
                  event: { ...this.state.event, email: e.target.value },
                })
              }
              style={{
                flexBasis: "49%",
                marginTop: "0px",
                marginBottom: "5px",
              }}
            />
            <TextField
              id="zipcode-input"
              required
              error={this.state.errors.zipcodeErrorTips ? true : false}
              helperText={this.state.errors.zipcodeErrorTips}
              label={t("label.zipcode")}
              type="text"
              autoComplete="zipcode"
              margin="normal"
              variant="outlined"
              value={this.state.event.zipcode}
              onChange={(e) =>
                this.setState({
                  event: { ...this.state.event, zipcode: e.target.value },
                })
              }
              style={{
                flexBasis: "49%",
                marginTop: "0px",
                marginBottom: "5px",
              }}
            />
          </Grid>
          <div className={"appPhone"}>
            <PhoneInput
              country={getCurrentCountry()}
              onlyCountries={[
                "at",
                "de",
                "gb",
                "ch",
                "li",
                "pl",
                "us",
                "it",
                "es",
                "ch",
              ]}
              ref="phone"
              id="phone"
              label={t("label.phoneNumber")}
              variant="outlined"
              color="secondary"
              style={{ display: "inline-flex" }}
              value={this.state.event.phone_number}
              onChange={(value, data, event, formattedValue) => {
                let phone = formattedValue.replace(/\(|\)|\-|\s/g, "");
                this.setState(
                  {
                    event: {
                      ...this.state.event,
                      phone_number:
                        phone === "" || phone.length <= 4 ? "" : phone,
                      phone_prefix:
                        phone === "" || phone.length <= 4
                          ? ""
                          : "+" + data.dialCode,
                    },
                  },
                  () => {
                    console.info(this.state.event.phone_prefix);
                  }
                );
              }}
            ></PhoneInput>
            {this.state.errors.phoneNumberErrorTips ? (
              <label
                style={{
                  fontSize: "0.75rem",
                  color: "red",
                  margin: "4px 14px 0px 14px",
                }}
              >
                {this.state.errors.phoneNumberErrorTips}
              </label>
            ) : null}
          </div>
          <TextField
            id="outlined-select-offer-native"
            select
            error={this.state.errors.offerErrorTips ? true : false}
            helperText={this.state.errors.offerErrorTips}
            label={t("label.offer")}
            fullWidth
            name="offer"
            margin="normal"
            variant="outlined"
            value={this.state.event.campaign_code}
            onChange={(e) =>
              this.setState({
                event: { ...this.state.event, campaign_code: e.target.value },
              })
            }
            style={{ marginTop: "0px", marginBottom: "5px" }}
          >
            {offers.map((option) => {
              let CCode = option.campaign_code;
              if (CCode.indexOf("lm") !== -1) {
                if (lm && CCode.indexOf(lm) !== -1) {
                  return (
                    <MenuItem
                      key={option.campaign_code}
                      value={option.campaign_code}
                    >
                      {option.campaign_name}
                    </MenuItem>
                  );
                } else if (
                  !lm &&
                  CCode.indexOf("lm1") === -1 &&
                  CCode.indexOf("lm2") === -1 &&
                  CCode.indexOf("lm3") === -1
                ) {
                  return (
                    <MenuItem
                      key={option.campaign_code}
                      value={option.campaign_code}
                    >
                      {option.campaign_name}
                    </MenuItem>
                  );
                }
              } else {
                return (
                  <MenuItem
                    key={option.campaign_code}
                    value={option.campaign_code}
                  >
                    {option.campaign_name}
                  </MenuItem>
                );
              }
            })}
          </TextField>
          <TextField
            id="outlined-select-offer-native"
            select
            error={this.state.errors.remarkErrorTips ? true : false}
            helperText={this.state.errors.remarkErrorTips}
            label={t("label.tags")}
            fullWidth
            name="tags"
            margin="normal"
            variant="outlined"
            value={this.state.event.remark}
            onChange={(e) =>
              this.setState({
                event: { ...this.state.event, remark: e.target.value },
              })
            }
            style={{ marginTop: "0px", marginBottom: "5px" }}
          >
            {this.state.tags.map((option) => {
                return (
                  <MenuItem
                    key={option.key}
                    value={t(option.remark)}
                  >
                    {t(option.remark)}
                  </MenuItem>
                );
            })}
          </TextField>
          <TextField
            id="outlined-description-input"
            label={t("label.description")}
            fullWidth
            type="text"
            margin="normal"
            variant="outlined"
            value={this.state.event.description}
            onChange={(e) =>
              this.setState({
                event: { ...this.state.event, description: e.target.value },
              })
            }
            style={{ marginTop: "0px", marginBottom: "5px" }}
          />
          <FormControlLabel
						control={<GreenCheckbox name="No Email" onChange={
							(e) => {
								this.setState({
									noEmail: !this.state.noEmail
								})
							}
						} />}
						label={t('FlexCalendar:label.noEmail')}
					/>
          {code === "gb" || code === "us" || code === "de" ? (
            <FormControlLabel
              control={
                <GreenCheckbox
                  name="Eye Test"
                  onChange={(e) => {
                    this.setState({
                      event: {
                        ...this.state.event,
                        eye_test: e.target.checked,
                      },
                    });
                  }}
                />
              }
              label={t("FlexCalendar:label.eyetest")}
            />
          ) : null}

          <DialogActions>
            <Button
              disabled={this.state.isSubmit}
              onClick={this.onSubmit}
              className={classes.buttonGreen}
            >
              {t("button.save")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(Styles)(withTranslation()(CreateAppDialog));

import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import AgentView from "./Agent";
import { Actions as AgentViewActions } from "../../reducers/AgentState";
import { Actions as CustomerActions } from "../../reducers/CustomerState";
import { Actions as DateCenterActions } from "../../reducers/DataCenterState";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

const mapStateToProps = (state) => ({
  needSelectRemarketingTag: state.reasonView.needSelectRemarketingTag,
  selectedContact: state.customerArea.selectedContact,
  currentTab: state.agentView.currentTab,
  authUser: state.dataCenter.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  changeTab: bindActionCreators(AgentViewActions.changeTab, dispatch),

  setSearchField: bindActionCreators(CustomerActions.setSearchField, dispatch),
  setSearchLeadId: bindActionCreators(
    CustomerActions.setSearchLeadId,
    dispatch
  ),
  setSearchInbound: bindActionCreators(
    CustomerActions.setSearchInbound,
    dispatch
  ),
  searchContactList: bindActionCreators(
    CustomerActions.searchContactList,
    dispatch
  ),
  setAuthUser: bindActionCreators(DateCenterActions.setAuthUser, dispatch),
});

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params, searchParams, setSearchParams }}
      />
    );
  }

  return ComponentWithRouterProp;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(AgentView)));

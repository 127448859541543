import React from "react";
import { withTranslation } from "react-i18next";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { withStyles } from "@mui/styles";
import { Styles } from "./styles";
import PropTypes from "prop-types";

import { connect } from "react-redux";

const BackdropLoading = ({ t, classes, open, message, useTranslate }) => (
  // error,warning,info,success
  <Backdrop className={classes.backdrop} open={open}>
    <CircularProgress color="inherit" />
    <Typography variant="h3" gutterBottom>
      {message ? (useTranslate ? t(message) : message) : t("message.loading")}
      ...
    </Typography>
  </Backdrop>
);

BackdropLoading.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string,
  useTranslate: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  open: state.commonLoading.isOpen,
  message: state.commonLoading.message,
  useTranslate: state.commonLoading.useTranslate,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles)(withTranslation()(BackdropLoading)));

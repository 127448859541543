import * as BackendApi from "../components/BackendApi";

const SELECT_REMARKETING_TAG = "SELECT_REMARKETING_TAG";
const FETCH_REMARKETING_TAGS = "FETCH_REMARKETING_TAGS";
const OPEN_DIALOG_TO_SELECT_REMARKETING_TAG =
    "OPEN_DIALOG_TO_SELECT_REMARKETING_TAG";
const CLOSE_SELECT_REMARKETING_TAG_DIALOG =
    "CLOSE_SELECT_REMARKETING_TAG_DIALOG";
const FETCH_AND_OPEN_TO_SELECT_REMARKETING_TAG =
    "FETCH_AND_OPEN_TO_SELECT_REMARKETING_TAG";
const SHOW_BUTTON_FOR_REMARKETING_TAG = "SHOW_BUTTON_FOR_REMARKETING_TAG";
const HIDE_BUTTON_FOR_REMARKETING_TAG = "HIDE_BUTTON_FOR_REMARKETING_TAG";

const initialState = {
    needSelectRemarketingTag: false,
    selectedTag: "",
    remarketingTags: [],
    hasTagsFetched: false,
    isOpenSelectDialog: false,
};

export class ActionsForReasonSelect {
    static selectRemarketingTag = (tag) => ({
        type: SELECT_REMARKETING_TAG,
        payload: tag,
    });
    static fetchRemarketingTags = () => ({
        type: FETCH_REMARKETING_TAGS,
        payload: BackendApi.fetch_remarketing_tag_list(),
    });
    static openSelectDialog = () => ({
        type: OPEN_DIALOG_TO_SELECT_REMARKETING_TAG,
    });
    static closeSelectDialog = () => ({
        type: CLOSE_SELECT_REMARKETING_TAG_DIALOG,
    });
    static fetctAndOpenSelectDialog = () => ({
        type: FETCH_AND_OPEN_TO_SELECT_REMARKETING_TAG,
        payload: BackendApi.fetch_remarketing_tag_list(),
    });

    static showReasonButton = () => ({ type: SHOW_BUTTON_FOR_REMARKETING_TAG });
    static hideReasonButton = () => ({ type: HIDE_BUTTON_FOR_REMARKETING_TAG });
}

export function reduce(state = initialState, action) {
    switch (action.type) {
        case `${FETCH_REMARKETING_TAGS}_PENDING`: {
            return state;
        }
        case `${FETCH_REMARKETING_TAGS}_FULFILLED`: {
            return {
                ...state,
                remarketingTags: action.payload.data,
                hasTagsFetched: true,
            };
        }
        case `${FETCH_REMARKETING_TAGS}_REJECTED`: {
            return state;
        }
        case SELECT_REMARKETING_TAG:
            return { ...state, selectedTag: action.payload };
        case OPEN_DIALOG_TO_SELECT_REMARKETING_TAG:
            return { ...state, isOpenSelectDialog: true };
        case CLOSE_SELECT_REMARKETING_TAG_DIALOG:
            return { ...state, isOpenSelectDialog: false };
        case `${FETCH_AND_OPEN_TO_SELECT_REMARKETING_TAG}_PENDING`: {
            return state;
        }
        case `${FETCH_AND_OPEN_TO_SELECT_REMARKETING_TAG}_FULFILLED`: {
            return {
                ...state,
                remarketingTags: action.payload.data,
                hasTagsFetched: true,
                isOpenSelectDialog: true,
            };
        }
        case `${FETCH_AND_OPEN_TO_SELECT_REMARKETING_TAG}_REJECTED`: {
            return state;
        }

        case SHOW_BUTTON_FOR_REMARKETING_TAG: {
            return { ...state, needSelectRemarketingTag: true };
        }

        case HIDE_BUTTON_FOR_REMARKETING_TAG: {
            return {
                ...state,
                needSelectRemarketingTag: false,
                selectedTag: "",
            };
        }
        default:
            return state;
    }
}

const OPEN_COMMON_MESSAGE = 'OPEN_COMMON_MESSAGE'
const CLOSE_COMMON_MESSAGE = 'CLOSE_COMMON_MESSAGE'

const initialState = {
    isOpen: false,
    messageTitle: '',
    messageDescription: '',
    okButtonText: '',
};

export class Actions {
    static open = (messageDescription, messageTitle, okButtonText) => {
        return {
            type: OPEN_COMMON_MESSAGE,
            payload: {
                messageDescription: messageDescription,
                messageTitle: messageTitle,
                okButtonText: okButtonText,
            }
        }
    }

    static close = () => {
        return { type: CLOSE_COMMON_MESSAGE, payload: null }
    }
}

export function reduce(state = initialState, action) {
    switch (action.type) {
        case OPEN_COMMON_MESSAGE: {
            return {
                isOpen: true,
                messageTitle: action.payload.messageTitle,
                messageDescription: action.payload.messageDescription,
                okButtonText: action.payload.okButtonText
            };
        }
        case CLOSE_COMMON_MESSAGE: {
            return { ...initialState };
        }
        default:
            return state;
    }
}

import { Actions } from '../../../reducers/CustomerState'
import { Actions as AgentViewActions } from '../../../reducers/AgentState'
import LeadDetail from './LeadDetail';
import { Actions as OutboundMessageActions } from '../../../reducers/OutboundMessageState'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next'

const mapStateToProps = (state) => ({
    selectedContact: state.customerArea.selectedContact,
    editStatus: state.customerArea.editStatus,
    offers: state.dataCenter.offers,
    authUser: state.dataCenter.authUser,
});

const mapDispatchToProps = (dispatch) => ({
    openSendMessageDialog: bindActionCreators(OutboundMessageActions.showDialog, dispatch),
    setLeadApp: bindActionCreators(Actions.setLeadApp, dispatch),
    changeTab: bindActionCreators(AgentViewActions.changeTab, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeadDetail));

import * as BackendApi from '../BackendApi';

import { Headline, styles } from './CustomerHistory.Styles';
import React, { Component } from 'react';
import { closeLoading, getServiceBrand, openLoading } from '../../utils';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Grid from '@mui/material/Grid';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Paper from '@mui/material/Paper';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import SmsIcon from '@mui/icons-material/Sms';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import Typography from '@mui/material/Typography';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import moment from '../../i18n/moment';
import { withStyles } from "@mui/styles";

class CustomerHistory extends Component {

  state = {
    historyList: [],
    dis: false,
    value: 'Before',
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment(new Date().setDate(new Date().getDate() + 1)).format('YYYY-MM-DD')
  }

  get_customer_history = () => {
    const { t, selectedContact } = this.props;
    openLoading(t('FlexCalendar:tips.loading'))
    BackendApi.search_customer_history({
      lead_id: selectedContact.lead_id,
      customer_no: selectedContact.customer_no,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
    }).then(result => {
      closeLoading()
      if (result.success) {
        this.setState({
          historyList: result.data
        }, () => {
        })
      }
    }).catch(error => {
      console.error('get_customer_history search_customer_history', error)
      closeLoading()
    })
  }

  componentDidMount() {
    const { t, selectedContact } = this.props;
    openLoading(t('FlexCalendar:tips.loading'))
    BackendApi.search_customer_history({
      lead_id: selectedContact.lead_id,
      customer_no: selectedContact.customer_no,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
    }).then(result => {
      closeLoading()
      if (result.success) {
        this.setState({
          historyList: result.data
        }, () => {
          if (this.state.historyList.length == 0) {
            let sdate = new Date(this.state.start_date)
            let edate = new Date(this.state.end_date)
            let today = moment(new Date()).format('YYYY-MM-DD')
            sdate.setDate(sdate.getDate() - 1)
            edate.setDate(edate.getDate() - 1)
            this.setState({
              start_date: moment(sdate).format('YYYY-MM-DD'),
              end_date: moment(edate).format('YYYY-MM-DD'),
              dis: moment(sdate).format('YYYY-MM-DD') == today ? true : false
            }, () => {
              this.get_customer_history()
            })
          }
        })
      }
    }).catch(error => {
      console.error('componentDidMount search_customer_history', error)
      closeLoading()
    })
  }

  loadTimelineNew = () => {
    const { classes, authUser } = this.props;
    let previousType = ''
    return (
      <Timeline align="alternate">
        {this.state.historyList.map((item, i) => {
          let template = ''
          let tempbackcolor = 'white'
          let temptextcolor = 'black'
          if (item.direction != 'inbound') {
            temptextcolor = "white"
          }
          if (item.author_name == "system") {
            tempbackcolor = "#82bd55"
          }
          else if (item.author == authUser.username) {
            tempbackcolor = "#c53d3d"
          }
          else if (item.direction != 'inbound') {
            tempbackcolor = "#595b5c"
          }
          let SeparatorStyle = {}
          if (item.status === 'error' || item.status === 'failed') {
            SeparatorStyle = { background: 'red' }
          }
          if (item.type === 'sms') {
            template = (
              <TimelineItem key={i}>
                <TimelineOppositeContent>
                  <Typography variant="body2" color="textSecondary">
                    {moment(item.create_date).format('DD.MM.YYYY HH:mm')}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot style={SeparatorStyle}>
                    {previousType === item.type && item.type === 'sms' ? null : <SmsIcon />}
                  </TimelineDot>
                  <TimelineConnector style={SeparatorStyle} />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} style={{ background: tempbackcolor, color: temptextcolor }} className={classes.paper}>
                    <Typography style={{ fontSize: '1rem' }} variant="h6" component="h1">
                      {item.author_name == "system" ? getServiceBrand() : item.author_name}
                    </Typography>
                    <Typography style={{ fontSize: '1rem' }}>{item.body}</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            )
          } else if (item.type === 'whatsapp') {
            template = (
              <TimelineItem key={i}>
                <TimelineOppositeContent>
                  <Typography variant="body2" color="textSecondary">
                    {moment(item.create_date).format('DD.MM.YYYY HH:mm')}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot style={SeparatorStyle}>
                    {previousType == item.type && item.type == 'whatsapp' ? null : <WhatsAppIcon />}
                  </TimelineDot>
                  <TimelineConnector style={SeparatorStyle} />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} style={{ background: tempbackcolor, color: temptextcolor }} className={classes.paper}>
                    <Typography style={{ fontSize: '1rem' }} variant="h6" component="h1">
                      {item.author_name == "system" ? getServiceBrand() : item.author_name}
                    </Typography>
                    <Typography style={{ fontSize: '1rem' }}>{item.body}</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            )
          } else if (item.type === 'notice') {
            template = (
              <TimelineItem key={i}>
                <TimelineOppositeContent>
                  <Typography variant="body2" color="textSecondary">
                    {moment(item.create_date).format('DD.MM.YYYY HH:mm')}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot style={SeparatorStyle}>
                    {previousType == item.type && item.type == 'notice' ? null : <NotificationsIcon />}
                  </TimelineDot>
                  <TimelineConnector style={SeparatorStyle} />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} style={{ background: tempbackcolor, color: temptextcolor }} className={classes.paper}>
                    <Typography style={{ fontSize: '1rem' }} variant="h6" component="h1">
                      {item.author_name == "system" ? getServiceBrand() : item.author_name}
                    </Typography>
                    <Typography style={{ fontSize: '1rem' }}>{item.body}</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            )
          } else if (item.type === 'dial') {
            template = (
              <TimelineItem key={i}>
                <TimelineOppositeContent>
                  <Typography variant="body2" color="textSecondary">
                    {moment(item.create_date).format('DD.MM.YYYY HH:mm')}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot style={SeparatorStyle}>
                    {previousType == item.type && item.type == 'dial' ? null :
                      (item.direction == 'outbound' ? <PhoneForwardedIcon /> : <PhoneInTalkIcon />)}
                  </TimelineDot>
                  <TimelineConnector style={SeparatorStyle} />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} style={{ background: tempbackcolor, color: temptextcolor }} className={classes.paper}>
                    <Typography style={{ fontSize: '1rem' }} variant="h6" component="h1">
                      {item.author_name == "system" ? getServiceBrand() : item.author_name}
                    </Typography>
                    <Typography style={{ fontSize: '1rem' }}>{item.body}</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            )
          } else if (item.type === 'voice') {
            template = (
              <TimelineItem key={i}>
                <TimelineOppositeContent>
                  <Typography variant="body2" color="textSecondary">
                    {moment(item.create_date).format('DD.MM.YYYY HH:mm')}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator >
                  <TimelineDot style={SeparatorStyle}>
                    {previousType == item.type && item.type == 'voice' ? null : <PermPhoneMsgIcon />}
                  </TimelineDot>
                  <TimelineConnector style={SeparatorStyle} />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} style={{ background: tempbackcolor, color: temptextcolor }} className={classes.paper}>
                    <Typography style={{ fontSize: '1rem' }} variant="h6" component="h1">
                      {item.author_name == "system" ? getServiceBrand() : item.author_name}
                    </Typography>
                    <Typography style={{ fontSize: '1rem' }}>{item.body}</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            )
          }
          previousType = item.type
          return template
        })
        }
      </Timeline>
    );
  }

  onBottomNavigationChange = (event, newValue) => {
    let sdate = new Date(this.state.start_date)
    let edate = new Date(this.state.end_date)
    let today = moment(new Date()).format('YYYY-MM-DD')
    console.info(sdate)
    if (newValue == "Before") {
      sdate.setDate(sdate.getDate() - 1)
      edate.setDate(edate.getDate() - 1)
      this.setState({
        start_date: moment(sdate).format('YYYY-MM-DD'),
        end_date: moment(edate).format('YYYY-MM-DD'),
        dis: moment(sdate).format('YYYY-MM-DD') == today ? true : false
      }, () => {
        console.info(sdate)
        console.info(this.state.start_date, this.state.end_date)
        this.get_customer_history()
      })
    } else {
      sdate.setDate(sdate.getDate() + 1)
      edate.setDate(edate.getDate() + 1)
      this.setState({
        start_date: moment(sdate).format('YYYY-MM-DD'),
        end_date: moment(edate).format('YYYY-MM-DD'),
        dis: moment(sdate).format('YYYY-MM-DD') == today ? true : false
      }, () => {
        this.get_customer_history()
      })
    }
  }

  render() {
    const { t, selectedContact } = this.props;
    let firstName = selectedContact.first_name
    let lastName = selectedContact.last_name
    let name_list = []
    if (firstName) { name_list.push(firstName) }
    if (lastName) { name_list.push(lastName) }
    let displayName = name_list.join(' ')
    let mobile = selectedContact.mobile
    let phone = selectedContact.phone
    let phone_list = []
    if (mobile) { phone_list.push(mobile) }
    if (phone) { phone_list.push(phone) }
    let displayPhone = phone_list.join('/')
    console.info(this.state.historyList)
    return (
      <React.Fragment>
        <Paper style={{ padding: "10px", margin: "10px" }} elevation={2}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Headline variant="h4">History From {displayName}({displayPhone})</Headline>
            {/* <Button style={{ marginLeft: "10px" }} variant="contained" color="primary" startIcon={<RefreshIcon />}>refresh</Button> */}
          </Grid>
        </Paper>
        <Paper style={{ padding: "10px", margin: "10px", maxHeight: "63vh", overflowY: 'scroll', background: 'transparent' }}>
          {
            this.state.historyList.length > 0 ? this.loadTimelineNew() :
              <Typography variant="h6">
                {t('FlexCalendar:NoticeTipsMessage')}
              </Typography>
          }
        </Paper>
        <Paper style={{ padding: "0px 10px", margin: "0px 10px" }}>
          <BottomNavigation position="static" value={this.state.value} onChange={this.onBottomNavigationChange}>
            <BottomNavigationAction value="Before" icon={<NavigateBeforeIcon />} />
            <BottomNavigationAction value="Next" disabled={this.state.dis} icon={<NavigateNextIcon />} />
          </BottomNavigation>
        </Paper>
      </React.Fragment>
    );
  }
};

export default withStyles(styles)(CustomerHistory);



import { createTheme } from "@mui/material/styles";

export const supervistaFlexTheme = {
  baseName: "GreyDark",
  colors: {
    base0: "#000000",
    base1: "#222222",
    base2: "#444444",
  },
  overrides: {
    MainHeader: {
      Container: {
        background: "#2f5771",
        color: "#ffffff",
      },
    },
    SideNav: {
      Container: {
        background: "#4a4e60",
      },
      Button: {
        background: "#4a4e60",
      },
    },
  },
};

export const supervistaAgentTheme = createTheme({
  palette: {
    primary: {
      main: "#575756",
    },
    secondary: {
      main: "#808080",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
      },
      containedPrimary: {
        backgroundColor: "#575756",
      },
    },
  },
  typography: {
    fontFamily: [
      "Palanquin",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

export default supervistaAgentTheme;

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { withStyles } from "@mui/styles";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { Component } from 'react';
import { errorTips, successTips } from '../../utils';
import * as BackendApi from '../BackendApi';
import { Divider, Headline, styles } from './TicketArea.Styles';

class TicketArea extends Component {
  constructor(props) {
    super(props)
    this.state = {
      CallNoticeErrorTips: '',
      messageTextValue: '',
      NoticeList: [],

      ticketType: '',
      ticketOccurrenceDate: null,
      ticketDescription: '',
      ticketCustomerName: '',
      ticketCustomerEmail: '',
      ticketCustomerPhone: '',
      ticketOpticianNo: '',
      ticketOrderNo: '',
      ticketOpticianName: '',

      useSelectedStore: false,
      useSelectedCustomer: false,

      ticketTypeMessage: '',
      ticketDescriptionMessage: '',


      onCreating: false
    }
  }

  componentDidMount() {

  }

  showTicketTypeMessage = () => {
    const { t } = this.props;
    this.setState({ ticketTypeMessage: t('FlexCrm:Please select a ticket type') })
  }

  cancelTicketTypeMessage = () => {
    this.setState({ ticketTypeMessage: '' })
  }

  setTicketType = (ticketType) => {
    this.setState({ 'ticketType': ticketType })
    if (!ticketType) {
      this.showTicketTypeMessage()
      return
    }
    if (ticketType && this.state.ticketTypeMessage) {
      this.cancelTicketTypeMessage()
    }

  }

  showTicketDescriptionMessage = () => {
    const { t } = this.props;
    this.setState({ ticketDescriptionMessage: t('FlexCrm:Please input problem description') })
  }

  cancelTicketDescriptionMessage = () => {
    this.setState({ ticketDescriptionMessage: '' })
  }

  setTicketDescription = (ticketDescription) => {
    this.setState({ ticketDescription: ticketDescription })
    if (!ticketDescription) {
      this.showTicketDescriptionMessage()
      return
    }
    if (ticketDescription && this.state.ticketDescriptionMessage) {
      this.cancelTicketDescriptionMessage()
    }
  }

  clearInputForm = () => {
    this.setState({
      ticketType: '',
      ticketOccurrenceDate: null,
      ticketDescription: '',
      ticketCustomerName: '',
      ticketCustomerEmail: '',
      ticketCustomerPhone: '',
      ticketOpticianNo: '',
      ticketOrderNo: '',
      ticketOpticianName: '',
      useSelectedStore: false,
      useSelectedCustomer: false,
    })
  }

  createTicket = () => {
    const { t } = this.props;
    console.log('Click Create Ticket', this.state)
    if (!this.state.ticketType) {
      this.showTicketTypeMessage()
      return
    }

    if (!this.state.ticketDescription) {
      this.showTicketDescriptionMessage()
      return
    }
    if (!this.state.onCreating) {
      this.setState({ onCreating: true })
      BackendApi.create_ticket({
        ticket_type: this.state.ticketType,
        description: this.state.ticketDescription,
        occurrence_date: this.state.ticketOccurrenceDate?.toISOString() || null,
        customer_name: this.state.ticketCustomerName || null,
        customer_email: this.state.ticketCustomerEmail || null,
        customer_phone: this.state.ticketCustomerPhone || null,
        optician_no: this.state.ticketOpticianNo || null,
        optician_name: this.state.ticketOpticianName || null,
        invoice_no: this.state.ticketOrderNo || null,
      }).then(result => {
        console.log('Create Ticket Success.', result)
        successTips(t('FlexCrm:Create ticket success.'))
        this.clearInputForm()
        this.setState({ onCreating: false })
      }).catch(error => {
        console.error('Create Ticket Failed.', error)
        errorTips(t("message.networkIsBusyPleaseRetry"))
        this.setState({ onCreating: false })
      })
    } else {
      errorTips(t('FlexCrm:You clicked too fast!'))
    }

  }

  onCheckSelectedCustomer = (event) => {
    const { t, selectedContact } = this.props;
    if (event.target.checked) {
      if (selectedContact) {
        let name_list = []
        selectedContact.first_name && name_list.push(selectedContact.first_name)
        selectedContact.last_name && name_list.push(selectedContact.last_name)
        let displayName = name_list.join(' ')
        let email = selectedContact.email || ''
        let mobile = selectedContact.mobile || selectedContact.phone || ''
        this.setState({
          useSelectedCustomer: true,
          ticketCustomerName: displayName, ticketCustomerEmail: email, ticketCustomerPhone: mobile
        })
      } else {
        errorTips(t('FlexCrm:No customer selected!'))
        this.setState({ useSelectedCustomer: false })
      }
    } else {
      this.setState({ ticketCustomerName: '', ticketCustomerEmail: '', ticketCustomerPhone: '' })
      this.setState({ useSelectedCustomer: false })
    }

    console.log('checked', event.target.name, event.target.checked)
  }

  onCheckSelectedStore = (event) => {
    const { t, selectedStore } = this.props;
    if (event.target.checked) {
      if (selectedStore) {
        this.setState({
          useSelectedStore: true,
          ticketOpticianNo: selectedStore.ref || '',
          ticketOpticianName: selectedStore.display_name || '',
        })
      } else {
        errorTips(t('FlexCrm:No optician selected on calendar!'))
        this.setState({ useSelectedStore: false })
      }
    } else {
      this.setState({ ticketOpticianNo: '', ticketOpticianName: '' })
      this.setState({ useSelectedStore: false })
    }

    console.log('checked', event.target.name, event.target.checked)
  }

  render() {
    const { t, classes } = this.props;

    return (
      <React.Fragment>
        <form className={classes.ticketFormRoot} noValidate autoComplete="off">
          <Headline variant="h6">Submit a Ticket</Headline>
          <div>
            <TextField
              id="standard-select-ticket-type"
              required
              select
              label={t('FlexCrm:Ticket Type')}
              className={classes.ticketFormField}
              value={this.state.ticketType}
              error={this.state.ticketTypeMessage ? true : false}
              helperText={this.state.ticketTypeMessage}
              onChange={(e) => this.setTicketType(e.target.value)}>
              <MenuItem value="termination">{t('FlexCrm:Termination')}</MenuItem>
              <MenuItem value="deals">{t('FlexCrm:Deals')}</MenuItem>
              <MenuItem value="orders">{t('FlexCrm:Orders')}</MenuItem>
              <MenuItem value="order status">{t('FlexCrm:Order status')}</MenuItem>
              <MenuItem value="bill">{t('FlexCrm:Bill')}</MenuItem>
              <MenuItem value="vouchers">{t('FlexCrm:Vouchers')}</MenuItem>
              <MenuItem value="payment reminder">{t('FlexCrm:Payment reminder')}</MenuItem>
              <MenuItem value="warning">{t('FlexCrm:Warning')}</MenuItem>
              <MenuItem value="claim">{t('FlexCrm:Claim')}</MenuItem>
              <MenuItem value="satisfaction guarantee">{t('FlexCrm:Satisfaction guarantee')}</MenuItem>
              <MenuItem value="refund warranty">{t('FlexCrm:Refund warranty')}</MenuItem>
              <MenuItem value="glasses insurance">{t('FlexCrm:Glasses insurance')}</MenuItem>
              <MenuItem value="miscellaneous">{t('FlexCrm:Miscellaneous')}</MenuItem>
              <MenuItem value="repayment">{t('FlexCrm:Repayment')}</MenuItem>
              <MenuItem value="change of bank details">{t('FlexCrm:Change of bank details')}</MenuItem>
              <MenuItem value="optician complaint">{t('FlexCrm:Optician complaint')}</MenuItem>
              <MenuItem value="data deletion">{t('FlexCrm:Data deletion')}</MenuItem>
            </TextField>
            <LocalizationProvider dateAdapter={AdapterDateFns} >
              <DateTimePicker
                renderInput={
                  (props) =>
                    <TextField
                      {...props}
                      className={`${classes.ticketFormField} ${classes.ticketOccurrenceDate}`}
                    />
                }
                margin="normal"
                id="picker-ticket-occurrence-date"
                label={t('FlexCrm:When did the problem happen')}
                value={this.state.ticketOccurrenceDate}
                clearable
                disableFuture
                onChange={(e) => {
                  this.setState({
                    ticketOccurrenceDate: e
                  })
                }}
              />
            </LocalizationProvider>
          </div>
          <div>
            <TextField
              id="standard-ticket-optician-no"
              label={t('FlexCrm:Optician ID')}
              placeholder={t('FlexCrm:E.g. 022100')}
              className={classes.ticketFormField}
              value={this.state.ticketOpticianNo}
              onChange={(e) => this.setState({ ticketOpticianNo: e.target.value })} />
            <TextField
              id="standard-ticket-order-no"
              label={t('FlexCrm:Order / Invoice Nr.')}
              className={`${classes.ticketFormField} ${classes.ticketOccurrenceDate}`}
              value={this.state.ticketOrderNo}
              onChange={(e) => this.setState({ ticketOrderNo: e.target.value })} />
          </div>
          <div>
            <TextField
              id="standard-ticket-optician-name"
              label={t('FlexCrm:Optician Name')}
              className={`${classes.ticketFormField} ${classes.ticketDescription}`}
              value={this.state.ticketOpticianName}
              onChange={(e) => this.setState({ ticketOpticianName: e.target.value })} />
          </div>
          <div>
            <FormControlLabel
              control={<Checkbox
                checked={this.state.useSelectedStore}
                onChange={this.onCheckSelectedStore}
                name="useSelectedStore" />}
              label={t('FlexCrm:Use the selected optician on calendar?')}
              className={classes.useSelectedStore} />
          </div>
          <div>
            <TextField
              id="standard-ticket-description"
              label={t('FlexCrm:Description')}
              multiline
              required
              rows="3"
              className={`${classes.ticketFormField} ${classes.ticketDescription}`}
              value={this.state.ticketDescription}
              error={this.state.ticketDescriptionMessage ? true : false}
              helperText={this.state.ticketDescriptionMessage}
              onChange={(e) => this.setTicketDescription(e.target.value)} />
          </div>
          <div>
            <TextField
              id="standard-ticket-customer-name"
              label={t('FlexCrm:Customer Name')}
              className={classes.ticketFormField}
              value={this.state.ticketCustomerName}
              onChange={(e) => this.setState({ ticketCustomerName: e.target.value })} />
            <TextField
              id="standard-ticket-customer-email"
              label={t('FlexCrm:Customer Email')}
              className={`${classes.ticketFormField} ${classes.ticketOccurrenceDate}`}
              value={this.state.ticketCustomerEmail}
              onChange={(e) => this.setState({ ticketCustomerEmail: e.target.value })} />
            <TextField
              id="standard-ticket-customer-phone"
              label={t('FlexCrm:Customer Phone')}
              className={classes.ticketFormField}
              value={this.state.ticketCustomerPhone}
              onChange={(e) => this.setState({ ticketCustomerPhone: e.target.value })} />
          </div>
          <div>
            <Button
              onClick={this.createTicket}
              disabled={this.state.onCreating}
              className={classes.ticketFormButton}
              variant="outlined"
              color="primary" >
              {t('FlexCrm:Submit')}
            </Button>
            <FormControlLabel
              control={<Checkbox
                checked={this.state.useSelectedCustomer}
                onChange={this.onCheckSelectedCustomer}
                name="useSelectedCustomer" />}
              label={t('FlexCrm:For the current selected customer?')}
              className={classes.useSelectedCustomer}
            />
          </div>
        </form>

        <Divider></Divider>

      </React.Fragment>
    );
  }
};

export default withStyles(styles)(TicketArea);

const OPEN_COMMON_CONFITM = 'OPEN_COMMON_CONFITM'
const CLOSE_COMMON_CONFIRM = 'CLOSE_COMMON_CONFIRM'
const CONFIRM_COMMON_CONFIRM = 'CONFIRM_COMMON_CONFIRM'

const initialState = {
    isOpen: false,
    confirmTitle: '',
    onConfirm: null,
    confirmDescription: '',
    confirmButtonText: '',
    cancelButtonText: '',
};

export class Actions {
    static open = (confirmTitle, onConfirm, confirmDescription, confirmButtonText, cancelButtonText) => {
        return {
            type: OPEN_COMMON_CONFITM,
            payload: {
                confirmTitle: confirmTitle,
                onConfirm: onConfirm,
                confirmDescription: confirmDescription,
                confirmButtonText: confirmButtonText,
                cancelButtonText: cancelButtonText,
            }
        }
    }

    static close = () => {
        return { type: CLOSE_COMMON_CONFIRM, payload: null }
    }

    static confirm = () => {
        return { type: CONFIRM_COMMON_CONFIRM, payload: null }
    }
}

export function reduce(state = initialState, action) {
    switch (action.type) {
        case OPEN_COMMON_CONFITM: {
            return {
                isOpen: true, 
                confirmTitle: action.payload.confirmTitle,
                onConfirm: action.payload.onConfirm,
                confirmDescription: action.payload.confirmDescription,
                confirmButtonText: action.payload.confirmButtonText,
                cancelButtonText: action.payload.cancelButtonText,
            };
        }
        case CLOSE_COMMON_CONFIRM: {
            return {...initialState};
        }
        case CONFIRM_COMMON_CONFIRM: {
            if(state.onConfirm){
                state.onConfirm()
            }
            return {...initialState};
        }
        default:
            return state;
    }
}

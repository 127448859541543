export const styles = (theme) => ({
  agentTabsRoot: {
    backgroundColor: "#94c11e ",
    color: "black ",
  },
  tabs: {
    backgroundColor: "#575756  ",
    height: "2px ",
  },
  showTab: {
    display: "flex",
  },
  hideTab: {
    display: "none",
  },
  avatar: { alignSelf: "center", position: "absolute", right: "2%", top: '8px'},
});

const $greenBtnBG = '#94c21e'
export const Styles = theme => ({
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},

	button: {
		margin: theme.spacing(1),
	},

	secondary: {
		backgroundColor: '#e51f1f',
		"&:hover": {
			backgroundColor: "#ff0808",
		},
		"& span": {
			fontWeight: 'bold',
			color: '#fff',
		}
	},

	buttonGreen: {
		margin: theme.spacing(1),
		boxShadow: '0px 2px 5px -5px',
		background: $greenBtnBG,
		'&:hover': {
			background: '#a7da23',
		},
		'& span': {
			color: '#fff',
			fontWeight: 'bold',
		}
	},
});

import * as BackendApi from '../components/BackendApi'

const OPEN_DIALOG_FOR_24H_CHECK = 'OPEN_DIALOG_FOR_24H_CHECK';
const CLOSE_DIALOG_FOR_24H_CHECK = 'CLOSE_DIALOG_FOR_24H_CHECK';
const INVITE_TO_JOIN_WHATSAPP = "INVITE_TO_JOIN_WHATSAPP"

const initialState = {
  to: null,
  channelSid: null,
  openDialog: false,
  sending: false,
  error: {
    message: null,
    translate: false,
  },
  
};

export class WhatsApp24CheckActions {
  static openDialogforWhatsapp24hCheck = (to, channelSid) => ({ type: OPEN_DIALOG_FOR_24H_CHECK, payload: { to: to, channelSid: channelSid } });
  static closeDialogforWhatsapp24hCheck = () => ({ type: CLOSE_DIALOG_FOR_24H_CHECK })
  static inviteToJoinWhatsapp = (to, channelSid, author) => ({
    type: INVITE_TO_JOIN_WHATSAPP,
    payload: BackendApi.invite_to_join_whatsapp(to, channelSid, author)
  })
}

export function reduce(state = initialState, action) {
  switch (action.type) {
    case `${INVITE_TO_JOIN_WHATSAPP}_PENDING`: {
      return { ...state, sending: true }
    }
    case `${INVITE_TO_JOIN_WHATSAPP}_FULFILLED`: {
      let result = action.payload.data;
      console.log("INVITE_TO_JOIN_WHATSAPP", result)
      if (!result.success) {
        let error = {
          message: result.failed_reason,
          translate: false,
        }
        return { ...state, sending: false, error: error }
      }
      return { ...initialState }
    }
    case `${INVITE_TO_JOIN_WHATSAPP}_REJECTED`: {
      return {
        ...state,
        sending: false,
        error: { message: 'message.networkIsBusyPleaseRetry', translate: true }
      }
    }

    case 'SEND_MESSAGE_TO_CUSTOMER_FULFILLED': {
      let result = action.payload.data;
      if (!result.success && result.failed_reason === 'OUT_OF_SESSION') {
        return { ...initialState, openDialog: true, to: result.to, channelSid: result.chat_channel_sid }
      }
      return state
    }
    case OPEN_DIALOG_FOR_24H_CHECK: {
      return { ...initialState, openDialog: true, to: action.payload.to, channelSid: action.payload.channelSid }
    }
    case CLOSE_DIALOG_FOR_24H_CHECK: {
      return { ...initialState }
    }
    default:
      return state;
  }
}


import React, { Component } from 'react';
import { styles } from './WhatsApp24hCheck.Styles'
import { withStyles } from "@mui/styles";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

class WhatsApp24hCheck extends Component {

  sendTemplateMessage = () => {
    this.props.inviteToJoinWhatsapp(this.props.to, this.props.channelSid, this.props.sender.identity)
  }
  closeDialogHandler = () => {
    this.props.closeDialogforWhatsapp()
  }

  render() {
    const { t, classes } = this.props;
    return (
      <React.Fragment>

        <Dialog
          open={this.props.openDialog}
          onClose={this.closeDialogHandler}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"The last message via WhatsApp to the customer is over 24 hours ago?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Whatsapp has a security provision that states that a message can only be sent to the customer via WhatsApp within a 24-hour window.
              Since the last message to the customer was over 24 hours ago, now we can only send to the customer a template message.
            </DialogContentText>
            {this.props.error && this.props.error.message ?
              <DialogContentText className={classes.error} id="alert-dialog-description">
                {this.props.error.translate ? t(this.props.error.message) : this.props.error.message}
                {/* Error by Sending the Template Message */}
              </DialogContentText>
              :
              null
            }
          </DialogContent>
          <DialogActions>
            <Button disabled={this.props.sending} onClick={this.closeDialogHandler} color="primary">
              Disagree
            </Button>
            <Button disabled={this.props.sending} onClick={this.sendTemplateMessage} color="primary" autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>

      </React.Fragment>
    );
  }
};

export default withStyles(styles)(WhatsApp24hCheck);

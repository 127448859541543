import { Actions } from "../../reducers/CommonTips";
import MuiAlert from "@mui/material/Alert";
import PropTypes from "prop-types";
import React from "react";
import Snackbar from "@mui/material/Snackbar";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TipsSnackBar = ({ t, open, onClose, message, type, useTranslate }) => (
  // error,warning,info,success
  <Snackbar
    open={open}
    autoHideDuration={2000}
    onClose={onClose}
    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
  >
    {open ? (
      <Alert onClose={onClose} severity={type ? type : "info"}>
        {useTranslate ? t(message) : message}
      </Alert>
    ) : null}
  </Snackbar>
);

TipsSnackBar.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  useTranslate: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  open: state.commonTips.isOpen,
  message: state.commonTips.message,
  useTranslate: state.commonTips.useTranslate,
  type: state.commonTips.type,
});

const mapDispatchToProps = (dispatch) => ({
  onClose: bindActionCreators(Actions.close, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TipsSnackBar));

import CustomerHistory from "./CustomerHistory";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

const mapStateToProps = (state) => ({
  selectedContact: state.customerArea.selectedContact,
  authUser: state.dataCenter.authUser,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CustomerHistory));

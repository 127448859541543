import moment from "moment-timezone";
import { getLanguageShort } from "../utils";

export const customizeMoment = (lang) => {
  if ("en" === lang || "de" === lang) {
    return {
      week: {
        dow: 1,
        doy: 1,
      },
    };
  }
  return {};
};
// init moment
let lang = getLanguageShort();
moment.updateLocale(lang, customizeMoment(lang));
console.log("Init Moment use:", lang);
export default moment;

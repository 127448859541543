import * as Utils from '../../utils'

import React, { Component } from 'react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import CalendarArea from '../CalendarArea/CalendarArea.Container';
import CallNoticeArea from '../CallNoticeArea/CallNoticeArea.Container';
import Chip from '@mui/material/Chip';
import CommonConfirm from '../Common/ConfirmDialog';
import CommonLoading from '../Common/BackdropLoading';
import CommonMessage from '../Common/MessageDialog';
import CommonTips from '../Common/TipsSnackBar';
import CustomerArea from '../CustomerArea/CustomerArea.Container';
import CustomerHistory from '../CustomerHistory/CustomerHistory.Container';
import FaceIcon from '@mui/icons-material/Face';
import InfoArea from '../InfoArea/InfoArea.Container';
import LoginView from '../LoginView/LoginView.Container';
import LogoutIcon from '@mui/icons-material/Logout';
import MuiAlert from '@mui/material/Alert';
import OutboundMessage from '../OutboundMessage/OutboundMessage.Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TicketArea from '../TicketArea/TicketArea.Container';
import WhatsApp24Check from '../WhatsApp24hCheck/WhatsApp24hCheck.Container';
import { styles } from './Agent.Styles';
import { supervistaAgentTheme } from './../../theme/supervistaTheme';
import { withStyles } from "@mui/styles";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
class AgentPanel extends Component {
  state = {
    showAgentView: true,
    // showOptometryView: false,
    loginName: '',
  }

  // componentDidMount() {
  //   console.log('AgentViewDidMount', this.props)
  //   let roles = this.props.agentWorker?.attributes?.roles || []
  //   if (roles.indexOf("admin") > -1) {
  //     this.setState({ showAgentView: true, showOptometryView: true })
  //   } else {
  //     let skills = this.props.agentWorker.attributes.skills || []
  //     if (skills.indexOf("optometry") > -1) {
  //       this.setState({ showOptometryView: true })
  //       this.props.changeTab('optometrySheet')
  //     }
  //     if (skills.indexOf("support") > -1) {
  //       this.setState({ showAgentView: true })
  //       this.props.changeTab('customer')
  //     }
  //   }
  //   let loginName = this.props.agentWorker?.attributes?.crm_agent_account || this.props.agentWorker?.attributes?.email || ''
  //   this.setState({ loginName: loginName })
  // }

  componentDidMount() {
    const { router } = this.props;
    let search = router.searchParams.get('q') || ''
    let lead_id = router.searchParams.get('lead') || ''
    let inbound = router.searchParams.get('inbound') || ''
    inbound = inbound ? true : false
    if (search) {
      this.props.setSearchField(search)
      if (lead_id) {
        this.props.setSearchLeadId(lead_id)
      }
      if (inbound) {
        this.props.setSearchInbound(inbound)
      }
      this.props.searchContactList(search, lead_id, inbound)
    }

  }

  handelTabChange = (event, value) => {
    this.props.changeTab(value)
  }

  handleLogout = () => {
    Utils.clear_crm_access_token()
    this.props.setAuthUser(null)
    Utils.openLogin()
  }

  loadInfo = () => {
    return <InfoArea />
  }

  render() {
    const { classes, t } = this.props;

    return (
      <div>
        <ThemeProvider theme={supervistaAgentTheme}>
          <StyledEngineProvider injectFirst>
            <LoginView key="login-CRM" username={this.props.router.searchParams.get('login')} />
            <Tabs
              className={classes.agentTabsRoot}
              value={this.props.currentTab}
              onChange={this.handelTabChange}
              variant="scrollable">
              {this.state.showAgentView ? <Tab value="customer" label={t('Customer')} /> : null}
              {this.state.showAgentView ? <Tab value="customerHistory" label={t('Customer History')} /> : null}
              {this.state.showAgentView ? <Tab value="calendar" label={t('Calendar')} /> : null}
              {this.state.showAgentView ? <Tab value="callNotice" label={t('Call Notice')} /> : null}
              {this.state.showAgentView ? <Tab value="ticket" label={t('Ticket')} /> : null}
              {/* {this.state.showOptometryView ? <Tab value="optometrySheet" label="Optometry Sheet" /> : null} */}
              {/* {this.props.needSelectRemarketingTag ? <ReasonView /> : null} */}
            </Tabs>
            {this.props.authUser ?
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Chip
                  className={classes.avatar}
                  color="primary"
                  icon={<FaceIcon />}
                  label={this.props.authUser.username}
                  onDelete={this.handleLogout}
                  deleteIcon={<LogoutIcon />}
                />
              </Stack>

              : null
            }
            {this.state.showAgentView && this.props.currentTab === "customer" ?
              <CustomerArea />
              : null
            }
            {this.state.showAgentView && this.props.currentTab === "customerHistory" ?
              !this.props.selectedContact ?
                <Paper style={{ padding: "8px", margin: "5px" }}>
                  <Alert severity="info">You need to select a contact firstly</Alert>
                </Paper>
                :
                <CustomerHistory />
              : null
            }
            {this.state.showAgentView && this.props.currentTab === "calendar" ?
              <CalendarArea flex={1} ></CalendarArea>
              : null
            }
            {this.state.showAgentView && this.props.currentTab === "callNotice" ?
              <CallNoticeArea flex={1} ></CallNoticeArea>
              : null
            }
            {this.state.showAgentView && this.props.currentTab === "ticket" ?
              <TicketArea />
              : null
            }
            <CommonConfirm key="common-confirm" />
            <CommonLoading key="common-Loading" />
            <CommonMessage key="common-message" />
            <CommonTips key="common-tips" />

            <WhatsApp24Check key="whatsAppCheck" />
            <OutboundMessage key="outboundMessage" />
          </StyledEngineProvider>
        </ThemeProvider>
      </div>
    );
  }
};

export default withStyles(styles)(AgentPanel);

import { reduce as AgentReducer } from "./AgentState";
import { reduce as CalendarReducer } from "./CalendarState";
import { reduce as ChatQuestionReducer } from "./ChatQuestionState";
import { reduce as CommonConfirmReducer } from "./CommonConfirm";
import { reduce as CommonLoadingReducer } from "./CommonLoading";
import { reduce as CommonMessageReducer } from "./CommonMessage";
import { reduce as CommonTipsReducer } from "./CommonTips";
import { reduce as CustomerReducer } from "./CustomerState";
import { reduce as DataCenterReducer } from "./DataCenterState";
import { reduce as InfoAreaReducer } from "./InfoAreaState";
import { reduce as LoginViewReducer } from "./LoginViewState";
import { reduce as OptometryReducer } from "./OptometryState";
import { reduce as OutboundMessage } from "./OutboundMessageState";
import { reduce as ReasonViewReducer } from "./ReasonViewState";
import { reduce as VideoReducer } from "./VideoState";
import { reduce as Whatsapp24hCheck } from "./WhatsApp24hCheckState";
import { combineReducers } from "redux";

// Register your redux store under a unique namespace
export const namespace = "supervistaApp";

export default combineReducers({
  video: VideoReducer,
  loginView: LoginViewReducer,
  reasonView: ReasonViewReducer,
  infoArea: InfoAreaReducer,
  outboundMessage: OutboundMessage,
  whatsapp24hCheck: Whatsapp24hCheck,
  agentView: AgentReducer,
  optometry: OptometryReducer,
  chatQuestion: ChatQuestionReducer,

  dataCenter: DataCenterReducer,
  calendarArea: CalendarReducer,
  customerArea: CustomerReducer,
  commonLoading: CommonLoadingReducer,
  commonConfirm: CommonConfirmReducer,
  commonMessage: CommonMessageReducer,
  commonTips: CommonTipsReducer,
});

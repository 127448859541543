// import * as Flex from "@twilio/flex-ui";

import { Actions as ConfirmDialogActions } from "./reducers/CommonConfirm";
// import cookie from "react-cookies";
import Cookies from "universal-cookie";
import { Actions as LoadingActions } from "./reducers/CommonLoading";
import { Actions as LoginActions } from "./reducers/LoginViewState";
import { Actions as MessageDialogActions } from "./reducers/CommonMessage";
import { Actions as TipsActions } from "./reducers/CommonTips";
import store from "./store";

export const getCurrentStore = () => {
  return store;
};

export const successTips = (message, translate) => {
  getCurrentStore().dispatch(TipsActions.success(message, translate));
};
export const infoTips = (message, translate) => {
  getCurrentStore().dispatch(TipsActions.info(message, translate));
};
export const warningTips = (message, translate) => {
  getCurrentStore().dispatch(TipsActions.warn(message, translate));
};
export const errorTips = (message, translate) => {
  getCurrentStore().dispatch(TipsActions.error(message, translate));
};
export const openLoading = (message, translate) => {
  getCurrentStore().dispatch(LoadingActions.open(message, translate));
};
export const closeLoading = () => {
  getCurrentStore().dispatch(LoadingActions.close());
};

export const openMessageDialog = (message, title, okButtonText) => {
  getCurrentStore().dispatch(
    MessageDialogActions.open(message, title, okButtonText)
  );
};

export const openConfirmDialog = (
  title,
  onConfirm,
  message,
  confirmButtonText,
  cancelButtonText
) => {
  getCurrentStore().dispatch(
    ConfirmDialogActions.open(
      title,
      onConfirm,
      message,
      confirmButtonText,
      cancelButtonText
    )
  );
};

export const openLogin = () => {
  getCurrentStore().dispatch(LoginActions.open());
};

export const cookies = new Cookies();

export const DEFAULT_ACCESS_TOKEN_NAME = "SUPERVISTA_API_ACCESS_TOKEN";

export const getApiAccessTokenName = () => {
  return window.appConfig.crmAccessTokenName || DEFAULT_ACCESS_TOKEN_NAME;
};

export const save_crm_access_token = (token) => {
  cookies.set(
    getApiAccessTokenName(),
    token.token_type + " " + token.access_token,
    { path: "/", maxAge: token.expires_in, sameSite: "none", secure: true }
  );
  return true;
};

export const check_crm_access_token = () => {
  let token_name = getApiAccessTokenName();
  let access_token = cookies.get(token_name);
  console.log("Check Access Token: ", token_name, access_token);
  return access_token ? access_token : null;
};

export const clear_crm_access_token = () => {
  let token_name = getApiAccessTokenName();
  // save_crm_access_token({ token_type: "", access_token: "" });
  cookies.remove(token_name, {
    path: "/",
    sameSite: "none",
    secure: true,
  });
  console.log("Clear Access Token: ", token_name);
  return true;
};

// export const start_outbound_call = (number) => {
//   console.log("Start Outbound Call", number);
//   if (!getCurrentStore().getState().flex.worker.worker.isAvailable) {
//     warningTips(
//       "FlexCrm:message.pleaseChangeYourActivityToAvailableBeforeDialOut",
//       true
//     );
//     return;
//   }
//   let authUser = getCurrentStore().getState().supervistaApp.dataCenter.authUser;
//   let agent_uid = (authUser && authUser.id) || 0;
//   Flex.Actions.invokeAction("StartOutboundCall", {
//     destination: number,
//     taskAttributes: {
//       name: number,
//       type: "click-to-call",
//       agent_uid: agent_uid,
//       worker_name: getCurrentStore().getState().flex.worker.worker.name,
//       service_brand: getServiceBrand(),
//       service_code: getServiceCode(),
//     },
//   }).catch((error) => {
//     console.log("outbound error.", error);
//     console.log(error.name);
//     console.log(error.message);
//     if (error.message === "voice connection already live") {
//       warningTips("FlexCrm:Can not outbound a call while answering.", true);
//     } else {
//       warningTips("FlexCrm:message.ItSeemsAWrongNumber", true);
//     }
//   });
// };

// export const add_message_body = (
//   channelSid,
//   message,
//   selectionStart,
//   selectionEnd
// ) => {
//   console.log(
//     "Add Message Body",
//     channelSid,
//     message,
//     selectionStart,
//     selectionEnd
//   );
//   if (!message || !channelSid) {
//     return;
//   }
//   Flex.Actions.invokeAction("SetInputText", {
//     channelSid: channelSid,
//     body: message,
//     selectionStart: selectionStart,
//     selectionEnd: selectionEnd,
//   }).catch((error) => {
//     console.log("Add Message Body error.", error);
//   });
// };

// export const logout_flex = () => {
//   console.log("Logout");
//   Flex.Actions.invokeAction("Logout", {}).catch((error) => {
//     console.log("Logout error.", error);
//   });
// };

export const getNameOfOderStatus = (status) => {
  switch (status) {
    case "S":
      return "Submitted";
    case "C":
      return "Cancelled";
    case "P":
      return "In production";
    case "F":
      return "Production finished";
    case "V":
      return "Sent to Optician";
    case "H":
      return "Order transfered to accounting";
    case "M":
      return "Customer informed by email";
    case "R":
      return "Provision was paid to optician";
    // case "W": return t("statusW");
    default:
      return "";
  }
};

export const getServiceEndpoint = () => {
  let endpoint = window.appConfig.backendServiceEndpoint;
  return endpoint || "https://cal.supervista.de";
};

export const getServiceCode = () => {
  let code = window.appConfig.backendServiceCode;
  return code || "at";
};

export const getServiceLogo = () => {
  let logo = window.appConfig.backendServiceLogo;
  return (
    logo ||
    "https://www.brillen.at/hs-fs/hubfs/New Logos/brillen-at-Logo-ohneClaim-2021.png?width=300&name=brillen-at-Logo-ohneClaim-2021.png"
  );
};

export const getServiceBrand = () => {
  let brand = window.appConfig.backendServiceBrand;
  return (brand && brand.toLowerCase()) || "brillen.at";
};

export const getCurrentCountry = () => {
  let serviceCode = getServiceCode();
  if (serviceCode.indexOf("-")) {
    return serviceCode.split("-")[0].toLowerCase();
  }
  return serviceCode;
};

export const getDefaultLanguage = () => {
  let language = window.appConfig.language;
  return language || "de";
};

export const getLanguageShort = (language) => {
  let lang = language || getDefaultLanguage();
  if (lang.indexOf("-")) {
    return lang.split("-")[0].toLowerCase();
  } else if (lang.indexOf("_")) {
    return lang.split("_")[0].toLowerCase();
  }
  return lang.toLowerCase();
};

export const currentDomain = () => {
  return window.location.hostname
}

export const secondLevelDomain = () => {
  let level = window.location.hostname.endsWith('co.uk') ? -3 : -2
  return window.location.hostname.split('.').slice(level).join('.')
}

export const loadLogo = () => {
  const country = getCurrentCountry();
  switch (country) {
    case "de":
      return "https://www.brillen.de/hs-fs/hubfs/brillen-DE-logo-xmas.png?width=250&name=brillen-DE-logo-xmas.png";
    case "us":
      return "https://www.brillen.com/hs-fs/hubfs/brillen-COM-logo-xmas.png?width=300&name=brillen-COM-logo-xmas.png";
    case "es":
      return "https://www.gafas.es/hs-fs/hubfs/gafas-ES-logo-xmas.png?width=274&name=gafas-ES-logo-xmas.png";
    case "it":
      return "occhiali24.it";
    case "uk":
      return "https://www.brillen.co.uk/hubfs/brillen-co-uk_Logo%20c100-1.svg";
    case "at":
      return "brillen.at";
    case "pl":
      return "brillen.pl";
    default:
      return "https://www.brillen.de/hs-fs/hubfs/brillen-DE-logo-xmas.png?width=250&name=brillen-DE-logo-xmas.png";
  }
};

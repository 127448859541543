import * as BackendApi from '../components/BackendApi'

const SHOW_MESSAGE_SENDING_DIALOG = "SHOW_MESSAGE_SENDING_DIALOG";
const CLOSE_MESSAGE_SENDING_DIALOG = "CLOSE_MESSAGE_SENDING_DIALOG";

const SEND_MESSAGE_TO_CUSTOMER = 'SEND_MESSAGE_TO_CUSTOMER';
const UPDATE_OUTBOUND_MESSAGE_BODY = 'UPDATE_OUTBOUND_MESSAGE_BODY'

const initialState = {
  to: '',
  body: '',
  isOpen: false,
  sending: false,
  error: {
    message: '',
    translate: false
  },

};

export class Actions {

  static showDialog = (to) => ({ type: SHOW_MESSAGE_SENDING_DIALOG, payload: { to: to } })
  static hideDialog = () => ({ type: CLOSE_MESSAGE_SENDING_DIALOG })
  static updateMessageBody = (body) => ({ type: UPDATE_OUTBOUND_MESSAGE_BODY, payload: body })
  static sendMessageToCustomer = (to, body, author) => ({
    type: SEND_MESSAGE_TO_CUSTOMER,
    payload: BackendApi.send_message(to, body, author)
  })

}

export function reduce(state = initialState, action) {
  switch (action.type) {
    case SHOW_MESSAGE_SENDING_DIALOG:
      return {
        ...state,
        isOpen: true,
        to: action.payload.to,
        error: initialState.error,
      }
    case CLOSE_MESSAGE_SENDING_DIALOG:
      return { ...initialState }

    case `INVITE_TO_JOIN_WHATSAPP_FULFILLED`: {
      let result = action.payload.data;
      console.log("INVITE_TO_JOIN_WHATSAPP_FULFILLED", result)
      if (result.success) {
        return { ...initialState }
      }
      return { ...state }
    }

    case 'CLOSE_DIALOG_FOR_24H_CHECK':
      return { ...initialState }

    case UPDATE_OUTBOUND_MESSAGE_BODY:
      return { ...state, body: action.payload }

    case `${SEND_MESSAGE_TO_CUSTOMER}_PENDING`: {
      return { ...state, sending: true }
    }
    case `${SEND_MESSAGE_TO_CUSTOMER}_FULFILLED`: {
      let result = action.payload.data;
      console.log("SEND_MESSAGE_TO_CUSTOMER", result)
      if (!result.success) {
        let error = {
          message: result.failed_reason,
          translate: false,
        }
        return { ...state, sending: false, error: error }
      }
      return { ...initialState }
    }
    case `${SEND_MESSAGE_TO_CUSTOMER}_REJECTED`: {
      console.log("SEND_MESSAGE_TO_CUSTOMER FAILED", action.payload)
      return {
        ...state,
        sending: false,
        error: { message: 'message.networkIsBusyPleaseRetry', translate: true }
      }
    }
    default:
      return state;
  }
}






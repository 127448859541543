import { styled } from "@mui/material/styles";

export const styles = (theme) => ({
  ticketFormRoot: {
    marginLeft: 10,
  },
  ticketFormField: {
    margin: "2ch",
    width: "35ch",
  },
  ticketFormButton: {
    margin: "2ch",
  },
  ticketDescription: {
    minWidth: "79ch",
  },
  ticketOccurrenceDate: {
    minWidth: "40ch",
  },
  useSelectedCustomer: {
    marginLeft: "2ch",
  },
  useSelectedStore: {
    marginLeft: "2ch",
  },
});

export const Headline = styled("div")`
  color: rgb(34, 34, 34);
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.1;
  letter-spacing: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 2.8em;
  background: none;
  transition: color 0.2s ease 0s;
  padding: 12px;
  overflow: hidden;
`;
export const Divider = styled("div")`
  display: flex;
  margin-top: 20px;
  border-style: solid;
  border-width: 0px 0px 1px;
  border-color: rgb(204, 204, 204);
`;

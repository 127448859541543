import { Actions } from "../../reducers/LoginViewState";
import { Actions as CustomerActions } from "../../reducers/CustomerState";
import { Actions as DateCenterActions } from "../../reducers/DataCenterState";
import LoginView from "./LoginView";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

const mapStateToProps = (state) => ({
  isOpen: state.loginView.isOpen,

  contactSearchField: state.customerArea.searchField,
  contactSearchLeadId: state.customerArea.searchLeadId,
  contactSearchInbound: state.customerArea.searchInbound,
  researchContactList: state.customerArea.researchContactListAfterLogin,
});

const mapDispatchToProps = (dispatch) => ({
  openLoginView: bindActionCreators(Actions.open, dispatch),
  closeLoginView: bindActionCreators(Actions.close, dispatch),
  setAuthUser: bindActionCreators(DateCenterActions.setAuthUser, dispatch),
  setOffers: bindActionCreators(DateCenterActions.setOffers, dispatch),

  searchContactList: bindActionCreators(
    CustomerActions.searchContactList,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(LoginView));

import { styled } from "@mui/material/styles";

export const CustomerNameContainer = styled("div")`
  display: flex;
  flex-flow: row;
`;
export const CustomerNameStyle = styled("div")`
  color: rgb(34, 34, 34);
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.1;
  letter-spacing: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: none;
  transition: color 0.2s ease 0s;
  padding: 5px;
  overflow: hidden;
  font-size: 1.8em;
`;

export const CustomerMainInfoContainer = styled("div")`
  display: flex;
  flex-flow: row;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const CustomerElementInfoContainer = styled("div")`
  display: flex;
  flex-flow: column;
  width: 55%;
`;

export const CustomerElemetHeadLine = styled("div")`
  color: rgb(152, 151, 151);
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.1;
  letter-spacing: 2px;
  text-overflow: ellipsis;
  background: none;
  transition: color 0.2s ease 0s;
  padding: 5px;
  overflow: hidden;
  font-size: 1.2em;
`;

export const CustomerElemetText = styled("div")`
  padding: 0px 0px 0px 12px;
  overflow: hidden;
  color: black;
  font-weight: 700;
  text-overflow: ellipsis;
  font-size: 1.2em;
`;

export const ContainerDividingLine = styled("div")`
  display: flex;
  border-style: solid;
  border-width: 0px 0px 1px;
  border-color: rgb(204, 204, 204);
  margin-top: 5px;
`;

export const CallButton = styled("div")`
  display: flex;
`;

export const FooterItem = styled("div")`
  display: flex;
  flex-flow: column;
  width: 50%;
  border-right: 1px solid;
  border-color: rgb(204, 204, 204);
`;

export const styles = (theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "red",
  },
  footItemBorder: {
    borderRight: "1px solid rgb(204, 204, 204)",
  },
});
